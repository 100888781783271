import React from 'react';
import { AgGridActionButton } from 'buildingBlocks';
import { MEMBER, checkPermissions } from 'utils/featureFlags';
import { User, ColDef } from 'utils/types';

const UserTableHeader = (
  user,
  permissions: Array<string>,
): Array<ColDef> => [
  {
    field: 'name',
    cellRenderer: ({ data }: { data: User }) => `${data.firstName} ${data.lastName}`,
    flex: 1,
    sortable: false,
    resizable: false,
  },
  {
    field: 'email',
    cellRenderer: ({ data }: { data: User }) => data.email,
    flex: 1,
    sortable: false,
    resizable: false,
  },
  {
    field: 'action',
    sortable: false,
    headerName: '',
    hide: !checkPermissions(user, permissions, MEMBER.ANY, false),
    maxWidth: 120,
    resizable: false,
    cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
    cellRenderer: ({ data }: { data: User }) => (
      <AgGridActionButton to={`/users/${data.id}`} type="edit" />
    ),
  },
];

export default UserTableHeader;
