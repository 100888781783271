import { OrderedMap } from 'immutable';

export default class MaxOrderedMap<K, V> {
  maxSize: number;

  orderedMap: OrderedMap<K, V>;

  constructor(maxSize: number) {
    this.maxSize = maxSize;
    // @ts-ignore jrose might be a bug?
    this.orderedMap = new OrderedMap();
  }

  set(key: K, value: V): MaxOrderedMap<K, V> {
    // OrderedMap doesn't have size for some reason in version 4.0.0r2
    // const localMap = (this.orderedMap.toKeyedSeq(): any).size >= this.maxSize
    //   ? this.orderedMap.rest() : this.orderedMap;
    const localMap = this.orderedMap.toKeyedSeq().size >= this.maxSize
      ? this.orderedMap.rest() : this.orderedMap;
    this.orderedMap = localMap.set(key, value);
    return this;
  }

  get(key: K): V | null {
    return this.orderedMap.get(key);
  }

  // Delete all values from a Map
  clear(): MaxOrderedMap<K, V> {
    this.orderedMap = this.orderedMap.clear();
    return this;
  }
}
