/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Bifunctor } from 'utils/functionHelpers';

/*
 these functions wrap useEffect and useMemo and have been renamed in order to convey that it only should run once,
 which is accomplished by passing the empty array below
*/

export const useMount = (functionToRunOnce: () => void) => {
  useEffect(functionToRunOnce, []);
};

export const useMemoOnce = (functionToRunOnce: () => void) => useMemo(functionToRunOnce, []);

export const useFunctionState = <F>(fn: F) => Bifunctor.second(
  (setState: (d: () => F) => void) => (func: F) => setState(() => func),
)(useState<F>(() => fn));
