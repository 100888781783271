/* eslint-disable import/no-import-module-exports */
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighChartMore from 'highcharts/highcharts-more';
import HighChartExporting from 'highcharts/modules/exporting';
import HighChartDraggablePoints from 'highcharts/modules/draggable-points';
import history from 'utils/history';
import { COPILOT_COLORS } from 'globalStyles';
import { PAGE_CHANGE_ACTION } from 'constantsBase';
import rootSaga from './sagas';
import Routes from './routes';
import createCopilotStore from './createCopilotStore';
import '@wppopen/components-library/dist/platform-ui-kit/global.css';
import '@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css';
import '@wppopen/components-library/dist/collection/';
import './wppGlobalStyle.scss';

const { NEW_DESIGN_SYSTEM: { BLUES, YELLOWS } } = COPILOT_COLORS;
// formats the relative time display
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: 'a month ago',
    MM: '%d months ago',
    y: '%d year ago',
    yy: '%d years ago',
  },
});

// This is a plugin to be able to enable/disable the exporting feature of Highcharts
// It will be default to true, to switch it off, add `exporting: { enabled: false }` to your highcharts configs.
HighChartMore(Highcharts);
HighChartExporting(Highcharts);
HighChartDraggablePoints(Highcharts);

const highchartsOptions = {
  chart: {
    style: {
      fontFamily: "'Gilroy','Gotham', 'Helvetica Neue',Arial,Helvetica,sans-serif;",
    },
  },
  tooltip: {
    backgroundColor: 'white',
  },
  plotOptions: {
    column: {
      color: BLUES.B500_WAVE,
    },
    line: {
      color: YELLOWS.Y500_GOLD,
      shadow: {
        width: 3,
        offsetY: 1,
      },
    },
    spline: {
      color: BLUES.B500_WAVE,
    },
  },
};

/**
 * Creates a Redux middleware and connects the sagas to the Redux store.
 * @see  http://yelouafi.github.io/redux-saga/docs/api/index.html#createsagamiddlewareoptions
 */
const sagaMiddleware = createSagaMiddleware();

export const store = createCopilotStore(sagaMiddleware);
sagaMiddleware.run(rootSaga);

/**
  * Render the app into the specified DOM node.
      * https://www.npmjs.com/package/react-dom
  * Make Redux store available to connect() calls within the App.
      * See: https://github.com/reactjs/react-redux/blob/master/docs/api.md#provider-store.
  * Set up React Router using browserHistory. Uses the browser History api.
      * See https://github.com/ReactTraining/react-router/blob/master/docs/guides/Histories.md#browserhistory
*/

history.listen(() => {
  // Dispatch page change action to be able to cancel any saga if it was meant to process for a specific page
  store.dispatch({ type: `${PAGE_CHANGE_ACTION}` });
});

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <Component>
        <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
      </Component>
    </Provider>,
    document.querySelector('#app'),
  );
};
console.log('in index.tsx');
render(Routes);
// @ts-ignore module is hot
if (module.hot) {
  // @ts-ignore module is hot
  module.hot.accept('./routes', () => {
    const newApp = require('./routes').default; // eslint-disable-line
    render(newApp);
  });
}
