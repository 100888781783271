import { MutableRefObject, useEffect } from 'react';

const useMutationObserver = (
  ref: MutableRefObject<any>,
  callback: MutationCallback,
  options: {
    attributes: boolean
    characterData: boolean
    childList: boolean
    subtree: boolean
  } = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  },
) => {
  useEffect(() => {
    if (!ref.current) return;

    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    // MutationObserver interface provides the ability to watch for changes being made to the DOM tree
    // it requires a callback to invoke once any DOM mutations occur
    const observer = new MutationObserver(callback);
    observer.observe(ref.current, options);

    // clean up function to stop observing the ref element
    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, options]);
};

export default useMutationObserver;
