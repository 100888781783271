import React from 'react';
import { WppTypography, WppTooltip } from 'buildingBlocks';
import { USER_SETTINGS } from '../style';

const {
  tooltipEmailListStyle,
} = USER_SETTINGS;

type WppEmailChipProps = {
  emailList: Array<string>
};

export const WppEmailChip = (props: WppEmailChipProps) => {
  const { emailList } = props;
  const emailCount = emailList.length;
  let displayText = '';

  if (emailCount > 2) {
    const visibleEmails = emailList.slice(0, 2).join(', ');
    const moreCount = emailCount - 2;
    displayText = `${visibleEmails}, +${moreCount}`;
  } else {
    displayText = emailList.join(', ');
  }

  return (
    <>
      <WppTypography
        tag="p"
        type="s-body"
        style={{ display: emailCount > 2 ? 'block' : 'none' }}
      >
        <WppTooltip theme="light" config={{ placement: 'right', allowHTML: true }}>
          {displayText}
          <div slot="tooltip-content" style={tooltipEmailListStyle}>
            {emailList.map((item) => (
              <WppTypography
                tag="p"
                type="s-body"
                key={item}
              >
                {item}
              </WppTypography>
            ))}
          </div>
        </WppTooltip>
      </WppTypography>
      <WppTypography
        tag="p"
        type="s-body"
        style={{ display: emailCount <= 2 ? 'block' : 'none' }}
      >
        {displayText}
      </WppTypography>
    </>
  );
};
