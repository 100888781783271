import _ from 'lodash';
import { STRATEGY_TYPE, DSP, RESULTS_LIMIT, GOAL_TYPES_TO_STRATEGY_TYPES, GOAL_TYPES } from 'constantsBase';
import { StrategyType, StrategyRecommendation } from 'utils/copilotAPI';
import { StrategyType as STType, Flight, OptimizationLevelType } from 'utils/types';
import { DisabledStrategyTypeReasons } from '../../containers/StrategyWizard/constants';
import { DspToPixel } from '../../containers/StrategyWizard/components/PixelPicker/types';
import { isHigherOrderExternalType } from '../../containers/StrategyWizard/utils';
import useFetcher, { PossibleStates } from './useFetcher';

export const isStrategyTypeDisabled = (selectedRestrictionObj) => (
  !_.isEmpty(_.get(selectedRestrictionObj, DisabledStrategyTypeReasons.unsupportedAdTypes))
  || !_.isEmpty(_.get(selectedRestrictionObj, DisabledStrategyTypeReasons.unsupportedFlightTypes))
  || !_.get(selectedRestrictionObj, DisabledStrategyTypeReasons.noFlightSupported, true)
  || !_.get(selectedRestrictionObj, DisabledStrategyTypeReasons.multipleFlightTypesSupported, true)
  || !_.get(selectedRestrictionObj, DisabledStrategyTypeReasons.multipleFlightsSupported, true)
);

const isConversionGoal = (goalType: string) => _.includes([
  GOAL_TYPES.cpa.value,
  GOAL_TYPES.conversionRate.value,
  GOAL_TYPES.conversionRevenue.value,
  GOAL_TYPES.engagementScore.value,
], goalType);

const isCompatibleStrategyType = (strategyTypeId: number, goalType: string, impValueFilters?: DspToPixel) => {
  if (isConversionGoal(goalType) && !_.isNil(impValueFilters)) {
    return _.includes(GOAL_TYPES_TO_STRATEGY_TYPES[goalType], strategyTypeId);
  }
  return _.includes(GOAL_TYPES_TO_STRATEGY_TYPES[goalType], strategyTypeId);
};

const useStrategyTypeFetcher = (
  eligibleFlights: Array<Flight>,
  dspId: number,
  selectedStrategyType: STType,
  setDefaultStrategyType: Function,
  oL: OptimizationLevelType,
  goalType?: string,
  setSelectedStrategyType?: (x: STType) => void,
  impValueFilters?: DspToPixel,
  isCrossPlatformOptimization?: boolean,
) => {
  const fetchStrategyTypes = async () => {
    const res = await StrategyType.get({ limit: RESULTS_LIMIT });
    //  seg rec strategies have been sunset
    const compatibleStrategyTypes = _.filter(res.data, (st) => (isCompatibleStrategyType(st.id, goalType, impValueFilters) || (isCrossPlatformOptimization && st.id === STRATEGY_TYPE.crossPlatformOptimization.id)) && st.id !== STRATEGY_TYPE.heliosSegmentRecency.id);
    const populatedStrategyTypes = _.map(compatibleStrategyTypes, (st) => ({ ...STRATEGY_TYPE.getById(st.id), ...st }));
    if (isCrossPlatformOptimization && _.head(populatedStrategyTypes)) {
      setDefaultStrategyType(populatedStrategyTypes, STRATEGY_TYPE.crossPlatformOptimization.id, setSelectedStrategyType);
      return { kind: PossibleStates.hasData, data: populatedStrategyTypes };
    }
    const flightCandidates = _.map(eligibleFlights, (flightObj) => {
      const f = _.pick(flightObj, ['externalId', 'externalType', 'member']);
      return { ...f, dsp: dspId };
    });
    const { data } = await StrategyRecommendation.stratTypeRestrictions(flightCandidates);
    const selectedRestrictionObj = _.get(data, _.get(selectedStrategyType, 'id', oL?.defaultStrat));
    const selectedIsDisabled = isStrategyTypeDisabled(selectedRestrictionObj);
    if (_.isEqual(dspId, DSP.APN.id) && selectedIsDisabled && !isHigherOrderExternalType(_.head(oL.externalTypeIds))) {
      setDefaultStrategyType(populatedStrategyTypes, STRATEGY_TYPE.customTree.id, setSelectedStrategyType);
    }
    return { kind: PossibleStates.hasData, data: populatedStrategyTypes, restrictions: data };
  };
  const currentState = useFetcher(fetchStrategyTypes, [dspId]);
  return currentState;
};

export default useStrategyTypeFetcher;
