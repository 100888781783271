import { all } from 'redux-saga/effects';
import { localStorageSagas } from 'utils/localstorage/sagas';
import { watchSagas as airflowSagas } from 'utils/airflow/sagas';

// Containers
import { loginSaga } from './containers/Login/sagas';
import { strategiesListSagas } from './containers/StrategiesList/sagas';
import logoutSaga from './containers/Logout/sagas';
import { seatsSagas } from './containers/Seats/sagas';
import { jobsSagas } from './containers/Jobs/sagas';
import { jobDetailSagas } from './containers/JobDetail/sagas';
import { taskDetailSagas } from './components/TaskCard/sagas';
import { usersSagas } from './containers/Users/sagas';
import { dashboardSagas } from './containers/Dashboard/sagas';
import { rolesSagas } from './containers/Roles/sagas';
import { userThemeSagas } from './containers/UserSettings/sagas';
import { strategyAnalyticSagas } from './containers/StrategyAnalytics/sagas';
import { customTreeAnalyticsSagas } from './containers/StrategyAnalytics/components/View/CustomTree/sagas';
import { strategyFlightRunsSagas } from './containers/StrategyFlightRuns/sagas';
import { appSaga } from './containers/App/sagas';
import abInsightsSagas from './containers/ABInsights/sagas';
import userGroupsSagas from './containers/UserGroups/sagas';
import copilotHealthSagas from './containers/CopilotHealth/sagas';
import authTokenSagas from './containers/AuthToken/sagas';

export default function* rootSaga() {
  yield all([
    abInsightsSagas(),
    airflowSagas(),
    appSaga(),
    authTokenSagas(),
    copilotHealthSagas(),
    customTreeAnalyticsSagas(),
    dashboardSagas(),
    jobDetailSagas(),
    jobsSagas(),
    localStorageSagas(),
    loginSaga(),
    logoutSaga(),
    rolesSagas(),
    seatsSagas(),
    strategiesListSagas(),
    strategyAnalyticSagas(),
    strategyFlightRunsSagas(),
    taskDetailSagas(),
    userGroupsSagas(),
    userThemeSagas(),
    usersSagas(),
  ]);
}
