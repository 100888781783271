import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';

export default function createCopilotStore(sagaMiddleware) {
  /** An array of middlewares to apply to Redux.  */
  const middleware = [sagaMiddleware];

  /* eslint-disable */
  // @ts-ignore
  const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    latency: 3000,
    maxAge: 25,
    // use this to console log the actions / state rather than using the visual devtool (prevents browser crashing)
    // predicate: (state, action) => console.log(action, state),
  })) || compose;
  /* eslint-enable */

  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore);

  return createStoreWithMiddleware(reducers);
}
