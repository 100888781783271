/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import { roleType } from 'constantsBase';
import { Member, Region, User, Role as RoleType } from 'utils/types';
import { ViewUser, Users } from 'utils/copilotAPI';
import useFetcher, { State as FetchState, PossibleStates } from 'utils/hooks/useFetcher';

function checkRole(role, type) {
  return role && role.type === type;
}

export const computeRegionOptions = (members = []): Array<Region> => _.values(_.reduce(members, (res, member) => {
  const name = _.get(member.region, 'name');
  if (name) {
    if (!res[name]) {
      res[name] = { name, value: [] };
    }
    res[name].value.push(member);
  }
  return res;
}, {}));

type Role = {
  id: string | number
};

export type MemberRole = {
  member: Member,
  role: Role,
};

type MemberId = number;

/**
 * Compute member options based on roles selected.
 * @param selectedRoles - Selected roles
 * @param members - List of available members
 * @param memberRoleMap - A map whose keys are roles and values are arrays of members allowed per each role
 * @returns {*}
 */
export function computeMemberOptions(
  selectedRoles: Array<Role>,
  members: Array<Member>,
  memberRoleMap: { [role: number]: Array<MemberId> },
  assignedMemberRoles?: Array<MemberRole>,
): Array<Member> {
  const memberRolesSelected = _.filter(selectedRoles, { type: roleType.member });

  if (_.isEmpty(memberRolesSelected)) { return []; }

  const assignedRolesByMember = _.reduce(assignedMemberRoles, (result, memberRole) => {
    if (memberRole && memberRole.member) {
      const assignedMembers = result[memberRole.role.id] || [];
      assignedMembers.push(memberRole.member.id);
      // eslint-disable-next-line no-param-reassign
      result[memberRole.role.id] = assignedMembers;
    }
    return result;
  }, {});

  const assignedMembers = [];
  _.forEach(memberRolesSelected, (role: Role) => {
    const value = assignedRolesByMember[role.id] || [];
    assignedMembers.push(value);
  });
  const commonMembersToRemove = _.intersection(...assignedMembers);

  const roleIds = _.mapValues(memberRolesSelected, 'id');
  const membersForRolesIds = _.map(roleIds, (roleId) => {
    const memberId = _.get(memberRoleMap, roleId, []);
    if (memberId.length === 1 && memberId[0] === null) {
      return _.map(members, 'id');
    }
    return memberId;
  });

  let commonMemberIds = _.intersection(...membersForRolesIds);
  commonMemberIds = _.difference(commonMemberIds, commonMembersToRemove);
  return _.map(commonMemberIds, (memberId) => _.find(members, (member) => member.id === memberId));
}

export function computeRoleOptions(roles, assignedMemberRoles) {
  const memberRoles = {};
  const userMemberRoles = _.reduce(
    assignedMemberRoles,
    (result, memberRole) => {
      if (memberRole) {
        if (checkRole(memberRole.role, roleType.global)) {
          result.push(memberRole.role);
        } else if (memberRole) {
          memberRoles[memberRole.role.id] = memberRole.role;
        }
      }
      return result;
    },
    [],
  );
  return _.filter(_.differenceBy(roles, userMemberRoles, 'name'), (v) => !_.isNil(v)) as unknown as Array<RoleType>;
}

/**
 * Check:
 *   - if it's already added (in this case do nothing)
 *   - if the newly created memberRole:
 *     * has to be added back in memberRoles (if it got deleted then re-added)
 *     * has to be added in memberRolesToSave (list of new memberRoles to add to the edited user)
 * @param member - the member associated to the new memberRole
 * @param role - the role associated to the new memberRole
 * @param memberRoles - the updated list retrieved from database (with all deleted)
 * @param memberRolesToSave - the updated list of newly added member role
 * @param memberRolesToDelete - the updated list of deleted member role (based on memberRoles)
 */
export function computeMemberRoleAdded(member, role, memberRoles, memberRolesToSave, memberRolesToDelete) {
  const memberRole = { member, role: { id: role.id } };
  if (!_.isUndefined(member)) {
    memberRole.member = { id: member.id };
  }
  // look if the memberrole was already present.
  const memberAlreadyAdded = _.find(memberRoles, memberRole) || _.find(memberRolesToSave, memberRole);
  if (!memberAlreadyAdded) {
    // Look if the role was previously deleted and came from API
    const deletedMemberIndex = _.findIndex(memberRolesToDelete, memberRole);
    if (deletedMemberIndex > -1) {
      memberRoles.push(memberRolesToDelete[deletedMemberIndex]);
      memberRolesToDelete.splice(deletedMemberIndex, 1);
    } else {
      // Add new role
      memberRolesToSave.push({ member, role });
    }
  }
}

/**
 * Check what member roles the user that is editing can display based on the edited user member roles list
 * @param memberRoles - the list of member roles the edited user have
 * @param memberRoleMap - the list of member roles available for the user that is editing
 * @returns {Array}
 */
export function filterValidMemberRoles(memberRoles, memberRoleMap) {
  return _.filter(memberRoles, (memberRole) => (memberRole && (
    (checkRole(memberRole.role, roleType.member) && _.get(memberRoleMap, memberRole.role.id, []).includes(memberRole.member.id))
    || checkRole(memberRole.role, roleType.global)
  )));
}

export const getMemberRoleForDisplay = (memberRole) => {
  const member: {} = _.get(_.head(memberRole), 'member');
  const allMemberRoles = _.map(memberRole, (mr) => _.pick(mr, 'role', 'id', 'member'));
  return { member: { ...member }, roles: [...allMemberRoles] };
};

type GroupedRole = {
  admin?: Array<{ member: Member }>,
};

export const orderAndGroupRolesForDisplay = (roles) => {
  const sortedRoles = _.orderBy(roles, [(r: { member }) => (
    r.member
      ? r.member.displayName.toLowerCase()
      : null
  )]);

  const groupedRoles: GroupedRole = {};
  _.forEach(sortedRoles, (role: { member: Member, role: { name: string } }) => {
    if (role.member) {
      const displayKey = `${role.member.displayName} - ${role.member.id}`;
      if (displayKey in groupedRoles) {
        groupedRoles[displayKey].push(role);
      } else {
        groupedRoles[displayKey] = [role];
      }
    } else {
      groupedRoles[_.camelCase(role.role.name)] = [role];
    }
  });
  return groupedRoles;
};

export const useDoesCurrentUserExist = (userId: number): FetchState => {
  const query = { id: userId };
  const fetchUserByUserId = async (): Promise<FetchState> => {
    const { data } = await ViewUser.get({ where: query });
    return { kind: PossibleStates.hasData, data };
  };
  return useFetcher(fetchUserByUserId, [userId]);
};

export const isAdmin = (user: User) => {
  const roleNames = _.map(user.roles, 'name');
  return _.includes(roleNames, 'admin');
};

export const validateApproverEmail = async (approver: string, isAdmin: boolean, userEmail: string) => {
  if (!isAdmin && _.toLower(userEmail) === _.toLower(approver)) {
    return false;
  }
  const res = await Users.validateUser({ approver });
  return _.get(res.data, 'isValid', false);
};

export const hasBrandManagerRole = (user: User) => _.includes(_.map(user.roles, 'name'), 'Brand Manager');
