import React from 'react';
import _ from 'lodash';
import { WppTableBodyCell, WppTableBodyRow } from 'buildingBlocks';
import { formatApnObj } from 'utils/formattingUtils';
import userGroupPageStyles from 'containers/UserGroups/style';
import {
  FlightItemColFlightBox, FlightItemColExisting, FlightItemColSplit,
  FlightItemColNew, FlightItemColSuccessOrFail, FlightItemDismiss,
} from './FlightItemCols';
import { ACTIONS } from '../constants';

const { setUserGroupsStyle } = userGroupPageStyles;

type FlightItemProps = {
  v: {
    externalId: string
    name: string
  }
  onDismiss: Function
  pct: number
  newGroupRange: Array<string>
  index: number
  selectedActionType: string
  checkedUserGroupData: Array<unknown>
  userGroupData: Array<unknown>
  disableDismiss: boolean
};

const FlightItem = ({
  v, onDismiss, pct, newGroupRange, index, selectedActionType, checkedUserGroupData, userGroupData, disableDismiss,
}: FlightItemProps) => {
  const hasError = _.has(userGroupData[index], 'response.error');
  const getExistingVal = (highOrLow: string) => _.get(checkedUserGroupData[index], `user_group_targets.groups[0].${highOrLow}`);
  const newGroupRangeVal = _.isEqual(selectedActionType, ACTIONS.apply.value) && !_.isEmpty(newGroupRange[index]) ? newGroupRange[index] : '-';
  const flightItemColFlightBoxWidth = !_.isEqual(selectedActionType, ACTIONS.apply.value) ? '72%' : '63%';
  const flightItemColExistingWidth = _.isEqual(selectedActionType, ACTIONS.apply.value) ? '12%' : '11%';

  return (
    <WppTableBodyRow>
      <WppTableBodyCell style={{ width: flightItemColFlightBoxWidth }}>
        <FlightItemColFlightBox
          formatApnObj={formatApnObj(v)}
        />
      </WppTableBodyCell>
      <WppTableBodyCell style={{ width: flightItemColExistingWidth }}>
        <FlightItemColExisting
          low={getExistingVal('low')}
          high={getExistingVal('high')}
          shouldDisplay={hasError || _.isEmpty(userGroupData)}
        />
      </WppTableBodyCell>
      {_.isEqual(selectedActionType, ACTIONS.apply.value) && (
        <WppTableBodyCell style={setUserGroupsStyle.tableStyle}>
          <FlightItemColSplit shouldDisplay={hasError || _.isEmpty(userGroupData)} pct={pct} />
        </WppTableBodyCell>
      )}
      <WppTableBodyCell style={setUserGroupsStyle.tableStyle}>
        <FlightItemColNew
          newGroupRangeVal={newGroupRangeVal}
        />
      </WppTableBodyCell>
      {!_.isEmpty(userGroupData) && (
        <WppTableBodyCell>
          <FlightItemColSuccessOrFail
            hasError={hasError}
            flightErrorMsg={_.get(userGroupData[index], 'response.error')}
          />
        </WppTableBodyCell>
      )}
      {!disableDismiss && (
        <WppTableBodyCell>
          <FlightItemDismiss
            onDismiss={() => onDismiss(v)}
          />
        </WppTableBodyCell>
      )}
    </WppTableBodyRow>
  );
};

export default FlightItem;
