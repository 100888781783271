import _ from 'lodash';
import moment from 'moment-timezone';
import domToImage from 'dom-to-image';
import fileSaver from 'file-saver';

export const shouldFilterDomNodes = (node: { className: string }, excludeDomWithClassName: string | null) => (
  !_.some(_.split(node.className, ' '), (cls) => cls === excludeDomWithClassName)
);

export function convertArrayOfObjectsToStringCSV(
  data: Array<Array<unknown>>,
  headers: Array<string>,
  columnDelimiter: string = ',',
  lineDelimiter: string = '\n',
) {
  if (data === null || !data.length || headers === null || !headers.length) {
    return '';
  }
  const reducer = (acc, val) => `${acc}"${val.join(`"${columnDelimiter}"`)}"${lineDelimiter}`;

  return data.reduce(reducer, `${headers.join(columnDelimiter)}${lineDelimiter}`);
}

export function exportFile(fileName: string, blob: Blob) {
  const element = document.createElement('a');
  const url = URL.createObjectURL(blob);
  element.href = url;
  element.setAttribute('download', fileName);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function domToPNG(domId: string, excludeDomWithClassName: string | null, filenameKey: string = 'cp-screenshot') {
  domToImage.toBlob(document.getElementById(domId), {
    filter: (n) => shouldFilterDomNodes(n, excludeDomWithClassName),
    cacheBust: true,
  }).then((blob) => {
    fileSaver.saveAs(blob, `${filenameKey}-${moment().valueOf()}.png`);
  });
}
