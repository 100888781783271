import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { WppButton, WppGrid, WppTypography } from 'buildingBlocks';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { Permission } from 'utils/featureFlags';
import PageLoading from 'components/PageLoading';
import { HTTP_STATUS } from 'constantsBase';
import PageForbidden from 'containers/403';
import { isAdmin } from 'containers/User/utils';
import { GlobalState } from 'reducers';
import { Lambda } from 'utils/copilotAPI';
import { isAdminOrQAGlobal } from 'utils/functionHelpers';
import { EnvironmentVariables, User } from 'utils/types';
import WHITELIST_IP_STYLES from './style';
import WhitelistIpModal from './WhitelistIPModal';

const { whiteListBtnContainer } = WHITELIST_IP_STYLES;

export const WHITELIST_IP_PAGE_NAME = 'Whitelist IP Address';
export const WHITELIST_IP_PAGE_SUB_HEADER = 'Whitelist your IP address so that you can use the VPN.';
const LAMBDA_FUNCTION_NAME = 'VpnWanIpFiltering-prod-addMyWanIp';

export const CustomHeaderWhiteListIP: React.FC = (): React.ReactElement => (
  <WppGrid container fullWidth>
    <WppGrid item all={24}>
      <WppTypography tag="h1" type="3xl-heading">
        {WHITELIST_IP_PAGE_NAME}
      </WppTypography>
    </WppGrid>
    <WppGrid item all={8}>
      <WppTypography tag="p" type="s-body">
        {WHITELIST_IP_PAGE_SUB_HEADER}
      </WppTypography>
    </WppGrid>
  </WppGrid>
);

const WhitelistIP = () => {
  const [awaitingResp, setAwaitingResp] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const navigate = useNavigate();

  const loginState = useSelector<GlobalState>((state) => state.login) as { user: User, env: string };
  const { user, env } = loginState;
  const envNotLoaded = _.isNil(env);
  const canAccessPage = isAdminOrQAGlobal(user);
  const isAdminUser = isAdmin(user);

  useEffect(() => {
    if (!envNotLoaded && !_.isEqual(env, EnvironmentVariables.dev)) {
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  const handleSubmit = async () => {
    setAwaitingResp(true);
    const lambdaRes = await Lambda.invokeLambdaFunction(LAMBDA_FUNCTION_NAME);
    const resPayload = _.get(lambdaRes, 'data.Payload', {});
    const resStatus = _.get(resPayload, 'statusCode');
    const resMessage = _.get(resPayload, 'userMessage', 'There was an error. Please try again in a few minutes.');
    if (resStatus === HTTP_STATUS.VALID) {
      setShouldRedirect(true);
    }
    setModalMessage(resMessage);
    setAwaitingResp(false);
  };

  if (envNotLoaded) {
    return <PageLoading />;
  }

  return (canAccessPage
    ? (
      <>
        {awaitingResp && <PageLoading />}
        {modalMessage && (
          <WhitelistIpModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
            shouldRedirect={shouldRedirect}
          />
        )}
        <WppPermissionPageTemplate
          title={WHITELIST_IP_PAGE_NAME}
          name={WHITELIST_IP_PAGE_NAME}
          permissions={Permission.adminUsers}
          customHeader={<CustomHeaderWhiteListIP />}
        >
          <WppGrid container fullWidth>
            <WppGrid item all={24}>
              <DocumentTitle title={WHITELIST_IP_PAGE_NAME} />
              <div style={whiteListBtnContainer}>
                <WppButton
                  onClick={handleSubmit}
                  disabled={!isAdminUser}
                  size="m"
                >
                  Whitelist My IP Address
                </WppButton>
              </div>
            </WppGrid>
          </WppGrid>
        </WppPermissionPageTemplate>
      </>
    ) : (
      <PageForbidden button />
    )
  );
};

export default WhitelistIP;
