// This file contains various utilities for display formatting.
/* eslint-disable no-restricted-imports */
import _ from 'lodash';
import moment, { Moment } from 'moment';
import numeral from 'numeral';
import uuid from 'uuid';
import { GOAL_VALUE_TYPE, GOAL_TYPES } from 'constantsBase';
import { OPTIMIZATION_DIRECTION_TEXT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { GoalDB } from 'containers/StrategyWizard/types';
import { isAWGOrImpact, hasSingleOutcomeAndValue, isAWGGoalType } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { isInRange } from 'utils/functionHelpers';
import { GoalType, ApnObj, Strategy, Goal } from 'utils/types';
import { getSingleRevTypeAndValue } from 'containers/StrategyWizard/utils';
import { decimalFormatting } from '../containers/StrategyWizard/steps/StrategyConfirmation/utils';

// Adds commas to numbers ex: 12345 => 12,345
export function formatNumber(num: number | string) {
  if (typeof num === 'string') {
    return num.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export const convertDatabaseDateToLongDate = (date: string | Moment) => moment(date).format('D MMMM YYYY, h:mm a');

export const formatMoney = (value: string | number, currencyCode: string = 'USD') => {
  const textVal = _.toString(value);
  const index = textVal.indexOf('.');
  const decPlaces = index !== -1 ? textVal.length - index - 1 : 0;
  return `${numeral(value).format(decimalFormatting(decPlaces))} ${currencyCode}`;
};

/*
 * Take a string and camelizing it without removing special character like _.camelCase would have done
 */
export const camelize = (str: string, separator: string = '\\s\\(') => str.replace(
  new RegExp(`([^${separator}])([^${separator}]*)`, 'g'),
  (_g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`,
);

/**
 * take a string and transform it to camelCase type of string
 */
export function toCamelCase(str: string, defaultValue: string = '') {
  return typeof str === 'string' ? str.replace(/(_[a-z])/g, (c) => `${c[1].toUpperCase()}`) : defaultValue;
}

/**
 * take a string and transform it to snake_case type of string
 */
export function toSnakeCase(str: string, defaultValue: string = '') {
  return typeof str === 'string' ? str.replace(/([A-Z])/g, (c) => `_${c.toLowerCase()}`) : defaultValue;
}

export function toSlug(str: string) {
  if (!str) {
    return undefined;
  }
  return str.toLowerCase()
    // trim leading/trailing spaces
    .replace(/^\s+|\s+$/g, '')
    // removing weird characters (everything that is not a-z, 0-9, _ or -
    .replace(/[^\w\s-]/g, '')
    // convert spaces
    .replace(/[-\s]+/g, '-')
    // remove trailing separator
    .replace(/-$/g, '');
}

const DECIMAL_SEPARATOR = '.';
const leftShiftingDecimal = (v: number, shift) => {
  const shiftedBy = _.repeat('0', shift);
  const negative = v < 0;
  const vStr = `${shiftedBy}${negative ? -v : v}`;

  const [left, right] = vStr.split(DECIMAL_SEPARATOR);
  const beforeDecimalSeparator = left.substring(0, left.length - shift);
  const afterDecimalSeparator = left.substring(left.length - shift) + (right || '');

  return Number(`${negative ? '-' : ''}${beforeDecimalSeparator}${DECIMAL_SEPARATOR}${afterDecimalSeparator}`);
};
const rightShiftingDecimal = (v, shift) => {
  const shiftedBy = _.repeat('0', shift);
  const vStr = `${v.toString()}${shiftedBy}`;

  const [left, right] = vStr.split(DECIMAL_SEPARATOR);
  if (!right) {
    return Number(left);
  }

  const beforeDecimalSeparator = left + right.substring(0, shift);
  const afterDecimalSeparator = right.substring(shift);

  return Number(`${beforeDecimalSeparator}${DECIMAL_SEPARATOR}${afterDecimalSeparator}`);
};

/**
 * shift decimal to the right if shift > 0
 * or to the left when shift < 0
 * @param value the value to shift
 * @param shift the amount of number to shift
 */
export const shiftDecimalSeparator = (value: string | number, shift: number = 0, defaultValue: number = NaN) => {
  // Check if it's a finite number
  // @ts-ignore typescript isFinite -- typescript doesn't have correct types for isFinite
  if (value === null || (typeof value === 'boolean') || Array.isArray(value) || !isFinite(value)) {
    return defaultValue;
  }

  const v = Number(value);
  // doesn't make sense to try to shift a 0
  if (v === 0) {
    return 0;
  }

  return (shift < 0 && leftShiftingDecimal(v, -shift))
    || (shift > 0 && rightShiftingDecimal(v, shift))
    || v;
};

export const truncate = (string: string, length: number) => (
  (string.length > length ? `${string.substring(0, length)}...` : string)
);

export const formatPercent = (float: number | string, precision: number = 2) => (
  `${shiftDecimalSeparator(float, 2).toFixed(precision)}%`
);

export const getGoalStr = (
  strategy: Strategy,
  goal: Goal,
  code: string,
  formattedGoalTarget: string,
) => {
  const isImpactGoal = _.isEqual(goal.type, GOAL_TYPES.impactOutcome.value);
  let goalStr = formattedGoalTarget;
  let revenueStr = '';
  let targetStr = '';
  const { type, target, customGoal } = goal;
  const { revTypeConfig, config } = strategy;
  if (strategy?.config?.hasCustomRevenueType && revTypeConfig) {
    const revTypeConfigKeys = Object.keys(revTypeConfig);
    const { revenueType } = getSingleRevTypeAndValue(revTypeConfig);
    revenueStr = !hasSingleOutcomeAndValue(revTypeConfig) ? 'Multiple' : `${_.toUpper(revenueType)} ${revTypeConfig[revTypeConfigKeys[0]].revenueValue} ${code} `;
  }
  if (config?.cyodEnabled) {
    const goalType = _.camelCase(type);
    const cyodGoalText = GOAL_TYPES.cyodCostPerSuccessEvent.value === goalType ? GOAL_TYPES.cyodCostPerSuccessEvent.text : GOAL_TYPES.cyodRateOfSuccessEvent.text;
    goalStr = `Upload External Measurement - ${cyodGoalText}`;
  }
  if (isAWGOrImpact(type)) {
    const goalOptDirection = _.get(customGoal, 'direction');
    const optimizationDirection = OPTIMIZATION_DIRECTION_TEXT[goalOptDirection].displayName;
    goalStr = isImpactGoal ? `${formattedGoalTarget} - ${optimizationDirection}` : goalStr;
    targetStr = isAWGGoalType(type) ? `${target} - ${optimizationDirection}` : '';
  }

  return { goalStr, revenueStr, targetStr };
};

export const formatGoal = (
  goalType: GoalType,
  goalTarget: number | string,
  currencyCode: string,
  customGoal: Partial<GoalDB> | string,
  hasMultiOutcomeOrValues: boolean = false,
  renderGoalType: boolean = true,
) => {
  let res = '';
  switch (goalType?.valueType) {
    case GOAL_VALUE_TYPE.PERCENTAGE:
      res = `${formatPercent(goalTarget)} ${renderGoalType ? goalType.text : ''}`;
      break;
    case GOAL_VALUE_TYPE.CURRENCY:
      res = hasMultiOutcomeOrValues
        ? `${formatPercent(goalTarget)} ${renderGoalType ? goalType.text : ''}`
        : `${formatMoney(goalTarget, currencyCode)} ${renderGoalType ? goalType.text : ''}`;
      break;
    case GOAL_VALUE_TYPE.LABEL:
      res = `${goalTarget} ${goalType.label} ${renderGoalType ? goalType.text : ''}`;
      break;
    default:
      res = `${renderGoalType ? goalType.text : ''} ${goalTarget}`;
      break;
  }

  if (customGoal) {
    // if customGoal is an object, use displayName, otherwise customGoal is the string we want to use
    const customGoalName = _.get(customGoal, 'displayName', customGoal);
    res = `${customGoalName === GOAL_TYPES.impactOutcome.value ? GOAL_TYPES.impactOutcome.text : customGoalName} ${!isAWGGoalType(goalType.value) ? goalTarget : ''}`;
  }

  return res.trim();
};

export function pluralizer(singlar: string, multiple: string) {
  return function pluralize(quantity: number) {
    return quantity === 1 ? singlar : multiple;
  };
}

export const zpad = (x: number) => numeral(x).format('00');

// Generate uuid based on timestamp
export function generateUUID() { return uuid.v1(); }
// Generate a random uuid
export const generateRandomUUID = () => uuid.v4();

// Swap 2 element in an array:
// i.e: [1, 2, 3, 4, 5], fromX = 1, toY = 3
// res = [1, 4, 3, 2, 5]
// eslint-disable-next-line arrow-parens
export const swapElementInArray = <T>(arr: Array<T>, fromX: number, toY: number) => {
  const maxIndex = arr.length - 1;
  if (maxIndex < 0 || fromX === toY || !isInRange(fromX, 0, maxIndex) || !isInRange(toY, 0, maxIndex)) {
    return arr;
  }

  const res: Array<T> = arr.slice();
  res[fromX] = res.splice(toY, 1, res[fromX])[0];
  return res;
};

const shouldDisplayHyphen = (extId, name) => !_.isEmpty(extId) && !_.isEmpty(name);

export const formatApnObj = (apnObj: ApnObj | null) => {
  if (!apnObj) {
    return '';
  }
  const extIdStr = apnObj.externalId ? `[ ${apnObj.externalId} ]` : '';
  const nameStr: string = _.get(apnObj, 'name', '');
  const hyphenStr: string = shouldDisplayHyphen(extIdStr, nameStr) ? ' - ' : '';
  return `${extIdStr}${hyphenStr}${nameStr}`;
};

export const trimWhiteSpace = (s: string) => _.trim(s.replace(/\s*(\r?\n)\s*/g, '$1'));

export const stringifyAndFormatAsJSON = (obj: unknown, indentAmt: number): string => (
  JSON.stringify(obj, null, indentAmt)
);

// TODO: Add testing!!

// Format a list of items to be displayed as a string with proper grammar:
// Ex: [1, 2, 3, 4] -> '1, 2, 3 and 4'
// https://stackoverflow.com/a/16251861
export const formatListAsString = (a: Array<string>) => (
  [a.slice(0, -1).join(', '), a.slice(-1)[0]].join(a.length < 2 ? '' : ' and ')
);
