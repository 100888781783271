import {
  LOCAL_STORAGE_SET_ITEM_COMPLETED,
} from './constants';

const INITIAL_STATE = {
  ...window.localStorage,
};

export default function localStorage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOCAL_STORAGE_SET_ITEM_COMPLETED:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
}
