/*
 *
 * userGroups reducer
 *
 */
import { Flight, Member, Advertiser } from 'utils/types';

import { GENERIC_ERROR_MSG } from 'constantsBase';

import {
  FETCH_MEMBERS_SUCCEEDED, FETCH_ADVERTISERS_SUCCEEDED, FETCH_ADVERTISERS, FETCH_FLIGHTS_SUCCEEDED,
  FETCH_MEMBERS_FAILED, FETCH_ADVERTISERS_FAILED, RESET_ADVERTISERS, CHECK_USER_GROUPS,
  CHECK_USER_GROUPS_SUCCEEDED, CHECK_USER_GROUPS_FAILED, SET_USER_GROUPS, SET_USER_GROUPS_SUCCEEDED,
  SET_USER_GROUPS_FAILED, LOADING_STATES, EDIT_FORM, REMOVE_USER_GROUPS, REMOVE_USER_GROUPS_SUCCEEDED,
  REMOVE_USER_GROUPS_FAILED, LoadingStates,
} from './constants';

type State = {
  flights: Array<Flight>,
  members: Array<Member>,
  advertisers: Array<Advertiser>,
  advertiserLoadingState: LoadingStates,
  loading: LoadingStates,
  memberFetchError: string | null,
  advertiserFetchError: string | null,
  selectedFlights: Array<Flight | []>,
  userGroupData: Array<unknown>,
  checkedUserGroupData: Array<unknown>,
  errorMsg: string | null,
};

export const INITIAL_STATE: State = {
  flights: [],
  members: [],
  advertisers: [],
  advertiserLoadingState: LOADING_STATES.notLoading,
  userGroupData: [],
  checkedUserGroupData: [],
  loading: LOADING_STATES.notLoading,
  memberFetchError: null,
  advertiserFetchError: null,
  selectedFlights: [],
  errorMsg: null,
};

function userGroupReducer(state: State = INITIAL_STATE, action: { type: string, payload: Object }) {
  switch (action.type) {
    case FETCH_MEMBERS_SUCCEEDED:
      return { ...state, members: action.payload };
    case FETCH_MEMBERS_FAILED:
      return { ...state, memberFetchError: action.payload };
    case FETCH_ADVERTISERS_FAILED:
      return { ...state, advertiserFetchError: action.payload };
    case FETCH_ADVERTISERS:
      return { ...state, advertiserLoadingState: LOADING_STATES.loading };
    case FETCH_ADVERTISERS_SUCCEEDED:
      return { ...state, advertisers: action.payload, advertiserLoadingState: LOADING_STATES.notLoading };
    case FETCH_FLIGHTS_SUCCEEDED:
      return { ...state, flights: action.payload };
    case CHECK_USER_GROUPS:
    case SET_USER_GROUPS:
    case REMOVE_USER_GROUPS:
      return { ...state, loading: LOADING_STATES.loading };
    case CHECK_USER_GROUPS_SUCCEEDED:
      return {
        ...state,
        userGroupData: [],
        checkedUserGroupData: action.payload,
        loading: LOADING_STATES.notLoading,
        errorMsg: null,
      };
    case SET_USER_GROUPS_SUCCEEDED:
    case REMOVE_USER_GROUPS_SUCCEEDED:
      return {
        ...state,
        userGroupData: action.payload,
        checkedUserGroupData: [],
        loading: LOADING_STATES.success,
        errorMsg: null,
      };
    case CHECK_USER_GROUPS_FAILED:
    case SET_USER_GROUPS_FAILED:
    case REMOVE_USER_GROUPS_FAILED:
      return {
        ...state,
        loading: LOADING_STATES.notLoading,
        userGroupData: [],
        errorMsg: GENERIC_ERROR_MSG,
      };
    case EDIT_FORM:
      return { ...state, loading: LOADING_STATES.notLoading, userGroupData: [] };
    case RESET_ADVERTISERS:
      return { ...state, advertisers: [], userGroupData: [], loading: LOADING_STATES.notLoading, errorMsg: null };
    default:
      return state;
  }
}

export default userGroupReducer;
