import * as _ from 'lodash';

// function createEnum<T extends { id: string }, K extends keyof T>(obj: T): T & { getById: (id: string) => T, getNameById: (id: string) => K} {
function createEnum<T extends object>(obj: T): T & { getById: (id: string | number) => T[keyof T], getNameById: (id: string | number) => string } {
  const lookup = _.keyBy(obj, 'id');
  const nameLookup = _.invert(_.mapValues(obj, 'id'));
  const ret = { ...obj, getById: (id: string | number) => lookup[id], getNameById: (id: string | number) => nameLookup[id] };
  return ret;
}

export default createEnum;
