import React from 'react';
import UnknownErrorPage from 'containers/UnknownError';
import { PageUnknownErrorMessage, PageUnknownErrorFooter } from 'containers/UnknownError/components/PageUnknownError';

const UnknownError = () => (
  <UnknownErrorPage
    message={<PageUnknownErrorMessage />}
    customFooter={<PageUnknownErrorFooter />}
  />
);

export default UnknownError;
