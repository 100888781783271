import { USERS_FETCH_COMPLETED } from './constants';

const INITIAL_STATE = {
  members: [],
  users: [],
  usersCount: 100,
  loading: true,
};

export default function usersListing(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USERS_FETCH_COMPLETED:
      return {
        ...state,
        users: action.payload.users,
        usersCount: action.payload.usersCount,
        loading: false,
      };
    default:
      return state;
  }
}
