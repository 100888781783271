import _ from 'lodash';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';

export const setFlightExternalTypeString = (displayName: string) => {
  if (displayName === FLIGHT_EXTERNAL_TYPE.apnLineItem.displayName) {
    return 'line-item';
  }
  return _.kebabCase(_.lowerCase(displayName));
};

export const newGroupRange = (selectedFlightLength: number) => {
  switch (selectedFlightLength) {
    case 2:
      return ['0-49', '50-99'];
    case 3:
      return ['0-33', '34-66', '67-99'];
    case 4:
      return ['0-24', '25-49', '50-74', '75-99'];
    case 5:
      return ['0-19', '20-39', '40-59', '60-79', '80-99'];
    default:
      return ['-'];
  }
};
