import _ from 'lodash';
import fp from 'lodash/fp';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { isNumeric } from './functionHelpers';

const toNumber = (value: any) => (isNumeric(value) ? Number(value) : NaN);

/**
 * validation helpers to be used with redux form validation.
 * individual helper functions to check different types of fields go here as well as the functions that can
 * apply many in one call.
 */

type FieldChecker<T = any> = (obj: { [key: string]: T }, key: string) => {} | { [key: string] : string };

/**
 * required checks to see if field has some non-falsy value
 * @param  formValues object to validate
 * @param  fieldName  field to validate
 * @return            empty object if no errors else { fieldName: errorString }
 */
export const required: FieldChecker = (formValues, fieldName) => {
  if (_.isNil(formValues[fieldName]) || _.trim(formValues[fieldName]) === '') {
    return { [fieldName]: 'Required' };
  }
  // Need to check if is finite before isEmpty, isEmpty consider 0 as empty
  if (isFinite(formValues[fieldName])) {
    return {};
  }
  if (_.isObjectLike(formValues[fieldName]) && _.isEmpty(formValues[fieldName])) {
    return { [fieldName]: 'Required' };
  }
  return {};
};

export const isEmailFormat: FieldChecker = (formValues, fieldName) => {
  const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  if (!emailReg.test(formValues[fieldName])) {
    return { [fieldName]: 'Incorrect Email Format' };
  }
  return {};
};

/**
 * isNumber checks to see if a field is a number
 * @param  formValues object to validate
 * @param  fieldName  field to validate
 * @return            empty object if no errors else { fieldName: errorString }
 */
export const isNumber: FieldChecker = (formValues, fieldName) => {
  if (!fp.isFinite(toNumber(formValues[fieldName]))) {
    return { [fieldName]: 'Must be a number' };
  }
  return {};
};

/**
 * isInteger checks to see if a field is an integer
 * @param  formValues object to validate
 * @param  fieldName  field to validate
 * @return            empty object if no errors else { fieldName: errorString }
 */
export const isInteger: FieldChecker = (formValues, fieldName) => {
  if (!fp.isInteger(toNumber(formValues[fieldName]))) {
    return { [fieldName]: 'Must be an integer' };
  }
  return {};
};

type Compare = (x: unknown, y: unknown) => boolean;
type CreateErrorMessage = (x: unknown, y?: unknown) => string;

type CompareFunction =
    (compareValue: string | any, customErrorMessage?: CreateErrorMessage) =>
    (formValues: { [key: string]: unknown }, fieldName: string) => {};

type CreateCompareFunction = (compare: Compare, defaultErrorMessage: CreateErrorMessage) => CompareFunction;

/**
 * createCompareFunction used to create new compare function
 * @param {(x, y) => boolean} - function that takes two arguments and compares them and will return a bool.
 * @param {value => string} - function that will get called to display error message.
 */
export const createCompareFunction: CreateCompareFunction = (
  (compare, defaultErrorMessage) => (
    (compareValue, customErrorMessage) => (
      (formValues, fieldName) => {
        const testValue = _.get(formValues, compareValue) ? _.get(formValues, compareValue) : compareValue;
        const inputValue = formValues[fieldName];
        return (
          compare(inputValue, testValue) ? {}
            : {
              [fieldName]: customErrorMessage
                ? customErrorMessage(testValue, inputValue)
                : defaultErrorMessage(testValue),
            }
        );
      }
    )
  )
);

export const isEqualTo: CompareFunction = createCompareFunction(
  (formValue, testValue) => _.isEqual(formValue, testValue),
  (testValue) => `Must be equal to ${testValue}`,
);

export const isNotEqualTo = createCompareFunction(
  (formValue, testValue) => !_.isEqual(formValue, testValue),
  (testValue) => `Should not be equal to ${testValue}`,
);

type MathCompare = (x: number, xs: number[]) => boolean;
// looks the same as CreateCompareFunction but * ends up being different and not compatable with
// CreateCompareFunction
type CreateMathErrorMessage = (x: any, y: unknown | null) => string;

type CreateMathCompareFunction =
  (mathCompare: MathCompare, defaultErrorMessage: CreateMathErrorMessage) =>
  (compareValue: any, customMathErrorMessage?: CreateMathErrorMessage) =>
  (formValues: { [key: string]: any }, fieldName: string) => {};

/**
 * createMathCompareFunction used to create new Math compare function
 * both operands will be cast into numbers
 * @param {(x, y) => boolean} - function that takes two arguments and compares them and will return a bool.
 * @param {value => string} - function that will get called to display error message.
 */
export const createMathCompareFunction: CreateMathCompareFunction = (
  (compareFn, defaultErrorMessage) => (
    (compareValue, customErrorMessage) => (
      (formValues, fieldName) => {
        // compareValue is either:
        // - a string => another field from the form
        // - a number => a value to compare with
        // - an array => an array of value to compare with
        let testValue: number | Array<number> = compareValue;
        if (typeof compareValue === 'string') {
          testValue = toNumber(formValues[compareValue]);
        } else if (Array.isArray(compareValue)) {
          testValue = compareValue.map((v) => (typeof v === 'string' ? toNumber(formValues[v]) : v));
        } else {
          testValue = toNumber(compareValue);
        }

        const testValueAsArray = Array.isArray(testValue) ? testValue : [testValue];
        const inputValue = toNumber(formValues[fieldName]);
        return (
          fp.some(Number.isNaN, testValueAsArray) || compareFn(inputValue, testValueAsArray)
            ? {}
            : {
              [fieldName]: customErrorMessage
                ? customErrorMessage(testValueAsArray, inputValue)
                : defaultErrorMessage(testValueAsArray, inputValue),
            }
        );
      }
    )
  )
);

/**
 * isLess is a function that can be used to check if a field value is smaller than another
 * inside a validator object it can be used as { a: isLess(30) }
 * @param {testValue} value to use when comparing against other values
 * @return {(formValues, fieldName) => boolean} a function for comparing values
 */
export const isLess = createMathCompareFunction(
  (formValue, [testValue]) => !fp.isFinite(testValue) || formValue < testValue,
  ([testValue]) => `Must be smaller than ${testValue}`,
);

// type MathCompareFunction =
//   (number | [number, number] | string,
//     ?(number[], ?number) => string)
//       => (formValues: { [key: string]: any }, s: string) => {};

type FirstArg = number | [number, number] | string;
type SecondArg = ((xs: number[], x: number | null) => string) | null;

export type MathCompareFunction =
  (x: FirstArg, y?: SecondArg)
  => (formValues: { [key: string]: any }, s: string) => {};

/**
 * isGreater is a function that can be used to check if a field value is larger than another
 * inside a validator object it can be used as { a: isGreater(30) }
 * @param {testValue} value to use when comparing against other values
 * @return {(formValues, fieldName) => boolean} a function for comparing values
 */
export const isGreater = createMathCompareFunction(
  (formValue, [testValue]) => !fp.isFinite(testValue) || formValue > testValue,
  ([testValue]) => `Must be greater than ${testValue}`,
);

/**
 * isGreater is a function that can be used to check if a field value is larger than another
 * inside a validator object it can be used as { a: isGreater(30) }
 * @param {testValue} value to use when comparing against other values
 * @return {(formValues, fieldName) => boolean} a function for comparing values
 */
export const isGreaterOrEqual = createMathCompareFunction(
  (formValue, [testValue]) => !fp.isFinite(testValue) || formValue >= testValue,
  ([testValue]) => `Must be greater or equal to ${testValue}`,
);

export const isLessOrEqual = createMathCompareFunction(
  (formValue, [testValue]) => !fp.isFinite(testValue) || formValue <= testValue,
  ([testValue]) => `Must be lesser or equal to ${testValue}`,
);

type CrossValidate =
  (compareFn: CompareFunction, s: string) =>
  (formValues: { [key: string]: any }, otherS: string) => {};

/**
 * crossValidate - a function used to create crossValidation function IE field must be greater than another
 * inside a validation object use { a: [isNumber, crossValidate(isGreater, 'b')], b: isNumber }
 * this will check that a is greater than b when the validation runs.
 * @param  {fn => (formValues, fieldName) => Boolean} fn - validation function that takes one argument
 * @param  {string} crossValidationFieldName             - field to validate against
 * @return {(formValues, fieldName) => Boolean}          - function for validation
 */
export const crossValidate: CrossValidate = (fn, crossValidationFieldName) => (
  (formValues, fieldName) => fn(crossValidationFieldName)(formValues, fieldName)
);

/**
 * applyValidators will run all of the given validators against the given field
 * (IE isNumber and required) -> applyValidators(values, 'myField', [required, isNumber])
 * if you only have a single validator you pass it by itself.
 * @param  formValues the object to validate
 * @param  fieldName  field that we want to validate
 * @param  validators function(s) to validate the field
 * @return            empty object if no errors else { fieldName: errorString }
 */
// eslint-disable-next-line arrow-parens
export const applyValidators = <T>(
  formValues: {},
  fieldName: string,
  validators: FieldChecker<T> | FieldChecker<T>[],
) => {
  const fns = Array.isArray(validators) ? validators : [validators];
  return fp.flow(
    fp.map((fn: FieldChecker<T>) => fn(formValues, fieldName)),
    fp.mergeAll,
  )(fns);
};

type ArrayOrSingle<T> = T | Array<T>;

/**
 * [applyAllValidators will apply all of the validators provided against the given values formValuesect
 * fieldNamesToValidators should be in the form { fieldName: [validator, validator1] }]
 * if you only have a single validator you can leave of the array.
 * @param  {Object} formValues             formValues the formValuesect that we are validating
 * @param  {Object} fieldNamesToValidators functions to be used in validation
 * @return {Object}                        error formValuesect keys of fields that have errors and values will be
 * error strings
 */
export const applyAllValidators = (
  formValues: {},
  fieldNamesToValidators: { [key: string]: ArrayOrSingle<FieldChecker<unknown>> | ArrayOrSingle<Compare> },
) => fp.flow(
  fp.toPairs,
  fp.map(([fieldName, fns]) => applyValidators(formValues, fieldName, fns)),
  fp.mergeAll,
)(fieldNamesToValidators);

export const mapValidator = (
  validators: { [key: string]: FieldChecker<string> | FieldChecker<string>[] },
) => (formValues: {}, fieldName: string) => {
  const errors = fp.map((obj) => applyAllValidators(obj, validators), formValues[fieldName]);
  const hasErrors = fp.flow(
    fp.map(fp.negate(fp.isEmpty)),
    fp.some(fp.identity),
  )(errors);
  // if there are no errors we don't want to add any keys to the validation dict
  if (!fp.isEmpty(errors) && hasErrors) {
    return { [fieldName]: errors };
  }
  return {};
};

type NestedValidator = (compare: { [key: string]: FieldChecker<unknown> }) => FieldChecker<any>;

export const nestedValidator: NestedValidator = (validator) => (formValues, fieldName) => {
  if (!formValues[fieldName]) {
    return { [fieldName]: 'Required' };
  }
  const errors = applyAllValidators(formValues[fieldName], validator);
  if (!fp.isEmpty(errors)) {
    return { [fieldName]: errors };
  }
  return {};
};

type ListValidator = (fieldChecker: FieldChecker<number>[]) => (formValues: { [key: string]: any }, s: string) => {};

/**
 * listValidator validator for lists of primiatives. This is required because of the current interface of
 * applyValidators and applyAllValidators both are expecting objects, if you pass a primative like number
 * that doesn't have any fields the API breaks down. Might need to refactor based on this...
 * ended up needing a bunch of tricks to convert from array like to arrays.
 */
export const listValidator: ListValidator = (validators) => (formValues, fieldName) => {
  const errors = fp.flow(
    // @ts-ignore jrose string number index
    fp.map((index) => applyValidators(formValues[fieldName], index, validators)),
    fp.toPairs,
    fp.map(([index, value]) => (fp.isEmpty(value) ? { [index]: undefined } : value)),
    fp.mergeAll,
    fp.toPairs,
    fp.map(([, value]) => value),
  )(
    fp.range(0, formValues[fieldName].length),
  );
  if (!fp.isEmpty(errors) && _.some(errors)) {
    return { [fieldName]: errors };
  }
  return {};
};

type ConditionalValidate =
  (fn: (x: any) => boolean, validatorObj: { [key: string]: any }) =>
  (formValues: { [key: string]: any }, s: string) => {};

export const conditionalValidate: ConditionalValidate = (fn, validatorObj) => (formValues, fieldName) => {
  if (fn(formValues[fieldName])) {
    return applyAllValidators(formValues, validatorObj);
  }
  return {};
};

type ComposeValidator<T> =
  (compareA: FieldChecker<T>, compareB: FieldChecker<T>)
  => FieldChecker<T>;

export const composeValidator: ComposeValidator<unknown> = (left, right) => (formValues, fieldName) => {
  const ret = left(formValues, fieldName);
  if (fp.isEmpty(ret)) {
    return right(formValues, fieldName);
  }
  return ret;
};

type ListLengthValidator = (
  fn: (x: number, y: number) => boolean,
  num: number,
  err: string,
) => (formValues: { [key: string]: any }, fieldName: string) => {};

/**
 * listLengthValidator compares the length of a list against a passed in comparison operator function and number value.
 */
export const listLengthValidator: ListLengthValidator = (fn, num, err) => (formValues, fieldName) => {
  const ret = _.get(formValues, fieldName);
  if (!_.isUndefined(ret)) {
    if (!fn(num, ret.length)) {
      return { [fieldName]: err };
    }
  }
  return {};
};

/**
 * isBetweenOrEqual checks to see if a field is between start and end inclusive
 * inside a validator object it can be used as { a: isBetweenOrEqual([0, 42]) }
 * @param {[start, end]} value to use when comparing against other values
 * @return {(formValues, fieldName) => boolean} a function for comparing values
 */
export const isBetweenOrEqual = createMathCompareFunction(
  (formValue, [start, end]) => !fp.isFinite(start) || !fp.isFinite(end) || (formValue >= start && end >= formValue),
  ([start, end]) => `Must be between or equal to ${start} and ${end}`,
);

export const isBetweenOrEqualToEnd = createMathCompareFunction(
  (formValue, [start, end]) => !fp.isFinite(start) || !fp.isFinite(end) || (formValue > start && end >= formValue),
  ([start, end]) => `Must be above ${start} and less than or equal to ${end}`,
);

export const percentageValidation = (formValues: {}, fieldName: string) => (
  isBetweenOrEqual([0, 1], () => 'Must be between 0 and 100')(formValues, fieldName)
);

export const nonZeroPercentageValidation = (formValues: {}, fieldName: string) => (
  isBetweenOrEqualToEnd([0, 1], () => 'Must be above 0 and less than or equal to 100')(formValues, fieldName)
);

export const goalTypeCustomValidation = (formValues: {}, fieldName: string) => (
  _.get(formValues, 'goalType.valueType') === GOAL_VALUE_TYPE.PERCENTAGE
    ? nonZeroPercentageValidation(formValues, fieldName)
    : isGreater(0)(formValues, fieldName)
);

export const strategyGoalsValidation = (formValues: {}, fieldName: string) => {
  const errors: { strategyGoals?: unknown } = {};
  const field = _.get(formValues, fieldName);
  const strategyGoalsErrors = [];
  _.each(field, (f, i) => {
    const goalErrors: { type?: string, target?: string } = {};
    if (!f.type) {
      goalErrors.type = 'Required';
    }
    if (!f.target) {
      goalErrors.target = 'Required';
    }
    if (f.target) {
      if (f.target <= 0) {
        goalErrors.target = 'Must be greater than 0';
      } else if (f.type.valueType === GOAL_VALUE_TYPE.PERCENTAGE && f.target >= 1) {
        goalErrors.target = 'Must be between 0 and 100';
      }
    }
    if (!_.isEmpty(goalErrors)) {
      strategyGoalsErrors[i] = goalErrors;
    }
  });
  if (strategyGoalsErrors.length) {
    errors.strategyGoals = strategyGoalsErrors;
  }
  return errors;
};
