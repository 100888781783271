import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Form, Grid, Icon, Popup } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import { WidgetWrapper } from 'utils/widget';
import { User } from 'utils/types';
import { APPROVER_PLACEHOLDER } from '../constants';
import { UserForm, Mode, ApproverValidationMode } from '../types';
import { isAdmin } from '../utils';
import RequestAccessSection from './RequestAccessSection';
import { EmailStatusMessage, ValidationMessage } from './StatusMessages';

const ApproverTooltip = () => (
  <Popup
    hoverable
    trigger={<Icon name="question circle outline" color="grey" />}
    content="Setting an approver will allow you to edit or update your permissions."
    position="right center"
  />
);

const ApproverHelpText = () => (
  <div style={{ fontSize: '12px', marginTop: '5px', marginBottom: '5px' }}>
    Please input an approver email to manage your Copilot roles and permissions.
    Approvers must be existing Copilot users.
    For further support please reach out to the
    <a
      role="button"
      tabIndex={0}
      style={{ color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE, marginLeft: '5px', cursor: 'pointer' }}
      onClick={() => WidgetWrapper('open')}
    >
      Copilot team
    </a>.
  </div>
);

type ApproverProps = {
  showRequestNotes: boolean,
  showRequestAccessButton: boolean,
  onChangeRequestNotes: (e: React.ChangeEvent<HTMLTextAreaElement>, data: { value: string }) => void,
  handleAccessRequestSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void,
  approver: string,
  showEmailStatus: boolean,
  onChangeApprover: (value: string) => void,
  validatingApprover: boolean,
  approverValidationMode: ApproverValidationMode,
  requestNotes: string,
  disabled: boolean,
  userToViewOrEdit: User,
  mode: Mode,
  sendEmailErrorMsg: string,
};

const Approver = ({ showRequestNotes, showRequestAccessButton,
  onChangeRequestNotes, handleAccessRequestSubmit, approver,
  showEmailStatus, onChangeApprover,
  validatingApprover, approverValidationMode, requestNotes,
  disabled, userToViewOrEdit, mode, sendEmailErrorMsg,
}: ApproverProps) => {
  const { control } = useFormContext<UserForm>();
  const email = useWatch({ name: 'email' });
  const emailApproverError = !isAdmin(userToViewOrEdit) && (approver === email);
  const showMessage = !approver && mode !== Mode.view;
  const showError = showMessage || emailApproverError;

  return (
    <>
      <Grid.Row>
        <Grid.Column width={4}>
          <Form.Field>
            <span>
              <span style={{ fontSize: '14px' }}><label htmlFor="approver" className="required-field-label">Approver</label></span>
              <span style={{ marginLeft: '10px' }}><ApproverTooltip /></span>
            </span>
            {showMessage && (<ApproverHelpText />)}
            <Controller
              name="approver"
              control={control}
              render={() => (
                <Form.Input
                  name="approver"
                  value={approver}
                  onChange={(_e, data) => {
                    onChangeApprover(data.value);
                  }}
                  placeholder={APPROVER_PLACEHOLDER}
                  required
                  error={showError ? { content: emailApproverError ? 'User and approver emails must be different' : 'Approver Required' } : false}
                  disabled={disabled}
                />
              )}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={4}>
          <div style={{ marginTop: '30px', marginLeft: '-50px' }}>
            <ValidationMessage
              validatingApprover={validatingApprover}
              validationMode={approverValidationMode}
              mode={approver ? Mode.edit : Mode.view}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      {showEmailStatus && (
        <EmailStatusMessage sendEmailErrorMsg={sendEmailErrorMsg} />
      )}
      {!showEmailStatus && (
        <RequestAccessSection
          showRequestNotes={showRequestNotes}
          showRequestAccessButton={showRequestAccessButton}
          onChangeRequestNotes={onChangeRequestNotes}
          handleAccessRequestSubmit={handleAccessRequestSubmit}
          approver={approver}
          requestNotes={requestNotes}
        />
      )}
    </>
  );
};

export default Approver;
