import _ from 'lodash';
import { put, select, takeEvery, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { setItem } from 'utils/localstorage/actions';
import { getUserId, buildKey } from 'utils/localstorage/utils';
import {
  USER_SETTINGS_LOAD_SELECTED_THEME,
  USER_SETTINGS_CHANGE_THEME,
} from './constants';
import { themeSettingLSKey } from './UserSettings';

export function* loadThemeSaga() {
  const userId = yield select(getUserId);
  const myKey = buildKey(userId, themeSettingLSKey);
  const currentClass = window.localStorage.getItem(myKey);
  yield document.documentElement.className = currentClass;
}

export function* changeThemeSaga({ payload: { theme } } : AnyAction) {
  const userId = yield select(getUserId);
  const myKey = buildKey(userId, themeSettingLSKey);
  if (theme) {
    document.documentElement.className = theme;
  } else {
    const currentClass = window.localStorage.getItem(myKey);
    document.documentElement.className = _.isNull(currentClass) || currentClass === '' ? 'dark' : '';
  }
  yield put(setItem(themeSettingLSKey, document.documentElement.className));
}

// Individual exports for testing
export function* userThemeSagas() {
  yield all([
    takeEvery(USER_SETTINGS_CHANGE_THEME, changeThemeSaga),
    takeEvery(USER_SETTINGS_LOAD_SELECTED_THEME, loadThemeSaga),
  ]);
}

// All sagas to be loaded
export default [
  userThemeSagas,
];
