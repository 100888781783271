import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Label, Button } from 'buildingBlocks';
import { noBorderRadius, userButton } from '../style';

type Props = {
  statusMsg: string
  disableSave: boolean
  onUserProfile?: boolean
  isNewUser?: boolean
};

const Footer = ({ statusMsg, disableSave, onUserProfile, isNewUser }: Props) => (
  <Grid.Row columns={2}>
    <Grid.Column floated="left" width={8}>
      <Label
        basic
        htmlFor="statusMsg"
        size="big"
        style={noBorderRadius}
        color="red"
        content={statusMsg}
      />
    </Grid.Column>
    <Grid.Column floated="right">
      <Button
        type="submit"
        id="user-detail-save-btn"
        primary
        style={userButton}
        floated="right"
        content={isNewUser ? 'Save and Request Access' : 'Save'}
        disabled={disableSave}
      />
      <Link to={onUserProfile ? '/' : '/users'}>
        <Button id="user-detail-cancel-btn" content="Cancel" style={noBorderRadius} floated="right" />
      </Link>
    </Grid.Column>
  </Grid.Row>
);

export default Footer;
