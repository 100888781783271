import {
  MEMBERS_FETCH_FAILED,
  MEMBERS_FETCH_SUCCEEDED,
  ROLES_FETCH_FAILED,
  ROLES_FETCH_SUCCEEDED,
  USER_STATUS_MSG,
  RESET_ALL,
  USER_ROLE_FETCH_SUCCEEDED,
  UPDATE_MEMBER_ROLES,
  USER_CREATE,
  UPDATE_USER,
  MEMBER_ROLES_MAP_FETCH_SUCCEEDED,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_FAILURE,
  USER_VALIDATION,
  VALIDATING_USER,
  SAVE_APPROVER,
  REQUEST_NOTES_DEFAULT_TEXT,
} from './constants';
import { ApproverValidationMode } from './types';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  members: [],
  roles: [],
  memberRoleMap: {},
  statusMsg: '',
  memberRoles: [],
  memberRolesToSave: [],
  memberRolesToDelete: [],
  saving: false,
  approver: null,
  users: [],
  approverRecordId: null,
  requestNotes: REQUEST_NOTES_DEFAULT_TEXT,
  showEmailStatus: false,
  validatingApprover: false,
  approverValidationMode: null,
  sendEmailErrorMsg: '',
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MEMBERS_FETCH_FAILED:
    case ROLES_FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case MEMBERS_FETCH_SUCCEEDED:
      return {
        ...state,
        members: action.payload,
      };
    case ROLES_FETCH_SUCCEEDED:
      return {
        ...state,
        roles: action.payload,
      };
    case USER_CREATE:
      return {
        ...state,
        saving: true,
      };
    case UPDATE_USER:
      return {
        ...state,
        saving: true,
      };
    case MEMBER_ROLES_MAP_FETCH_SUCCEEDED:
      return {
        ...state,
        memberRoleMap: action.payload,
      };
    case USER_STATUS_MSG:
      return {
        ...state,
        statusMsg: action.message,
        saving: false,
      };
    case RESET_ALL:
      return {
        ...INITIAL_STATE,
      };
    case USER_ROLE_FETCH_SUCCEEDED:
    {
      return {
        ...state,
        firstName: action.payload.user?.firstName,
        lastName: action.payload.user?.lastName,
        email: action.payload.user?.email,
        memberRoles: action.payload.memberRoles,
        approver: action.payload.approver,
        memberRolesToSave: [],
        memberRolesToDelete: [],
      };
    }
    case UPDATE_MEMBER_ROLES:
      return {
        ...state,
        memberRoles: action.payload.memberRoles || state.memberRoles,
        memberRolesToSave: action.payload.memberRolesToSave || state.memberRolesToSave,
        memberRolesToDelete: action.payload.memberRolesToDelete || state.memberRolesToDelete,
      };
    case EMAIL_SENT_SUCCESS: {
      return { ...state, showEmailStatus: true, emailSent: true };
    }
    case EMAIL_SENT_FAILURE: {
      return { ...state, showEmailStatus: true, emailSent: false, sendEmailErrorMsg: action.payload.error };
    }
    case USER_VALIDATION: {
      return {
        ...state,
        validatingApprover: false,
        approverValidationMode: action.payload.isValid === true ? ApproverValidationMode.valid : ApproverValidationMode.invalid,
      };
    }
    case VALIDATING_USER: {
      return {
        ...state,
        validatingApprover: true,
        approverValidationMode: null,
      };
    }
    case SAVE_APPROVER: {
      return {
        ...state,
        approver: action.payload.approver,
      };
    }
    default:
      return state;
  }
}
