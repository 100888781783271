import { COPILOT_LAYOUT, COPILOT_COLORS } from 'globalStyles';
import { CSSProperties } from 'react';

const { WPP } = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

const userGroupPageStyles: { [key: string]: CSSProperties | any } = {
  flightSplitHeader: {
    fontWeight: 500,
    color: '#1a3849',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  newColBox: {
    color: '#1a3849',
    fontWeight: '500',
    width: '76px',
    padding: '14px 0',
    textAlign: 'center',
  },
  setUserGroupsStyle: {
    memberAdvertiserContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    formTitleStyle: {
      marginBottom: SPACING[8],
    },
    radionBtnStyle: {
      marginTop: SPACING[8],
    },
    radioBtnContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableStyle: {
      width: '10%',
    },
    errorMessageStyle: {
      display: 'block',
      marginTop: SPACING[8],
      color: WPP.danger500,
    },
    selectedActionTypeText: {
      marginTop: SPACING[4],
    },
    flightItemNewCol: {
      display: 'flex',
      justifyContent: 'center',
    },
    flightItemDeleteIconStyle: {
      cursor: 'pointer',
    },
    flightItemNewColText: {
      marginRight: '22px',
    },
    setUserGroupsBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: SPACING[12],
      padding: '32px 0px 0px',
    },
    dividerStyle: {
      margin: '24px 0px',
    },
  },
};

export default userGroupPageStyles;
