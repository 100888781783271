/*
 *
 * UserSettings actions
 *
 */

import {
  USER_SETTINGS_CHANGE_THEME,
  USER_SETTINGS_LOAD_SELECTED_THEME,
} from './constants';

export function loadTheme() {
  return {
    type: USER_SETTINGS_LOAD_SELECTED_THEME,
  };
}

export function changeTheme(theme: string | void) {
  return {
    type: USER_SETTINGS_CHANGE_THEME,
    payload: { theme },
  };
}
