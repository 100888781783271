/*
 *
 * UserGroup actions
 *
 */

import { Member, Flight } from 'utils/types';

import {
  FETCH_MEMBERS, FETCH_MEMBERS_SUCCEEDED, FETCH_MEMBERS_FAILED,
  FETCH_ADVERTISERS_SUCCEEDED, FETCH_ADVERTISERS_FAILED, FETCH_ADVERTISERS, FETCH_FLIGHTS, FETCH_FLIGHTS_SUCCEEDED,
  FETCH_FLIGHTS_FAILED, RESET_ADVERTISERS, CHECK_USER_GROUPS, REMOVE_USER_GROUPS,
  SET_USER_GROUPS, EDIT_FORM,
} from './constants';

export function fetchMembers(memberIds: Array<number>, dspIds?: Array<number>) {
  return {
    type: FETCH_MEMBERS,
    payload: { memberIds, dspIds },
  };
}

export function fetchMembersSucceeded(members: Array<Member>) {
  return {
    type: FETCH_MEMBERS_SUCCEEDED,
    payload: members,
  };
}

export function fetchMembersFailed(msg: string) {
  return {
    type: FETCH_MEMBERS_FAILED,
    payload: msg,
  };
}

export function fetchAdvertisers(member: Member, searchTerm: string) {
  return {
    type: FETCH_ADVERTISERS,
    payload: { member, searchTerm },
  };
}

export function fetchAdvertisersSucceeded(members: Array<Member>) {
  return {
    type: FETCH_ADVERTISERS_SUCCEEDED,
    payload: members,
  };
}

export function fetchAdvertisersFailed(msg: string) {
  return {
    type: FETCH_ADVERTISERS_FAILED,
    payload: msg,
  };
}

export function fetchFlights(memberId: number, advertiserId: number, flightType: number, searchString: string = '', includeDisabledFlights: boolean, excludedIds: Array<number> = null) {
  return {
    type: FETCH_FLIGHTS,
    payload: {
      memberId, advertiserId, flightType, searchString, includeDisabledFlights, excludedIds,
    },
  };
}

export function fetchFlightsSucceeded(flights: Array<Flight>) {
  return {
    type: FETCH_FLIGHTS_SUCCEEDED,
    payload: flights,
  };
}

// TODO do something with this
export function fetchFlightsFailed() {
  return {
    type: FETCH_FLIGHTS_FAILED,
  };
}

export function editForm() {
  return {
    type: EDIT_FORM,
  };
}

export function resetForm() {
  return {
    type: RESET_ADVERTISERS,
  };
}

export const setUserGroupForm = (memberExtId: string, flightExtIds: Array<number>, flightExternalType: string) => ({
  type: SET_USER_GROUPS,
  payload: {
    memberExtId,
    flightExtIds,
    flightExternalType,
  },
});

export const checkUserGroupForm = (memberExtId: string, flightExtIds: Array<number>, flightExternalType: string) => ({
  type: CHECK_USER_GROUPS,
  payload: {
    memberExtId,
    flightExtIds,
    flightExternalType,
  },
});

export const removeUserGroupForm = (memberExtId: string, flightExtIds: Array<number>, flightExternalType: string) => ({
  type: REMOVE_USER_GROUPS,
  payload: {
    memberExtId,
    flightExtIds,
    flightExternalType,
  },
});
