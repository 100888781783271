/**
a slightly more usable set class that has all of most of the useful set functions on it.
can be used as a drop in replacement by doing `import Set from 'utils/betterSet';`
 */

class BetterSet<T> extends Set<T> {
  get length() {
    return this.size;
  }

  isSuperSet(subset: Iterable<T>) {
    // eslint-disable-next-line no-restricted-syntax
    for (const elem of subset) {
      if (!this.has(elem)) {
        return false;
      }
    }
    return true;
  }

  union(otherSet: Iterable<T>) {
    const res = new BetterSet<T>(this);
    // eslint-disable-next-line no-restricted-syntax
    for (const elem of otherSet) {
      res.add(elem);
    }
    return res;
  }

  intersection(otherSet: Iterable<T>) {
    const res = new BetterSet<T>();
    // eslint-disable-next-line no-restricted-syntax
    for (const elem of otherSet) {
      if (this.has(elem)) {
        res.add(elem);
      }
    }
    return res;
  }

  difference(otherSet: Iterable<T>) {
    const res = new BetterSet<T>(this);
    // eslint-disable-next-line no-restricted-syntax
    for (const elem of otherSet) {
      res.delete(elem);
    }
    return res;
  }

  symDifference(otherSet: BetterSet<T>) {
    const left = this.difference(otherSet);
    const right = otherSet.difference(this);
    return left.union(right);
  }

  map<OtherT>(fn: (x: T) => OtherT): BetterSet<OtherT> {
    const ret = new BetterSet<OtherT>();
    this.forEach((elem) => {
      ret.add(fn(elem));
    });
    return ret;
  }
}

export default BetterSet;
