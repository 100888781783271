export const ELEMENT_ID = {
  createStrategyButton: 'create-strategy-button',
  navBar: {
    strategies: 'nav-bar-strategies',
    helpCenter: 'nav-bar-help-center',
    tools: 'nav-bar-tools',
    admin: 'nav-bar-admin',
    search: 'nav-bar-copilot-search',
    searchDropdownItem: 'nav-bar-search-dropdown-item',
    notification: 'nav-bar-notification-nav',
    notificationInbox: 'notification-container',
    notificationBellContainer: 'nav-bar-notification-bell-container',
    user: 'nav-bar-user',
    notificationBellActionRequired: 'nav-bar-notification-bell-action-required',
    notificationBellAllNotification: 'nav-bar-notification-bell-all-notification',
    notificationBellContainerSetting: 'nav-bar-notification-bell-container-setting',
  },
} as const;

export const HOME_PAGE_ELEMENT_TAG = {
  sortBtn: 'home-page-sort-btn',
  sortDropdown: 'home-page-sort-dropdown',
  createStratBtn: 'home-page-new-strategy-btn',
  bulkCreateStratBtn: 'home-page-bulk-create-strategy-btn',
  filterByStratName: 'home-page-filter-by-strat-name',
  filterByDspOb: 'home-page-filter-by-dsp-obj',
  filterByLastMod: 'home-page-filter-by-last-modified',
  stratDetailHamburgerMenu: 'home-page-strat-detail-hamburger-menu',
  stratDetailHamburgerMenuDelete: 'home-page-strat-detail-hamburger-menu-delete',
};

export const STRATEGY_WIZARD_BUTTON_TAG = {
  createStrategy: 'create-strategy',
  updateStrategy: 'update-strategy',
  nextButtonForNewStrategy: 'next-new-strategy-wizard',
  backButtonForNewStrategy: 'back-new-strategy-wizard',
  backButtonForEditStrategy: 'back-edit-strategy-wizard',
  nextButtonForEditStrategy: 'next-edit-strategy-wizard',
};

export const AB_INSIGHTS_ELEMENT_TAG = {
  submitBtn: 'ab-submit-btn',
};

export const TOAST_ELEMENT_TAG = {
  closeBtn: 'toast-close-btn',
  completeActionsBtn: 'toast-complete-actions-btn',
};

export const PENDO_HEADER_CLASS = 'single-flight-budget-header';
