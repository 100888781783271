// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timePicker > input {
  min-width: 255px;
  max-width: 255px;
  height: 35px;
  margin-right: 10px;
  border-radius: 0px;
}

.timePickerDisabled > input {
  min-width: 255px;
  max-width: 255px;
  height: 35px;
  margin-right: 10px;
  border-radius: 0px;
  background-color: #F0F0F0;
}`, "",{"version":3,"sources":["webpack://./src/containers/UserSettings/components/TimePicker.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".timePicker > input {\n  min-width: 255px;\n  max-width: 255px;\n  height: 35px;\n  margin-right: 10px;\n  border-radius: 0px;\n}\n\n.timePickerDisabled > input {\n  min-width: 255px;\n  max-width: 255px;\n  height: 35px;\n  margin-right: 10px;\n  border-radius: 0px;\n  background-color: #F0F0F0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
