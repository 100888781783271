import React from 'react';
import { WppButton } from 'buildingBlocks';
import { WppButtonClickHandler } from 'utils/types';
import userGroupPageStyles from 'containers/UserGroups/style';

const { setUserGroupsStyle } = userGroupPageStyles;

type Props = {
  isConfirmationPage: boolean
  edit: WppButtonClickHandler
  reset: WppButtonClickHandler
  loading: boolean
  isDisabled: boolean
  pristine: boolean
  handleUserGroupSubmit: WppButtonClickHandler
};

const FormButtons = ({ isConfirmationPage, edit, reset, loading, isDisabled, handleUserGroupSubmit, pristine }: Props) => (
  <div style={setUserGroupsStyle.setUserGroupsBtnContainer}>
    <WppButton
      variant="secondary"
      disabled={pristine}
      onClick={reset}
    >
      {!isConfirmationPage ? 'Clear Section' : 'Done'}
    </WppButton>
    {!isConfirmationPage ? (
      <WppButton
        loading={loading}
        disabled={isDisabled}
        onClick={handleUserGroupSubmit}
      >
        Submit
      </WppButton>
    ) : (
      <WppButton
        content="Edit"
        disabled={isDisabled}
        onClick={edit}
      >
        Edit
      </WppButton>
    )}
  </div>
);

export default FormButtons;
