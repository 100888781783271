import _ from 'lodash';
import {
  required, applyAllValidators, listLengthValidator, composeValidator,
} from 'utils/formValidators';
import { ACTIONS } from './constants';

const selectedFlightsValidation = (formValues) => {
  const actionType = _.get(formValues, 'actionType');
  const min = _.isEqual(actionType, ACTIONS.apply.value) ? 2 : 1;
  const max = 5;
  const lt = listLengthValidator(_.lte, min, `Select at least ${min} DSP objects.`);
  const gt = listLengthValidator(_.gte, max, 'You must select 5 or fewer DSP objects.');
  return composeValidator(gt, lt);
};

const validators = {
  member: required,
  advertiser: required,
};

const validate = (values) => applyAllValidators(values, { ...validators, selectedFlights: [selectedFlightsValidation(values)] });

export default validate;
