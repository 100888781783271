// @flow
import React from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import FormSectionHeader from 'components/FormSectionHeader';
import { Grid, Form, Radio } from 'buildingBlocks';
import { ACTIONS } from '../constants';

type ActionTypeProps = {
  selectedValue: string,
  change: Function,
  actionTypeOptions: Array<{ value: string, displayName: string }>,
};

const ActionType = ({
  selectedValue, actionTypeOptions, change,
}: ActionTypeProps) : Array<React.ReactNode> => _.map(actionTypeOptions, ({ value, displayName }) => (
  <Radio
    key={value}
    className={`action-type button ui ${selectedValue === value ? 'primary' : ''}`}
    style={{ fontSize: '12px' }}
    checked={selectedValue === value}
    label={displayName}
    value={value}
    onChange={(_e, data) => {
      change(data.value);
    }}
  />
));

const ActionTypeField = ({ selectedValue, actionTypeOptions, change } : ActionTypeProps) => (
  <Field
    id="actionType"
    name="actionType"
    component={ActionType}
    selectedValue={selectedValue}
    actionTypeOptions={actionTypeOptions}
    change={change}
  />
);

type ActionTabsProps = {
  isDisabled: boolean,
  changeAction: Function,
  selectedActionType: string,
};

const ActionTabs = ({ isDisabled, changeAction, selectedActionType } : ActionTabsProps) => (
  <Grid.Column width={6} style={{ zIndex: 1 }}>
    <Form.Field disabled={isDisabled}>
      <FormSectionHeader group="flightInput" title="Action" shouldStyleHeaderInline />
      <label style={{ marginBottom: '10px', marginTop: '16px' }}>Select a user group action</label>
      <div className="ui large fluid buttons">
        <ActionTypeField
          actionTypeOptions={_.values(ACTIONS)}
          selectedValue={selectedActionType}
          change={changeAction}
        />
      </div>
    </Form.Field>
  </Grid.Column>
);

export default ActionTabs;
