// @flow
import React from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import userGroupPageStyles from 'containers/UserGroups/style';
import { Form, WppRadio, WppTypography } from 'buildingBlocks';
import { ACTIONS } from '../constants';

const { setUserGroupsStyle } = userGroupPageStyles;

type ActionTypeProps = {
  selectedValue: string
  change: Function
  actionTypeOptions: Array<{ value: string, displayName: string }>
};

const ActionType = ({
  selectedValue, actionTypeOptions, change,
}: ActionTypeProps): Array<React.ReactNode> => {
  const handleActionTabRadio = (value) => {
    change(value);
  };

  return _.map(actionTypeOptions, ({ value, displayName }) => (
    <WppRadio
      key={value}
      className="set-users-group"
      value={value}
      style={setUserGroupsStyle.radionBtnStyle}
      labelConfig={{ text: displayName }}
      checked={selectedValue === value}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onWppChange={({ detail: { value } }) => {
        handleActionTabRadio(value);
      }}
    />
  ));
};

const ActionTypeField = ({ selectedValue, actionTypeOptions, change } : ActionTypeProps) => (
  <Field
    id="actionType"
    name="actionType"
    component={ActionType}
    selectedValue={selectedValue}
    actionTypeOptions={actionTypeOptions}
    change={change}
  />
);

type ActionTabsProps = {
  isDisabled: boolean
  changeAction: Function
  selectedActionType: string
};

const ActionTabs = ({ isDisabled, changeAction, selectedActionType } : ActionTabsProps) => (
  <Form.Field disabled={isDisabled}>
    <WppTypography type="s-strong">Action</WppTypography>
    <ActionTypeField
      actionTypeOptions={_.values(ACTIONS)}
      selectedValue={selectedActionType}
      change={changeAction}
    />
  </Form.Field>
);

export default ActionTabs;
