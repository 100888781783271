import React from 'react';
import { WppIconTrash, WppTypography } from 'buildingBlocks';
import userGroupPageStyles from 'containers/UserGroups/style';
import FlightItemValidationColumn from './FlightItemValidationColumn';

const { setUserGroupsStyle } = userGroupPageStyles;

type FlightItemColFlightBoxProps = {
  formatApnObj: string
};

export const FlightItemColFlightBox = ({
  formatApnObj,
}: FlightItemColFlightBoxProps) => (
  <WppTypography>{formatApnObj}</WppTypography>
);

type FlightItemColExistingProps = {
  low: string
  high: string
  shouldDisplay: boolean
};

export const FlightItemColExisting = ({ low, high, shouldDisplay } : FlightItemColExistingProps) => (
  <>
    {shouldDisplay && (
      <span style={{ color: 'grey' }}>
        {low}-
        {high}
      </span>
    )}
  </>
);

type FlightItemColSplitProps = {
  pct: number
  shouldDisplay: boolean
};

export const FlightItemColSplit = ({ shouldDisplay, pct } : FlightItemColSplitProps) => (
  <>
    {shouldDisplay && <WppTypography type="s-body">{pct}%</WppTypography>}
  </>
);

type FlightItemColNewProps = {
  newGroupRangeVal: string
};

export const FlightItemColNew = ({ newGroupRangeVal } : FlightItemColNewProps) => (
  <div style={setUserGroupsStyle.flightItemNewCol}>
    <WppTypography style={setUserGroupsStyle.flightItemNewColText} type="s-body">{newGroupRangeVal}</WppTypography>
  </div>
);

type FlightItemColSuccessOrFailProps = {
  hasError: boolean
  flightErrorMsg: string
};

export const FlightItemColSuccessOrFail = ({ hasError, flightErrorMsg } : FlightItemColSuccessOrFailProps) => (
  <FlightItemValidationColumn
    hasError={hasError}
    flightErrorMsg={flightErrorMsg}
  />
);

type FlightItemDismissProps = {
  onDismiss: () => void
};

export const FlightItemDismiss = ({ onDismiss } : FlightItemDismissProps) => (
  <WppIconTrash style={setUserGroupsStyle.flightItemDeleteIconStyle} onClick={onDismiss} />
);
