import _ from 'lodash';
import React, { ReactElement } from 'react';
import { WppEmptyError } from '@wppopen/components-library-react';
import DocumentTitle from 'react-document-title';
import { Container, WppTypography } from 'buildingBlocks';
import { unknownErrorHeader, unknownContainer, unknownErrorMsgStyle } from 'containers/403/styles';
import { COPILOT_LAYOUT } from 'globalStyles';

type Props = {
  message?: string | ReactElement
  customFooter?: ReactElement
};

const UnknownErrorPage = ({ message, customFooter }: Props) => (
  <>
    <DocumentTitle title="Access Denied" />
    <Container
      fluid
      textAlign="center"
    >
      <div style={unknownContainer}>
        <WppEmptyError />
        <div style={{ paddingTop: COPILOT_LAYOUT.SPACING[24] }}>
          <WppTypography type="m-strong" style={unknownErrorHeader}>
            Unable to Load Strategy
          </WppTypography>
          <WppTypography type="s-body" style={unknownErrorMsgStyle}>
            {message}
          </WppTypography>
        </div>
        {!_.isNil(customFooter) && customFooter}
      </div>
    </Container>
  </>
);

export default UnknownErrorPage;
