import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Divider, Form, Grid, Input } from 'buildingBlocks';
import { ViewUser } from 'utils/copilotAPI';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { EmailValidationMessage } from './StatusMessages';
import { Mode } from '../types';

// eslint-disable-next-line no-useless-escape
const validEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const NameField = validatedFieldWrapper(Input);

type Props = {
  userId: string | number
  disabled: boolean
  mode: number
  emailValidated: boolean
  setEmailValidated: (x: any) => void
};
const UserInfo = ({ userId, disabled, mode, emailValidated, setEmailValidated }: Props) => {
  const [validatingEmail, setValidatingEmail] = useState<boolean>(false);
  const email = useWatch({ name: 'email' });
  const [emailError, setEmailError] = useState<any>(email ? false : { content: 'Required' });
  const { control } = useFormContext();

  const getUserEmailError = () => {
    if (!_.isEqual(mode, Mode.create)) {
      return false;
    }
    return emailError;
  };

  // eslint-disable-next-line consistent-return
  const validateEmail = async () => {
    if (!email) {
      setEmailError({ content: 'Required' });
    }
    if (email && !validatingEmail) {
      await setValidatingEmail(true);
      const emailIsValid = validEmailFormat.test(email);
      const dbCheck = await ViewUser.get({ email, limit: 1 });
      if (!_.isEmpty(dbCheck.data)) {
        setEmailError({ content: 'This email address is already in use' });
        setEmailValidated(false);
      } else if (!emailIsValid) {
        setEmailError({ content: 'Please enter a valid email format' });
        setEmailValidated(false);
      } else {
        setEmailError(false);
        setEmailValidated(true);
      }
      setValidatingEmail(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateWrapper = useCallback(_.debounce(validateEmail, 1000), [validateEmail]);

  useEffect(() => {
    validateWrapper();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <Grid.Column width={12}>
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <span style={{ fontSize: '12px', marginBottom: '4px' }}><label htmlFor="email" className="field-label">First Name</label></span>
            <Controller
              name="firstName"
              rules={{ required: { value: true, message: 'Required' } }}
              control={control}
              render={({ field, fieldState }) => (
                <NameField
                  type="text"
                  placeholder="First Name"
                  onChange={field.onChange}
                  field={field}
                  fieldState={fieldState}
                  disabled={disabled}
                  skipIsDirtyCheck
                  fluid
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <span style={{ fontSize: '12px', marginBottom: '4px' }}><label htmlFor="email" className="field-label">Last Name</label></span>
            <Controller
              name="lastName"
              rules={{ required: { value: true, message: 'Required' } }}
              control={control}
              render={({ field, fieldState }) => (
                <NameField
                  type="text"
                  placeholder="Last Name"
                  onChange={field.onChange}
                  field={field}
                  fieldState={fieldState}
                  disabled={disabled}
                  skipIsDirtyCheck
                  fluid
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <span style={{ fontSize: '12px', marginBottom: '4px' }}><label htmlFor="email" className="field-label">Email</label></span>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Form.Input
                  name="email"
                  value={field.value}
                  onChange={(_e, data) => {
                    field.onChange(data.value);
                  }}
                  placeholder={'User\'s Email Address'}
                  error={getUserEmailError()}
                  disabled={!(_.isNaN(userId))}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <div style={{ marginTop: '30px', marginLeft: '-50px' }}>
              <EmailValidationMessage
                email={email}
                validatingEmail={validatingEmail}
                emailValidated={emailValidated}
                mode={mode}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Divider />
      </Grid>
    </Grid.Column>
  );
};

export default UserInfo;
