import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import _ from 'lodash';
import { Form, WppGrid, WppDivider, WppInlineMessage } from 'buildingBlocks';
import { FetchFlightsAction } from 'components/DoubleFlightSelect/DoubleFlightSelect';
import { Member, Advertiser, Flight, LoadingStates, User } from 'utils/types';
import { getStrategyMembers, Permission } from 'utils/featureFlags';
import { FLIGHT_EXTERNAL_TYPE, DSP } from 'constantsBase';
import MemberAndAdvertiser from 'components/RFMemberAndAdvertiser';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import WppPageTemplate from 'components/PageTemplate/WppPageTemplate';
import userGroupPageStyles from 'containers/UserGroups/style';
import MiddleForm from './components/MiddleForm';
import FormButtons from './components/FormButtons';
import { setFlightExternalTypeString } from './utils';
import {
  fetchMembers, fetchAdvertisers, fetchFlights, resetForm, setUserGroupForm,
  checkUserGroupForm, editForm, removeUserGroupForm,
} from './actions';
import { FORM_NAME, PAGE_TITLE, LOADING_STATES, ACTIONS } from './constants';
import validate from './validate';

const { setUserGroupsStyle } = userGroupPageStyles;

type Props = {
  change: Function
  fetchMembers: Function
  fetchAdvertisers: Function
  fetchFlights: FetchFlightsAction
  user: User
  members: Array<Member>
  advertisers: Array<Advertiser>
  selectedMember: Member
  selectedAdvertiser: Advertiser
  advertiserLoadingState: LoadingStates
  selectedFlightType: number
  selectedActionType: string
  flights: Array<Flight>
  invalid: boolean
  reset: Function
  loading: boolean
  pristine: boolean
  advertiserFetchError: string
  memberFetchError: string
  resetForm: Function
  editForm: Function
  selectedFlights: Array<Flight>
  checkUserGroupForm: Function
  setUserGroupForm: Function
  removeUserGroupForm: Function
  checkedUserGroupData: Array<unknown>
  userGroupData: Array<unknown>
  errorMsg: string | null
};

const UserGroupsTool: React.FC<Props> = (props) => {
  const {
    change,
    user,
    members,
    advertisers,
    selectedMember,
    selectedAdvertiser,
    advertiserLoadingState,
    selectedFlightType,
    selectedActionType,
    flights,
    invalid,
    pristine,
    advertiserFetchError,
    memberFetchError,
    selectedFlights,
    checkedUserGroupData,
    loading,
    userGroupData,
    errorMsg,
    reset,
    fetchMembers: fetchMembersAction,
    fetchAdvertisers: fetchAdvertisersAction,
    fetchFlights: fetchFlightsAction,
    resetForm: resetUserGroupFormAction,
    setUserGroupForm: setUserGroupFormAction,
    checkUserGroupForm: checkUserGroupFormAction,
    removeUserGroupForm: removeUserGroupFormAction,
    editForm: editUserGroupForm,
  } = props;

  const [localCheckedUserGroupData, setLocalCheckedUserGroupData] = useState<Array<unknown>>(checkedUserGroupData);

  useEffect(() => {
    const getMembers = getStrategyMembers(user);
    fetchMembersAction(getMembers, [DSP.APN.id]);
  });

  const onSeatSelect = (member: Member) => {
    change('advertiser', '');
    fetchAdvertisersAction(member.id);
  };

  const handleUserGroupCheck = (externalId: string, flightType: number, selectedFlightsArray: Array<Flight>) => {
    const fet = FLIGHT_EXTERNAL_TYPE.getById(flightType);
    checkUserGroupFormAction(
      externalId,
      _.map(selectedFlightsArray, 'externalId'),
      setFlightExternalTypeString(fet.displayName),
    );
  };

  useEffect(() => {
    if (_.size(members) === 1 && !selectedMember) {
      change('member', members[0]);
      onSeatSelect(_.first(members)!);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, selectedMember, change]);

  useEffect(() => {
    if (_.size(selectedFlights) >= 2 && _.size(selectedFlights) <= 5) {
      handleUserGroupCheck(selectedMember.externalId, selectedFlightType, selectedFlights);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlights]);

  // Updates local state when the checked user group data prop changes.
  useEffect(() => {
    setLocalCheckedUserGroupData(checkedUserGroupData);
  }, [checkedUserGroupData]);

  const handleUserGroupSubmit = (externalId: string, selectedFlightTypeNumber: number, selectedFlightsArray: Array<unknown>) => {
    const fet = FLIGHT_EXTERNAL_TYPE.getById(selectedFlightTypeNumber);
    const args = [
      externalId,
      _.map(selectedFlightsArray, 'externalId'),
      setFlightExternalTypeString(fet.displayName),
    ];
    if (selectedActionType === ACTIONS.apply.value) {
      setUserGroupFormAction(...args);
    } else {
      removeUserGroupFormAction(...args);
    }
  };

  const handleDismiss = (toDismiss: { externalId: string }) => {
    const filteredFlights = _.filter(selectedFlights, (item) => item.externalId !== toDismiss.externalId);
    change('selectedFlights', filteredFlights);
  };

  const resetFormHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    reset();
    resetUserGroupFormAction();
  };

  const edit = () => {
    editUserGroupForm();
    handleUserGroupCheck(selectedMember.externalId, selectedFlightType, selectedFlights);
  };

  const onSearchChange = (searchTerm: string) => {
    fetchAdvertisersAction(selectedMember.id, searchTerm);
  };

  const clearFlightFields = () => {
    change('selectedFlights', []);
  };

  const onAdvSelect = (advertiser: Advertiser) => {
    clearFlightFields();
    fetchFlightsAction(selectedMember.id, advertiser.id, selectedFlightType, undefined, false);
  };

  const isMemberAdvertiserEmpty = () => !selectedMember || !selectedAdvertiser;

  const isConfirmationPage = _.isEqual(loading, LOADING_STATES.success);

  return (
    <>
      <WppPermissionPageTemplate title={PAGE_TITLE} name={PAGE_TITLE} permissions={Permission.userGroups}>
        <Form>
          <WppGrid container fullWidth>
            <WppGrid item all={24}>
              <Form.Field disabled={isConfirmationPage}>
                <MemberAndAdvertiser
                  members={members}
                  advertisers={advertisers}
                  prettyMembers={getOptionsWithDspIcon(members)}
                  selectedMember={selectedMember}
                  selectedAdvertiser={selectedAdvertiser}
                  memberFetchError={memberFetchError}
                  advertiserFetchError={advertiserFetchError}
                  loadingAdvertisers={_.isEqual(advertiserLoadingState, LOADING_STATES.loading)}
                  onSeatSelect={(_event, selected: Member) => onSeatSelect(selected)}
                  onSearchChange={(_event, value) => onSearchChange(value.searchQuery)}
                  onAdvSelect={(adv: Advertiser) => onAdvSelect(adv)}
                  onAdvClick={() => fetchAdvertisersAction(selectedMember.id)}
                />
              </Form.Field>
            </WppGrid>
            <WppGrid item all={24}>
              {!isMemberAdvertiserEmpty() && <WppDivider style={setUserGroupsStyle.dividerStyle} />}
            </WppGrid>
            {!isMemberAdvertiserEmpty() && (
              <MiddleForm
                selectedFlightType={selectedFlightType}
                selectedActionType={selectedActionType}
                selectedMember={selectedMember}
                selectedAdvertiser={selectedAdvertiser}
                flights={flights}
                fetchFlights={fetchFlightsAction}
                clearFlightFields={clearFlightFields}
                handleDismiss={handleDismiss}
                selectedFlights={selectedFlights}
                checkedUserGroupData={localCheckedUserGroupData}
                userGroupData={userGroupData}
                isDisabled={isConfirmationPage}
                changeAction={(action) => change('actionType', action)}
              />
            )}
            {!_.isNil(errorMsg) && (
              <WppInlineMessage
                size="m"
                hideCloseBtn={false}
                message={errorMsg}
                type="error"
              />
            )}
          </WppGrid>
        </Form>
      </WppPermissionPageTemplate>
      <WppPageTemplate withoutLayout>
        <FormButtons
          isConfirmationPage={isConfirmationPage}
          edit={edit}
          reset={resetFormHandler}
          loading={_.isEqual(loading, LOADING_STATES.loading)}
          isDisabled={invalid}
          pristine={pristine}
          handleUserGroupSubmit={() => {
            handleUserGroupSubmit(selectedMember.externalId, selectedFlightType, selectedFlights);
          }}
        />
      </WppPageTemplate>
    </>
  );
};

const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
  user: state.login.session.data.user,
  members: state.userGroups.members,
  advertisers: state.userGroups.advertisers,
  selectedMember: formSelector(state, 'member'),
  selectedAdvertiser: formSelector(state, 'advertiser'),
  memberFetchError: state.userGroups.memberFetchError,
  advertiserFetchError: state.userGroups.advertiserFetchError,
  flights: state.userGroups.flights,
  advertiserLoadingState: state.userGroups.advertiserLoadingState,
  selectedFlightType: formSelector(state, 'flightType'),
  selectedFlights: formSelector(state, 'selectedFlights'),
  selectedActionType: formSelector(state, 'actionType'),
  loading: state.userGroups.loading,
  checkedUserGroupData: state.userGroups.checkedUserGroupData,
  userGroupData: state.userGroups.userGroupData,
  errorMsg: state.userGroups.errorMsg,
});

export default connect(mapStateToProps, {
  fetchMembers,
  fetchAdvertisers,
  fetchFlights,
  resetForm,
  setUserGroupForm,
  checkUserGroupForm,
  removeUserGroupForm,
  editForm,
})(
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      flightType: FLIGHT_EXTERNAL_TYPE.apnLineItem.id,
      actionType: ACTIONS.apply.value,
    },
    validate,
    // @ts-ignore - redux
  })(UserGroupsTool),
);
