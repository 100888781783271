import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { Grid, Form } from 'buildingBlocks';
import { Member, Advertiser, Flight } from 'utils/types';
import FormSectionHeader from 'components/FormSectionHeader';
import { FetchFlightsAction } from 'components/DoubleFlightSelect/DoubleFlightSelect';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import FlightTypeField from 'components/FlightTypeField';
import { newGroupRange } from '../utils';
import FlightSelectWithSplits from './FlightSelectorWithSplits';
import FlightItem from './FlightItem';
import ActionTabs from './ActionTabs';

const { apnCampaign, apnLineItem } = FLIGHT_EXTERNAL_TYPE;

type Props = {
  fetchFlights: FetchFlightsAction,
  selectedMember: Member,
  selectedAdvertiser: Advertiser,
  selectedFlightType: number,
  selectedActionType: string,
  flights: Array<Flight>,
  clearFlightFields: () => void,
  handleDismiss: Function,
  selectedFlights: Array<unknown>,
  checkedUserGroupData: Array<unknown>,
  userGroupData: Array<unknown>,
  isDisabled: boolean,
  changeAction: Function,
};

const MiddleForm = ({
  selectedFlightType, selectedMember, selectedAdvertiser, flights, fetchFlights, clearFlightFields,
  handleDismiss, selectedFlights, checkedUserGroupData, userGroupData, isDisabled,
  changeAction, selectedActionType,
}: Props) => {
  const hasErrors = _.find(userGroupData, (flight) => _.has(flight, 'response.error')) || _.isEmpty(userGroupData);
  return (
    <Grid.Row>
      <Grid.Column width={12} style={{ padding: '0 1.5rem' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Form.Field disabled={isDisabled}>
                <FormSectionHeader group="flightInput" title="DSP Objects" shouldStyleHeaderInline />
                <FlightTypeField
                  selectedValue={selectedFlightType}
                  flightTypeOptions={[apnLineItem, apnCampaign]}
                  afterChange={(flightType: number) => {
                    clearFlightFields();
                    fetchFlights(selectedMember.id, selectedAdvertiser.id, flightType, '', false);
                  }}
                />
              </Form.Field>
            </Grid.Column>
            <ActionTabs
              selectedActionType={selectedActionType}
              isDisabled={isDisabled}
              changeAction={changeAction}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={12} style={{ padding: '1.5rem', paddingTop: 0, position: 'relative', top: '-60px' }}>
        <Field
          // @ts-ignore - redux form
          component={FlightSelectWithSplits}
          // @ts-ignore - redux form
          isDisabled={isDisabled}
          name="selectedFlights"
          text="Search DSP Object(s)..."
          keyFn={(fc) => `[${fc.externalId}] - ${fc.name}`}
          options={flights}
          selected={selectedFlights}
          selectedActionType={selectedActionType}
          hasErrors={hasErrors}
          onChange={(_event, currentlySelectedFlights) => {
            const selectedIds = _.map(currentlySelectedFlights, 'id');
            fetchFlights(selectedMember.id, selectedAdvertiser.id, selectedFlightType, '', false, selectedIds);
          }}
          search={{
            searchType: 'api',
            onSearchChange: (_event, value) => {
              fetchFlights(selectedMember.id, selectedAdvertiser.id, selectedFlightType, value.searchQuery, false);
            },
            debounce: { timer: 500 },
          }}
          renderSelectionList={
            (selected) => _.map(selected, (v: { externalId: string, name: string }, index: number) => (
              <FlightItem
                key={v.externalId}
                v={v}
                onDismiss={() => handleDismiss(v)}
                pct={_.round(100 / selectedFlights.length)}
                newGroupRange={newGroupRange(selectedFlights.length)}
                index={index}
                selectedActionType={selectedActionType}
                checkedUserGroupData={checkedUserGroupData}
                userGroupData={userGroupData}
                disableDismiss={isDisabled}
              />
            ))
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default MiddleForm;
