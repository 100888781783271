import React, { useRef, useEffect, ReactChild } from 'react';

/*
Hook that detects clicks outside of the passed ref & calls passed-in callback
*/
const useOutsideDetector = (ref, callback) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

type Props = {
  callback: Function
  children: ReactChild
};

const DetectClickOutsideWrapper = (props: Props) => {
  const wrapperRef = useRef(null);
  useOutsideDetector(wrapperRef, props.callback);
  return <div ref={wrapperRef}>{props.children}</div>;
};

export default DetectClickOutsideWrapper;
