/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Container, Divider, Form, Grid, Segment } from 'buildingBlocks';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import PageForbidden from 'containers/403';
import { Member, Role, User } from 'utils/types';
import { USER_SUCCESS_MSG, REQUEST_NOTES_DEFAULT_TEXT } from '../constants';
import {
  saveUser,
  requestAccess,
  userValidation,
  validatingUser,
} from '../actions';
import MemberRoleComponent from './MemberRoleComponent';
import UserInfo from './UserInfo';
import Footer from './Footer';
import Approver from './Approver';
import { Mode, UserForm, UserProps, ApproverValidationMode } from '../types';
import { isAdmin, validateApproverEmail } from '../utils';
import PageLoader from './PageLoader';

const viewMode = (mode: Mode) => mode === Mode.view;

export const UserPage = (props: UserProps) => {
  const {
    userData, userId, isLoggedInUser,
    mode, onUserProfile, isNewUser, navigate,
  } = props;

  const dispatch = useDispatch();
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;
  const userToViewOrEdit = useSelector<GlobalState>((rootState) => rootState.user) as User;
  const { statusMsg, memberRolesToSave, memberRolesToDelete, validatingApprover,
    saving, approverValidationMode, showEmailStatus, approver: originalApprover, sendEmailErrorMsg } = userToViewOrEdit;

  const initialFormValues = {
    firstName: isNewUser ? user.firstName : userToViewOrEdit.firstName,
    lastName: isNewUser ? user.lastName : userToViewOrEdit.lastName,
    email: isNewUser ? user.email : userToViewOrEdit.email,
    approver: isNewUser ? user.approver : userToViewOrEdit.approver,
    requestNotes: userToViewOrEdit.requestNotes ?? REQUEST_NOTES_DEFAULT_TEXT,
    selectedMembers: [],
    selectedRoles: [],
    selectedRegion: '',
    approverValidationMode: ApproverValidationMode.invalid,
  };

  const formMethods = useForm<UserForm>({ defaultValues: initialFormValues, mode: 'onChange' });
  const { setValue, reset, control } = formMethods;

  const firstName = useWatch<UserForm>({ name: 'firstName', control }) as string;
  const lastName = useWatch<UserForm>({ name: 'lastName', control }) as string;
  const email = useWatch<UserForm>({ name: 'email', control }) as string;
  const approver = useWatch<UserForm>({ name: 'approver', control }) as string;
  const requestNotes = useWatch<UserForm>({ name: 'requestNotes', control }) as string;
  const selectedMembers = useWatch<UserForm>({ name: 'selectedMembers', control }) as Array<Member>;
  const selectedRoles = useWatch<UserForm>({ name: 'selectedRoles', control }) as Array<Role>;

  useEffect(() => {
    reset({
      firstName,
      lastName,
      email,
      approver,
      requestNotes,
      selectedMembers: initialFormValues.selectedMembers,
      selectedRoles: initialFormValues.selectedRoles,
      selectedRegion: initialFormValues.selectedRegion,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToViewOrEdit]);

  const [emailValidated, setEmailValidated] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  // ra=1 is set in email-template in copilot-ui
  const [shouldDisableUserInfoFields, setShouldDisableUserInfoFields] = useState<boolean>(viewMode(mode));

  const validateApprover = async () => {
    if (approver && !validatingApprover) {
      dispatch(validatingUser());
      const userIsAdmin = isAdmin(user);
      const isValid = await validateApproverEmail(approver, userIsAdmin, email);
      dispatch(userValidation(isValid));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateWrapper = useCallback(_.debounce(validateApprover, 1000), [validateApprover]);

  useEffect(() => {
    if (statusMsg === USER_SUCCESS_MSG) {
      navigate(onUserProfile ? '/' : '/users');
    }
  }, [statusMsg, onUserProfile, navigate]);

  useEffect(() => {
    setShouldDisableUserInfoFields(viewMode(mode));
  }, [mode, setShouldDisableUserInfoFields]);

  useEffect(() => {
    validateWrapper();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approver]);

  const isAdminOrUsersApprover = (user: User, approver: string) => {
    const userIsAdmin = isAdmin(user);
    return (userIsAdmin || (_.toLower(user.email) === _.toLower(approver)));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUser(
      userId,
      firstName,
      lastName,
      email,
      memberRolesToSave,
      memberRolesToDelete,
      approver,
      requestNotes,
      isNewUser,
      dispatch,
    );
  };

  const onRequestNotesChange = (value: string) => {
    setValue('requestNotes', value);
  };

  const onApproverChange = (value: string) => {
    setValue('approver', value);
  };

  const handleRequestAccess = (e) => {
    e.preventDefault();
    requestAccess(userData, approver, requestNotes, dispatch);
  };

  const qAReadOnly = isQAGlobalReadOnly(user);
  const isAdminOrUsersApproverBool = isAdminOrUsersApprover(user, originalApprover);
  const userIsAdmin = isAdmin(user);

  const showRequestSection = isLoggedInUser && shouldDisableUserInfoFields;
  const canViewUser = isAdminOrUsersApproverBool || isLoggedInUser || qAReadOnly;
  const disableSave = saving || !firstName || !lastName || !approver || qAReadOnly || viewMode(mode) || validatingApprover || approverValidationMode === ApproverValidationMode.invalid || (mode === Mode.create && !emailValidated);

  return (
    <Container fluid>
      <Segment clearing>
        {canViewUser
          ? (
            <div style={{ position: 'relative' }}>
              {showLoader && <PageLoader segmentStyle={{ border: 'transparent', boxShadow: 'none' }} />}
              { !showLoader && (
                <FormProvider {...formMethods}>
                  <Form
                    noValidate
                    onSubmit={(e) => {
                      setShowLoader(true);
                      handleSubmit(e);
                    }}
                  >
                    <Grid>
                      <UserInfo
                        userId={userId}
                        disabled={shouldDisableUserInfoFields || (!isLoggedInUser && !userIsAdmin)}
                        mode={mode}
                        emailValidated={emailValidated}
                        setEmailValidated={setEmailValidated}
                      />
                      <Approver
                        showRequestNotes={showRequestSection || isNewUser}
                        showRequestAccessButton={showRequestSection}
                        onChangeRequestNotes={(_e, data) => onRequestNotesChange(data.value)}
                        handleAccessRequestSubmit={handleRequestAccess}
                        approver={approver}
                        onChangeApprover={onApproverChange}
                        showEmailStatus={showEmailStatus}
                        validatingApprover={validatingApprover}
                        approverValidationMode={approverValidationMode}
                        requestNotes={requestNotes}
                        disabled={shouldDisableUserInfoFields}
                        userToViewOrEdit={userToViewOrEdit}
                        mode={mode}
                        sendEmailErrorMsg={sendEmailErrorMsg}
                      />
                      <Divider />
                      <MemberRoleComponent
                        userCanEditRoles={isAdminOrUsersApproverBool}
                        disabled={shouldDisableUserInfoFields}
                        selectedMembers={selectedMembers}
                        selectedRoles={selectedRoles}
                        isNewUser={isNewUser}
                      />
                      <Footer
                        statusMsg={statusMsg}
                        disableSave={disableSave}
                        onUserProfile={onUserProfile}
                        isNewUser={isNewUser}
                      />
                    </Grid>
                  </Form>
                </FormProvider>
              )}
            </div>
          )
          : (<PageForbidden button={{ url: '/users', content: 'Go back to users listing page' }} />)}
      </Segment>
    </Container>
  );
};

export default UserPage;
