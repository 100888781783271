import React from 'react';
import _ from 'lodash';
import { WppTable, WppTableHeader, WppTableHeaderRow, WppTableHeaderCell, WppTypography } from 'buildingBlocks';
import userGroupPageStyles from 'containers/UserGroups/style';
import { ACTIONS } from '../constants';

const { setUserGroupsStyle } = userGroupPageStyles;

type Props = {
  selectedActionType: string
  hasErrors: boolean
};

const RowHeader = ({ selectedActionType, hasErrors }: Props) => (
  <WppTable>
    <WppTableHeader>
      <WppTableHeaderRow>
        <WppTableHeaderCell style={{ width: '50%' }}>
          <WppTypography type="s-strong" tag="p">DSP Object</WppTypography>
        </WppTableHeaderCell>
        <WppTableHeaderCell style={setUserGroupsStyle.tableStyle}>
          {hasErrors && <WppTypography type="s-strong" tag="p">Existing</WppTypography>}
        </WppTableHeaderCell>
        {_.isEqual(selectedActionType, ACTIONS.apply.value) && (
          <WppTableHeaderCell style={setUserGroupsStyle.tableStyle}>
            {hasErrors && <WppTypography type="s-strong" tag="p">Split</WppTypography>}
          </WppTableHeaderCell>
        )}
        <WppTableHeaderCell style={setUserGroupsStyle.tableStyle}>
          <WppTypography type="s-strong" tag="p">New</WppTypography>
        </WppTableHeaderCell>
      </WppTableHeaderRow>
    </WppTableHeader>
  </WppTable>
);

export default RowHeader;
