import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WppButton, WppIconClose, WppModal, WppActionButton, WppTypography } from 'buildingBlocks';
import WHITELIST_IP_STYLES from './style';

const { modalMsgContainer, modalSectionContainer } = WHITELIST_IP_STYLES;

type WhitelistIPModalProps = {
  modalMessage: string
  setModalMessage: (x: any) => void
  shouldRedirect: boolean
};

const WhitelistIpModal = (props: WhitelistIPModalProps) => {
  const { modalMessage, setModalMessage, shouldRedirect } = props;

  const navigate = useNavigate();

  const handleClose = () => {
    setModalMessage('');
    if (shouldRedirect) {
      navigate('/');
    }
  };

  return (
    <WppModal
      open={!!modalMessage}
      size="m"
    >
      <div slot="header" style={modalSectionContainer}>
        <WppActionButton onClick={handleClose}>
          <WppIconClose />
        </WppActionButton>
      </div>
      <div slot="body">
        <div style={{ ...modalMsgContainer, ...(shouldRedirect && { padding: '32px 24px 36px' }) }}>
          <WppTypography type="s-body">{modalMessage}</WppTypography>
        </div>
      </div>
      <div slot="actions" style={modalSectionContainer}>
        {shouldRedirect && (
          <WppButton size="s" onClick={handleClose}>
            Close
          </WppButton>
        )}
      </div>
    </WppModal>
  );
};

export default WhitelistIpModal;
