import _ from 'lodash';
import React from 'react';
import { Grid, Table, Icon, Label } from 'buildingBlocks';
import OptionWithIcon from 'components/OptionWithIcon';
import DspIcon from 'components/DspIcon';
import { roleType } from 'constantsBase';
import { memberRoleColumns } from '../constants';
import { getMemberRoleForDisplay, orderAndGroupRolesForDisplay } from '../utils';

type Props = {
  roles: Array<{}>
  onClickRemove: Function
  userCanEditRoles: boolean
};

const getMember = (roles) => {
  const [{ role: { type } }] = roles;
  return type === roleType.global ? 'All Members Above' : '';
};

const displayMemberRole = (key, memberRole, userCanEditRoles, onClickRemove) => {
  const { member, roles } = memberRole;
  return (
    <Table.Row key={`${key}`}>
      <Table.Cell width={5}>
        <OptionWithIcon text={member.displayName || getMember(roles)} icon={member.dsp ? <DspIcon dspId={member.dsp} /> : null} />
      </Table.Cell>
      <Table.Cell width={3}>{_.get(member, 'externalId')}</Table.Cell>
      <Table.Cell width={5}>
        {_.map(roles, (r) => <Label key={`${r.member ? r.member.id : 'admin'} - ${r.role.id}`}> {r.role.name} {userCanEditRoles ? <Icon name="delete" onClick={() => onClickRemove(r)} /> : null} </Label>)}
      </Table.Cell>
    </Table.Row>
  );
};

const MemberRoleTable = ({ roles, onClickRemove, userCanEditRoles }: Props) => {
  const groupedRoles = orderAndGroupRolesForDisplay(roles);

  return (
    <Grid.Row>
      <Grid.Column>
        <Table>
          <Table.Header>
            <Table.Row>
              {_.map(memberRoleColumns, (col) => <Table.HeaderCell key={col.value}>{col.text}</Table.HeaderCell>)}
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(groupedRoles, (memberRole, i) => {
              const newMemberRole = getMemberRoleForDisplay(memberRole);
              return displayMemberRole(i, newMemberRole, userCanEditRoles, onClickRemove);
            })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
  );
};

export default MemberRoleTable;
