/* eslint-disable no-restricted-imports */
import _ from 'lodash';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { configuringCampaignStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { User } from 'utils/types';
import { membersForFeature, Permission, MEMBER } from 'utils/featureFlags';
import { Member } from 'utils/copilotAPI';
import useFetcher, { PossibleStates } from 'utils/hooks/useFetcher';

export const useMemberFetcher = (
  user: User,
  permissionsBlackList: Array<string> = [],
  selectedOptType?: OptimizationType,
) => {
  const fetchMembers = async () => {
    const validMembers = membersForFeature(user, Permission.accessStrategyWizard);
    if (_.isEmpty(validMembers)) {
      return { kind: PossibleStates.permissionDenied };
    }
    const hasAdminAccess = validMembers === MEMBER.ALL;
    const where = hasAdminAccess
      ? { enabled: 1 }
      : { id: validMembers, enabled: 1 };
    const configuringCampaignStrat = configuringCampaignStratCheck(selectedOptType);
    const permissionsbl = (!hasAdminAccess && configuringCampaignStrat)
      ? [...permissionsBlackList, Permission.stratAMZNBudgetOptimization]
      : permissionsBlackList;
    const members = await Member.membersByPermissions({ where, permissionsbl });
    return { kind: PossibleStates.hasData, data: members.data };
  };

  const currentState = useFetcher(fetchMembers, []);
  const error = currentState.kind === PossibleStates.error && 'Failed to fetch members';
  // @ts-ignore TODO
  return [currentState.data, error];
};
