import { COPILOT_COLORS } from 'globalStyles';

export const noBorderRadius = {
  borderRadius: '0',
  borderStyle: 'none',
};

export const inputField = {
  width: '100%',
  borderRadius: '0',
};

export const textLabel = {
  color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.METTLES.M700_OSLO,
  fontWeight: 'normal',
};

export const userButton = {
  float: 'right',
  background: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
  color: 'white',
};

export const addButton = {
  float: 'right',
  background: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
  color: 'white',
  marginTop: '27px',
};

export const pageLoader = {
  container: {
    marginTop: '80px',
    width: '65%',
  },
  divWrapper: {
    position: 'relative' as 'relative',
    height: '400px',
  },
  fullHeightAndWidth: {
    height: '100%',
    width: '100%',
  },
};
