import dev from './dev';
import local from './local';
import release from './release';
import prod from './prod';
import demo from './demo';

// Define env global constants based on environment
const env = (() => {
  // @ts-ignore global var
  switch (__ENV__) {
    case 'production':
    case 'prod':
      return prod;
    case 'release':
      return release;
    case 'demo':
      return { ...dev, ...demo };
    case 'dev':
      return dev;
    default:
      return { ...dev, ...local };
  }
})();

export default env;
