/*
 *
 * UserGroup constants
 *
 */
import { Flight, Advertiser, $Values } from 'utils/types';

const PREFIX = 'USER_GROUPS';

export const FETCH_MEMBERS = `${PREFIX}:FETCH_MEMBERS`;
export const FETCH_MEMBERS_SUCCEEDED = `${PREFIX}:FETCH_MEMBERS_SUCCEEDED`;
export const FETCH_MEMBERS_FAILED = `${PREFIX}:FETCH_MEMBERS_FAILED`;
export const FETCH_ADVERTISERS = `${PREFIX}:FETCH_ADVERTISERS`;
export const FETCH_ADVERTISERS_SUCCEEDED = `${PREFIX}:FETCH_ADVERTISERS_SUCCEEDED`;
export const FETCH_ADVERTISERS_FAILED = `${PREFIX}:FETCH_ADVERTISERS_FAILED`;
export const FETCH_FLIGHTS = `${PREFIX}:FETCH_FLIGHTS`;
export const FETCH_FLIGHTS_SUCCEEDED = `${PREFIX}:FETCH_FLIGHTS_SUCCEEDED`;
export const FETCH_FLIGHTS_FAILED = `${PREFIX}:FETCH_FLIGHTS_FAILED`;
export const CHECK_USER_GROUPS = `${PREFIX}:CHECK_USER_GROUPS`;
export const CHECK_USER_GROUPS_SUCCEEDED = `${PREFIX}:CHECK_USER_GROUPS_SUCCEEDED`;
export const CHECK_USER_GROUPS_FAILED = `${PREFIX}:CHECK_USER_GROUPS_FAILED`;
export const SET_USER_GROUPS = `${PREFIX}:SET_USER_GROUPS`;
export const SET_USER_GROUPS_SUCCEEDED = `${PREFIX}:SET_USER_GROUPS_SUCCEEDED`;
export const SET_USER_GROUPS_FAILED = `${PREFIX}:SET_USER_GROUPS_FAILED`;
export const REMOVE_USER_GROUPS = `${PREFIX}:REMOVE_USER_GROUPS`;
export const REMOVE_USER_GROUPS_SUCCEEDED = `${PREFIX}:REMOVE_USER_GROUPS_SUCCEEDED`;
export const REMOVE_USER_GROUPS_FAILED = `${PREFIX}:REMOVE_USER_GROUP_FAILED`;
export const EDIT_FORM = `${PREFIX}:EDIT_FORM`;
export const RESET_ADVERTISERS = `${PREFIX}:RESET_ADVERTISERS`;
export const FORM_NAME = 'UserGroups';
export const PAGE_TITLE = 'Set User Groups';
export const ROUTE = '/usergroups';
export const FLIGHT_DROPDOWN_LIMIT = 10;

export type FormValues = {
  advertiser: Advertiser,
  flightType: number,
  selectedFlights: Array<Flight>,
};

export const LOADING_STATES = {
  loading: 'loading',
  notLoading: 'notLoading',
  success: 'success',
};

export type LoadingStates = $Values<typeof LOADING_STATES>;

export const ACTIONS = {
  apply: { value: 'apply', displayName: 'APPLY NEW USER GROUPS' },
  remove: { value: 'remove', displayName: 'REMOVE ALL USER GROUPS' },
};

// eslint-disable-next-line max-len
export const GENERIC_ERROR_MSG = 'An unexpected error occured. Please try again later. If problems persist, reach out to Copilot support.';
