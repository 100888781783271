export const MEMBERS_FETCH_SUCCEEDED = 'USER:MEMBERS_FETCH_SUCCEEDED';
export const MEMBERS_FETCH_FAILED = 'USER:MEMBERS_FETCH_FAILED';
export const ROLES_FETCH_SUCCEEDED = 'USER:ROLES_FETCH_SUCCEEDED';
export const ROLES_FETCH_FAILED = 'USER:ROLES_FETCH_FAILED';
export const MEMBER_ROLES_MAP_FETCH_SUCCEEDED = 'USER:MEMBER_ROLES_MAP_FETCH_SUCCEEDED';
export const MEMBER_ROLES_MAP_FETCH_FAILED = 'USER:MEMBER_ROLES_MAP_FETCH_FAILED';

export const UPDATE_MEMBER_ROLES = 'USER:UPDATE_MEMBER_ROLES';

export const USER_CREATE = 'USER:USER_CREATE';
export const USER_CREATE_SUCCEEDED = 'USER:USER_CREATE_SUCCEEDED';
export const USER_CREATE_FAILED = 'USER:USER_CREATE_FAILED';

export const USER_MEMBER_ROLE_CREATE_SUCCEEDED = 'USER:USER_MEMBER_ROLE_CREATE_SUCCEEDED';

export const RESET_ALL = 'USER:RESET_ALL';
export const USER_STATUS_MSG = 'USER:USER_STATUS_MSG';
export const USER_SUCCESS_MSG = 'User Create/Edit Successfully';

export const memberRoleColumns = [
  { text: 'Members', value: 'member' },
  { text: 'External ID', value: 'externalId' },
  { text: 'Roles', value: 'role' },
];

export const GET_USER = 'USER:USER_FETCH';
export const USER_ROLE_FETCH_SUCCEEDED = 'USER:USER_ROLE_FETCH_SUCCEEDED';
export const USER_FETCH_FAILED = 'USER:USER_FETCH_FAILED';
export const UPDATE_USER = 'USER:UPDATE_USER';
export const USER_UPDATE_FAILED = 'USER:USER_UPDATE_FAILED';
export const MEMBER_ROLE_DELETE_SUCCEEDED = 'USER:MEMBER_ROLE_DELETE_SUCCEEDED';

export const REQUEST_ACCESS = 'USER:REQUEST_ACCESS';
export const EMAIL_SENT_SUCCESS = 'USER:EMAIL_SENT_SUCCESS';
export const EMAIL_SENT_FAILURE = 'USER:EMAIL_SENT_FAILURE';
export const VALIDATING_USER = 'USER:VALIDATING_USER';
export const USER_VALIDATION = 'USER:USER_VALIDATION';

export const USER_DETAIL_FORM = 'userDetail';
export const SAVE_APPROVER = 'USER:SAVE_APPROVER';

export const REQUEST_NOTES_DEFAULT_TEXT = 'Could you please update my roles and grant me access to the following DSP accounts:';

export const APPROVER_PLACEHOLDER = 'firstname.lastname@company.com';
