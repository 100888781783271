const AIRFLOW = 'AIRFLOW';

export const FETCH_REPORT = `${AIRFLOW}:FETCH_REPORT`;
export const FETCH_REPORT_STARTED = `${AIRFLOW}:FETCH_REPORT_STARTED`;
export const FETCH_REPORT_SUCCEEDED = `${AIRFLOW}:FETCH_REPORT_SUCCEEDED`;
export const FETCH_REPORT_FAILED = `${AIRFLOW}:FETCH_REPORT_FAILED`;
export const FETCH_REPORT_RETURNED_EMPTY = `${AIRFLOW}:FETCH_REPORT_RETURNED_EMPTY`;
export const FETCH_DAG_STATUS_SUCCESS = `${AIRFLOW}:FETCH_DAG_STATUS_SUCCESS`;
export const FETCH_DAG_STATUS_RUNNING = `${AIRFLOW}:FETCH_DAG_STATUS_RUNNING`;
export const FETCH_DAG_STATUS_FAILED = `${AIRFLOW}:FETCH_DAG_STATUS_FAILED`;
export const FETCH_DAG_CONTENT_SUCCEEDED = `${AIRFLOW}:FETCH_DAG_CONTENT_SUCCEEDED`;
export const S3_FETCH_SUCCEEDED = `${AIRFLOW}:S3_FETCH_SUCCEEDED`;
export const SUCCESSFUL_REQUEST_AFTER_FAILURE = `${AIRFLOW}:SUCCESSFUL_REQUEST_AFTER_FAILURE`;
export const DAG_REQUEST_FAILED = `${AIRFLOW}:DAG_REQUEST_FAILED`;
export const POLL_DAG_STATUS = `${AIRFLOW}:POLL_DAG_STATUS`;

export const AF_NUM_RETRIES = 10;
export const AF_RETRY_DELAY_TIME = 10000;
export const AF_POLL_DELAY_TIME = 1000;
