import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Users } from 'utils/copilotAPI';
import {
  WppInput,
  WppTypography,
  WppSideModal,
  WppActionButton,
  WppIconEdit,
  WppButton,
  WppIconTrash,
  WppIconPlus,
  WppIconError,
  WppDivider,
  WppInlineMessage,
  WppTooltip,
} from 'buildingBlocks';
import useSideModalHideFreshdesk from 'utils/hooks/useSideModalHideFreshdesk';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { USER_SETTINGS } from '../style';
import { WppEmailChip } from './WppEmailChip';

const MAX_EMAIL_LIMIT = 10;

const {
  emailInputModal,
  emailModalHeader,
  emailModalTableRow,
  emailModalInput,
  emailModalTrash,
  saveUndoStyle,
  undoButtonStyle,
  emailErrorInput,
  emailErrorIcon,
  limitMessageStyle,
} = USER_SETTINGS;

type EmailChipsProps = {
  index: number
  items: Array<string>
  onChange: (...event: any[]) => void
  disabled: boolean
};

const EmailChips = (props: EmailChipsProps) => {
  const { index, items, onChange, disabled } = props;
  const [emailList, setEmailList] = useState<Array<string>>([]);
  const [modalEmailList, setModalEmailList] = useState<Array<string>>(emailList);
  const [inputValue, setInputValue] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errorsMap, setErrorsMap] = useState<{ [key: number]: boolean }>({});
  const [isAddNewEmail, setIsAddNewEmail] = useState<boolean>(false);
  const errorMessage = useRef<string>('');

  useEffect(() => {
    if (items) {
      setEmailList(items);
    }
  }, [index, items]);
  // hide freshdesk button when side modal is open
  useSideModalHideFreshdesk(isModalOpen);

  const isInList = (email: string) => modalEmailList.includes(email);
  // eslint-disable-next-line no-useless-escape
  const isEmail = (email: string) => /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email);

  const isValid = (email: string) => {
    if (isInList(email)) {
      errorMessage.current = `${email} has already been added.`;
      return false;
    }
    return true;
  };

  const handleChange = async (evt: WppInputCustomEvent<InputChangeEventDetail>, itemIndex: number) => {
    const textEntered = evt.target.value;
    setInputValue(textEntered);

    const isTextValid = isEmail(textEntered) && isValid(textEntered);
    const isInExistingList = isInList(textEntered);
    const isDifferentFromCurrent = !_.isEqual(textEntered, modalEmailList[itemIndex]);
    if (!isTextValid) {
      errorMessage.current = !errorMessage.current ? 'Invalid email address.' : errorMessage.current;
      setErrorsMap((prev) => ({ ...prev, [itemIndex]: true }));
      return;
    }

    if (isTextValid) {
      const res = await Users.validateUser({ approver: textEntered });

      if (res.data.isValid) {
        const updatedList = itemIndex < 0
          ? [...modalEmailList, textEntered]
          : modalEmailList.map((email, idx) => (idx === itemIndex ? textEntered : email));

        setModalEmailList(updatedList);
        setIsAddNewEmail(false);
        errorMessage.current = '';
        setErrorsMap((prev) => ({ ...prev, [itemIndex]: false }));
        setInputValue('');
      } else {
        errorMessage.current = 'Invalid email address.';
        setErrorsMap((prev) => ({ ...prev, [itemIndex]: true }));
      }
    } else if (isInExistingList) {
      setErrorsMap((prev) => ({ ...prev, [itemIndex]: isDifferentFromCurrent }));
      if (!isDifferentFromCurrent) {
        setInputValue('');
        errorMessage.current = '';
      }
    }
  };

  const addEditEmails = () => {
    setIsModalOpen(true);
    setModalEmailList(emailList);
  };

  const addNewEmail = () => {
    setIsAddNewEmail(true);
    errorMessage.current = '';
    setErrorsMap((prev) => ({ ...prev, [-1]: false }));
  };

  const removeFromModalEmailList = (itemValue) => {
    setModalEmailList(modalEmailList.filter((item) => item !== itemValue));
  };

  const removeNewEmailInput = () => {
    setIsAddNewEmail(false);
    setInputValue('');
  };

  const handleCloseModal = () => {
    setIsAddNewEmail(false);
    setInputValue('');
    setIsModalOpen(false);
    setModalEmailList([]);
    setErrorsMap({});
  };

  const handleModalSave = () => {
    setEmailList(modalEmailList);
    setIsModalOpen(false);
  };

  // This makes the latest emailList available to getValues()
  useEffect(() => {
    onChange(emailList);
    setModalEmailList(emailList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailList, onChange]);

  return (
    <>
      <WppTypography tag="p" type="s-body" style={{ display: emailList.length > 0 ? 'none' : 'block' }}>
        None
      </WppTypography>
      <WppEmailChip emailList={emailList} />
      <WppSideModal
        open={isModalOpen}
        onWppSideModalClose={handleCloseModal}
        onWppSideModalOpen={addEditEmails}
      >
        <h3 slot="header">
          <WppTypography tag="p" type="2xl-heading" style={emailModalHeader}>
            Share With
          </WppTypography>
        </h3>
        <div slot="body" className="modalBody" style={emailInputModal}>
          <table width="100%">
            <thead>
              <tr style={emailModalTableRow}>
                <th colSpan={2} align="left">
                  <WppTypography tag="p" type="s-strong" style={emailModalInput}>
                    Emails
                  </WppTypography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <WppDivider />
                </td>
              </tr>
              {modalEmailList.map((item, i) => (
                <React.Fragment key={`email-item-${item}`}>
                  <tr style={emailModalTableRow}>
                    <td style={emailErrorInput}>
                      <WppInput
                        name="email"
                        messageType={errorsMap[i] ? 'error' : undefined}
                        value={item}
                        onWppChange={(evt) => handleChange(evt, i)}
                        disabled={disabled}
                        style={emailModalInput}
                      />
                      {errorsMap[i] && (
                        <WppTooltip text={errorMessage.current} config={{ placement: 'bottom' }}>
                          <WppIconError style={emailErrorIcon} />
                        </WppTooltip>
                      )}

                    </td>
                    <td style={emailModalTrash}>
                      <WppActionButton variant="secondary" onClick={() => removeFromModalEmailList(item)}>
                        <WppIconTrash slot="icon-start" />
                      </WppActionButton>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <WppDivider />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              {isAddNewEmail && (
                <React.Fragment key="new-email-input">
                  <tr style={emailModalTableRow}>
                    <td style={emailErrorInput}>
                      <WppInput
                        name="email"
                        placeholder={!disabled ? 'Enter Emails' : null}
                        value={inputValue}
                        messageType={errorsMap[-1] ? 'error' : undefined}
                        onWppChange={(evt) => handleChange(evt, -1)}
                        disabled={disabled}
                        style={emailModalInput}
                      />
                      {errorsMap[-1] && (
                        <WppTooltip text={errorMessage.current} config={{ placement: 'bottom' }}>
                          <WppIconError style={emailErrorIcon} />
                        </WppTooltip>
                      )}
                    </td>
                    <td style={emailModalTrash}>
                      <WppActionButton variant="secondary" onClick={removeNewEmailInput}>
                        <WppIconTrash slot="icon-start" />
                      </WppActionButton>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <WppDivider />
                    </td>
                  </tr>
                </React.Fragment>
              )}
              {modalEmailList.length >= MAX_EMAIL_LIMIT && (
                <tr style={limitMessageStyle}>
                  <td>
                    <WppInlineMessage size="s" message="You can only add no more than 10 emails." type="information" />
                  </td>
                </tr>
              )}
              <tr style={emailModalTableRow}>
                <td>
                  <WppActionButton
                    variant="primary"
                    onClick={addNewEmail}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        addNewEmail();
                      }
                    }}
                    disabled={modalEmailList.length < MAX_EMAIL_LIMIT ? isAddNewEmail : true}
                  >
                    <WppIconPlus slot="icon-start" /> Add Email
                  </WppActionButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div slot="actions" style={saveUndoStyle}>
          <WppButton
            variant="secondary"
            size="s"
            onClick={handleCloseModal}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleCloseModal();
              }
            }}
            style={undoButtonStyle}
          >
            Close
          </WppButton>
          <WppButton
            variant="primary"
            size="s"
            onClick={handleModalSave}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleModalSave();
              }
            }}
            disabled={_.isEqual(modalEmailList, emailList)}
          >
            Save
          </WppButton>
        </div>
      </WppSideModal>
      <WppActionButton variant="secondary" disabled={disabled} onClick={addEditEmails}>
        <WppIconEdit slot="icon-start" />
        Edit
      </WppActionButton>
    </>
  );
};
export default EmailChips;
