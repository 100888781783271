/* eslint-disable no-restricted-syntax */

import _ from 'lodash';

export function* paths(source: {}, start: Array<unknown> = []): Generator {
  for (const name of Object.keys(source)) {
    const path = _.concat(start, name);
    const node = _.get(source, name);
    if (_.isPlainObject(node)) {
      // Yield object constructor and descend.
      yield [path, {}];
      // @ts-ignore generator
      yield* paths(node, path);
    } else if (_.isArray(node)) {
      // Yield array constructor and descend.
      yield [path, []];
      // @ts-ignore generator
      yield* paths(node, path);
    } else {
      // Yield leaf node nodes directly.
      yield [path, node];
    }
  }
}

type Camelizer = (s: string) => string;

/**
 * Given a source object, change all snake_case paths to camelCase paths.
 */
const desnakify = (source: {}, camelizer: Camelizer = _.camelCase) => {
  const target = {};
  // @ts-ignore generator
  for (const [path, value] of paths(source)) {
    const camelPath = path.map(camelizer);
    _.set(target, camelPath, value);
  }
  return target;
};

export default desnakify;
