import _ from 'lodash';
import React from 'react';
import { WppSelect, WppListItem, WppTypography, WppLabel, WppGrid, WppDivider } from 'buildingBlocks';
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types';
import { USER_SETTINGS } from '../style';
import { tzNames, timeZoneDisplayNameFormat } from '../utils';

const {
  timeZoneDropDown,
} = USER_SETTINGS;

type TimeZoneProp = {
  key: string
  text: string
  value: string
};

type TimeZoneDropdownProps = {
  timeZone: string
  items: Array<TimeZoneProp>,
  saveUserSetting: (key: string, value: string | boolean) => void,
  disabled: boolean
};

const TimeZoneDropdown = (props: TimeZoneDropdownProps) => {
  const { disabled, timeZone, items, saveUserSetting } = props;
  const handleWppChange = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    const data = event.detail;
    saveUserSetting('timeZone', _.toString(data.value));
  };
  const timeZoneDisplay = `${timeZoneDisplayNameFormat(timeZone)} ${timeZone}`;

  return (
    <WppSelect
      onWppChange={handleWppChange}
      placeholder={timeZoneDisplay}
      value={timeZoneDisplay}
      withSearch
      disabled={disabled}
      message="This setting will affect email notifications."
      style={timeZoneDropDown}
    >
      {
        _.map(items, (item: any) => (
          <WppListItem key={item.key} value={item.value}>
            <p slot="label">{item.text}</p>
          </WppListItem>
        ))
      }
    </WppSelect>
  );
};

type EmailNotificationsProps = {
  timeZoneSetting: string
  saveUserSetting: (key: string, value: string | boolean) => void
  disabled: boolean
};

const EmailNotifications = (props: EmailNotificationsProps) => {
  const { timeZoneSetting, saveUserSetting, disabled } = props;
  return (
    <WppGrid container fullWidth>
      <WppGrid item all={24}>
        <WppTypography tag="p" type="xl-heading">
          Notifications
        </WppTypography>
        <WppDivider />
      </WppGrid>
      <WppGrid item all={24}>
        <WppLabel
          config={{ text: 'Time Zone' }}
          htmlFor="name"
          typography="s-strong"
        />
      </WppGrid>
      <WppGrid item all={24}>
        <TimeZoneDropdown
          timeZone={timeZoneSetting}
          items={tzNames}
          saveUserSetting={saveUserSetting}
          disabled={disabled}
        />
      </WppGrid>
    </WppGrid>
  );
};

export default EmailNotifications;
