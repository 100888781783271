import {
  LOCAL_STORAGE_SET_ITEM,
  LOCAL_STORAGE_SET_ITEM_COMPLETED,
  LOCAL_STORAGE_CLEAR_ALL,
} from './constants';

export function setItem(key, value) {
  return {
    type: LOCAL_STORAGE_SET_ITEM,
    payload: { key, value },
  };
}

export function setItemCompleted(key, value) {
  return {
    type: LOCAL_STORAGE_SET_ITEM_COMPLETED,
    payload: { key, value },
  };
}

export function clearLocalStorage() {
  return {
    type: LOCAL_STORAGE_CLEAR_ALL,
    payload: {},
  };
}
