// @flow
import React from 'react';
import { Popup, Icon } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';

const { WPP } = COPILOT_COLORS;

type FlightItemValidationColumnProps = {
  hasError: boolean,
  flightErrorMsg: string,
};
const FlightItemValidationColumn = ({ hasError, flightErrorMsg }: FlightItemValidationColumnProps) => (
  <span style={{ color: 'grey' }}>
    {hasError ? (
      <span style={{ color: '#e03e4d' }}>
        Failed <Popup size="tiny" trigger={<Icon name="info circle" color="red" />} content={flightErrorMsg} />
      </span>
    )
      : <span style={{ color: WPP.success400 }}>Success</span>}
  </span>
);

export default FlightItemValidationColumn;
