import React from 'react';
import { Grid, Icon } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import { ApproverValidationMode, Mode } from '../types';

export const EmailStatusMessage = ({ sendEmailErrorMsg }: { sendEmailErrorMsg: string }) => (
  <Grid.Row>
    <Grid.Column width={4}>
      {sendEmailErrorMsg
        ? <p style={{ color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.REDS.R700_VALENTINE }}>{sendEmailErrorMsg}</p>
        : <p style={{ color: '#27AE60' }}>Success! Your manager will be notified.</p>}
    </Grid.Column>
  </Grid.Row>
);

type ValidationMessageProps = {
  validatingApprover: boolean
  validationMode: ApproverValidationMode
  mode: Mode
};

export const ValidationMessage = ({ validatingApprover, validationMode, mode }: ValidationMessageProps) => {
  if (mode === Mode.view) {
    return null;
  }
  if (validatingApprover) {
    return (<Icon loading name="spinner" />);
  }
  if (validationMode === ApproverValidationMode.valid) {
    return (<Icon name="check" color="green" />);
  }
  if (validationMode === ApproverValidationMode.invalid) {
    return (<Icon name="close" color="red" />);
  }
  return null;
};

type EmailValidationMessageProps = {
  email: string
  validatingEmail: boolean
  emailValidated: boolean
  mode: Mode
};

export const EmailValidationMessage = ({ email, validatingEmail, emailValidated, mode }: EmailValidationMessageProps) => {
  if (mode !== Mode.create || !email) {
    return null;
  }
  if (validatingEmail) {
    return (<Icon loading name="spinner" />);
  }
  if (emailValidated) {
    return (<Icon name="check" color="green" />);
  }
  return (<Icon name="close" color="red" />);
};
