import _ from 'lodash';
import { toSnakeCase } from 'utils/formattingUtils';
import { mapKeysDeep } from 'utils/functionHelpers';

export function prepareDetachmentConfig(removedFlights) {
  const flightIds = _.map(removedFlights, 'id');
  const conf = {
    flightIdToActions: _.zipObject(flightIds, _.times(flightIds.length, _.constant(null))),
  };
  return mapKeysDeep(conf, (_v, k) => toSnakeCase(k));
}

export default prepareDetachmentConfig;
