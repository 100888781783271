import _ from 'lodash';
import { Member, PrettyOption } from 'utils/types';

/**
  searchByNameOrExtId closes over the value of currently selected option and
  provides SemanticUI Dropdown the function to search options to display

  @param  {Array.<string>}  selected  - array of selected options
  @returns {(options, query) => Array.<Object>}  function in form that SemanticUI expects
*/

type Option = {
  text: string,
  content: { props: { externalId: string } },
  value: string,
};

// eslint-disable-next-line
export function searchByNameOrExtId(selectedInput?: Array<Member | PrettyOption> | string | null) {
  let selected = [];
  if (selectedInput) {
    if (typeof selectedInput === 'string') {
      selected = [selectedInput];
    } else {
      selected = selectedInput;
    }
  }

  function customSearch(options: Array<Option>, searchQuery: string) {
    const re = new RegExp(_.escapeRegExp(searchQuery), 'i');
    return _.filter(options, (option: Option) => !selected.includes(option.value)
      && _.some([option.text, option.content.props.externalId], (t) => re.test(_.deburr(t))));
  }

  return customSearch;
}
