import {
  FETCH_REPORT,
  FETCH_DAG_STATUS_SUCCESS,
  FETCH_DAG_STATUS_RUNNING,
  FETCH_DAG_STATUS_FAILED,
  SUCCESSFUL_REQUEST_AFTER_FAILURE,
  DAG_REQUEST_FAILED,
  POLL_DAG_STATUS,
} from './constants';

export function triggerDagByStrat(
  request,
  actionOnFinish,
  actionOnDisconnect,
  actionOnRecovery,
  actionOnFail,
  taskId,
  triggerFn,
) {
  return {
    type: FETCH_REPORT,
    payload: {
      request,
      actionOnFinish,
      actionOnDisconnect,
      actionOnRecovery,
      actionOnFail,
      taskId,
      triggerFn,
    },
  };
}

export function triggerDetachmentDag(request, triggerFn) {
  return {
    type: FETCH_REPORT,
    payload: { request, triggerFn },
  };
}
export function dagRequestFailed(attemptNumber) {
  return {
    type: DAG_REQUEST_FAILED,
    payload: { try: attemptNumber },
  };
}

export function fetchDagStatusSuccess(status) {
  return {
    type: FETCH_DAG_STATUS_SUCCESS,
    payload: status,
  };
}

export function fetchDagStatusRunning(status) {
  return {
    type: FETCH_DAG_STATUS_RUNNING,
    payload: status,
  };
}

export function fetchDagStatusFailed(status) {
  return {
    type: FETCH_DAG_STATUS_FAILED,
    payload: status,
  };
}

export function successfulRequestAfterFailure() {
  return {
    type: SUCCESSFUL_REQUEST_AFTER_FAILURE,
  };
}

export function pollDagStatus(
  dagId,
  runId,
  taskId,
  actionOnFinish,
  actionOnDisconnect,
  actionOnRecovery,
  actionOnFail,
) {
  return {
    type: POLL_DAG_STATUS,
    payload: {
      dagId,
      runId,
      taskId,
      actionOnFinish,
      actionOnDisconnect,
      actionOnRecovery,
      actionOnFail,
    },
  };
}
