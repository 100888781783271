import { useState } from 'react';
import { RESULTS_LIMIT } from 'constantsBase';
import { Brand } from 'utils/copilotAPI';
import { useAsyncEffect } from 'utils/functionHelpers';
import { Brand as BrandType } from 'utils/types';

export const useBrandFetcher = () => {
  const [loadingBrands, setLoadingBrands] = useState<boolean>(true);
  const [brandError, setBrandError] = useState<string>('');
  const [brandCandidates, setBrandCandidates] = useState<Array<BrandType>>([]);

  useAsyncEffect(async () => {
    try {
      const brandRes = await Brand.get({
        limit: RESULTS_LIMIT,
        skip: 0,
        sort: 'name asc',
        populate: 'advertisers',
        isDeleted: false,
      });
      setBrandCandidates(brandRes.data);
      setLoadingBrands(false);
    } catch (err) {
      setBrandError(err.message);
      setLoadingBrands(false);
    }
  }, []);
  return { brandCandidates, loadingBrands, brandError };
};
