import React, { CSSProperties } from 'react';
import { Container, Loader, Segment } from 'buildingBlocks';
import { pageLoader } from '../style';

const { container, divWrapper, fullHeightAndWidth } = pageLoader;

type PageLoaderProps = {
  style?: CSSProperties
  segmentStyle?: CSSProperties
};

const PageLoader = ({ style, segmentStyle }: PageLoaderProps) => (
  <Container fluid style={style ? { ...container, ...style } : container}>
    <Segment clearing style={segmentStyle}>
      <div style={divWrapper}>
        <div style={{ ...fullHeightAndWidth, zIndex: 1 }}>
          <table style={fullHeightAndWidth}>
            <tbody>
              <tr>
                <td style={{ verticalAlign: 'center' }}>
                  <Loader size="massive" inline="centered" active />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Segment>
  </Container>
);

export default PageLoader;
