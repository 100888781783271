import { put, select, takeEvery, all, call } from 'redux-saga/effects';
import { setItemCompleted } from './actions';
import { LOCAL_STORAGE_SET_ITEM, LOCAL_STORAGE_CLEAR_ALL } from './constants';
import { getUserId, buildKey } from './utils';

export function* setItemSaga({ payload: { key, value } }) {
  const userId = yield select(getUserId);
  const myKey = buildKey(userId, key);
  // Setting localStoage
  yield call(() => window.localStorage.setItem(myKey, value));
  // Setting redux form
  yield put(setItemCompleted(myKey, value));
}

export function* clearLocalStorageSaga() {
  yield call(() => window.localStorage.clear());
}

export function* localStorageSagas() {
  yield all([
    // @ts-ignore redux saga
    takeEvery(LOCAL_STORAGE_SET_ITEM, setItemSaga),
    takeEvery(LOCAL_STORAGE_CLEAR_ALL, clearLocalStorageSaga),
  ]);
}

export default [
  localStorageSagas,
];
