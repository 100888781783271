/**
 * Defines widely used calculations and formulas.
 */
import _ from 'lodash';
import moment from 'moment';

type TwoArityMath = (x: number, y: number) => number;
type ThreeArityMath = (x: number, y: number, z: number) => number;

function checkNumberType(...args: Array<unknown>) {
  if (_.every(args, isFinite)) {
    return true;
  }
  throw new TypeError('Parameters passed to calculation functions must be numbers');
}

export const safeDivision: (a: number, b: number) => number = (a, b) => {
  checkNumberType(a, b);

  if (b <= 0) {
    return 0;
  }

  return a / b;
};

// See: https://confluence.xaxis.com/display/DEP/Analytics
// -------------------------------------------------------
export const cpc: TwoArityMath = (revenue, clicks) => safeDivision(revenue, clicks);
export const netCpc: TwoArityMath = (cost, clicks) => safeDivision(cost, clicks);

export const cpa: TwoArityMath = (revenue, conversions) => safeDivision(revenue, conversions);
export const netCpa: TwoArityMath = (cost, conversions) => safeDivision(cost, conversions);

export const goalCpa: TwoArityMath = (revenue, goalConversions) => safeDivision(revenue, goalConversions);
export const netGoalCpa: TwoArityMath = (cost, goalConversions) => safeDivision(cost, goalConversions);

export const cpcv: TwoArityMath = (revenue, videoCompletes) => safeDivision(revenue, videoCompletes);
export const netCpcv: TwoArityMath = (cost, videoCompletes) => safeDivision(cost, videoCompletes);

export const cpcl: TwoArityMath = (revenue, videoCompletes) => safeDivision(revenue, videoCompletes);
export const netCpcl: TwoArityMath = (cost, videoCompletes) => safeDivision(cost, videoCompletes);

export const vcpm: TwoArityMath = (revenue, viewableImpressions) => safeDivision(revenue, viewableImpressions) * 1000;
export const netVcpm: TwoArityMath = (cost, viewableImpressions) => safeDivision(cost, viewableImpressions) * 1000;

export const netCpm: TwoArityMath = (cost, impressions) => safeDivision(cost, impressions) * 1000;
export const cpm: TwoArityMath = (revenue, impressions) => safeDivision(revenue, impressions) * 1000;
export const avgBid: TwoArityMath = (bid, impressions) => safeDivision(bid, impressions) * 1000;

export const margin: TwoArityMath = (revenue, cost) => (
  safeDivision((revenue - cost), revenue)
);
export const ctr: TwoArityMath = (clicks, impressions) => (
  safeDivision(clicks, impressions)
);
export const conversionRate: TwoArityMath = (conversions, impressions) => (
  safeDivision(conversions, impressions)
);
export const goalConversionRate: TwoArityMath = (goalConversions, impressions) => (
  safeDivision(goalConversions, impressions)
);
export const conversionRevenueRate: TwoArityMath = (conversionRevenue, impressions) => (
  safeDivision(conversionRevenue, impressions)
);

export const impressionValueRate: TwoArityMath = (impressionValue, impressions) => (
  safeDivision(impressionValue, impressions) * 1000
);

export const impValuePerCost: TwoArityMath = (impressionValue, advRevenue) => (
  safeDivision(impressionValue, advRevenue)
);

export const completionRate: TwoArityMath = (videoCompletes, videoStarts) => (
  safeDivision(videoCompletes, videoStarts)
);

export const ivrImps: TwoArityMath = (viewableImpressions, impressions) => (
  safeDivision(viewableImpressions, impressions)
);
export const ivrMeasured: TwoArityMath = (viewableImpressions, viewMeasuredImps) => (
  safeDivision(viewableImpressions, viewMeasuredImps)
);
export const expTimeImps: TwoArityMath = (viewDurationImps, impressions) => (
  safeDivision(viewDurationImps, impressions)
);
export const expTimeMeasured: TwoArityMath = (viewDurationImps, viewMeasuredImps) => (
  safeDivision(viewDurationImps, viewMeasuredImps)
);
export const viewMeasuredRate: TwoArityMath = (viewMeasuredImps, impressions) => (
  safeDivision(viewMeasuredImps, impressions)
);
export const viewabilityRate: TwoArityMath = (viewableImpressions, impressions) => (
  safeDivision(viewableImpressions, impressions)
);

export const cpaPerPostConversions: ThreeArityMath = (cost, postViewConversions, postClickConversions) => (
  safeDivision(cost, (postViewConversions + postClickConversions))
);
export const ecpaPerPostConversions: ThreeArityMath = (cost, postViewConversions, postClickConversions) => (
  cpaPerPostConversions(cost, postViewConversions, postClickConversions)
);

export const cpaByCpm: ThreeArityMath = (_cpm, impressions, clicks) => (
  safeDivision((impressions * _cpm) / 1000, clicks)
);
export const cpcByCpm: ThreeArityMath = (_cpm, impressions, conversions) => (
  safeDivision((impressions * _cpm) / 1000, conversions)
);
export const avgBidBySpread: ThreeArityMath = (cost, spread, impressions) => (
  avgBid((cost + spread), impressions)
);
export const measuredRate: TwoArityMath = (viewMeasuredImps, impressions) => (
  safeDivision(viewMeasuredImps, impressions)
);
export const vcr: TwoArityMath = (fullyCompletedViewViews, videoViews) => (
  ctr(fullyCompletedViewViews, videoViews)
);
export const dcpm: TwoArityMath = (spend, impressions) => (
  safeDivision(spend, impressions) * 1000
);
export const roi: TwoArityMath = (revenue, cost) => (
  safeDivision((revenue - cost), cost)
);
export const vcpcv: TwoArityMath = (revenue, videoViewableCompletes) => safeDivision(revenue, videoViewableCompletes);
export const netVcpcv: TwoArityMath = (cost, videoViewableCompletes) => safeDivision(cost, videoViewableCompletes);

export const costPerImpValue: TwoArityMath = (cost, impressionValue) => safeDivision(cost, impressionValue);
export const impValuePerImp: TwoArityMath = (impressionValue, impressions) => safeDivision(impressionValue, impressions);

export const percentage = (value: number) => (value * 100);

/**
  * vCPM Billable impressions
  * @param {number} viewableImps Viewable impressions
  * @param {number} totalImps Total Impressions
  * @param {number} measuredImps Measured Impressions
  * @param {number} ivr In View Rate
*/
// eslint-disable-next-line max-len
export const billableImps: (viewableImps: number, totalImps: number, measuredImps: number, ivrvalue: number) => number = (viewableImps, totalImps, measuredImps, ivrValue) => {
  checkNumberType(viewableImps, totalImps, measuredImps, ivrValue);
  return viewableImps + (ivrValue * (totalImps - measuredImps));
};

export const flightLength: (effectiveEndDate: string, effectiveStartDate: string) => number = (effectiveEndDate, effectiveStartDate) => {
  if (!_.isDate(effectiveEndDate) || !_.isDate(effectiveStartDate)) {
    throw new TypeError('Parameter must be a javascript Date object');
  }

  const mEndDate = moment(effectiveEndDate);
  const mStartDate = moment(effectiveStartDate);

  return (Math.abs(mEndDate.diff(mStartDate, 'days')) + 1);
};

// Null and string will result at the end of the sort
export const sortNaNAtTheEnd: (b: boolean | null) => (a: any, b: any) => number = (asc = true) => (a, b) => {
  if (_.isNil(a) || isNaN(a)) {
    return 1;
  }

  if (_.isNil(b) || isNaN(b)) {
    return -1;
  }

  if (asc) {
    return a - b;
  }

  return b - a;
};

type Comparator = (x: unknown, y: unknown) => number;
export const lessThan: Comparator = (a, b) => sortNaNAtTheEnd(true)(a, b);
export const greaterThan: Comparator = (a, b) => sortNaNAtTheEnd(false)(a, b);
