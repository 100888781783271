import { useRef, useEffect } from 'react';

// keeps track of the previous prop or state value.
// taken from React docs https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
/* eslint-disable */
export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
