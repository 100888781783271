import React from 'react';
import { Button, Icon, Grid } from 'buildingBlocks';

type Props = {
  disabled: boolean,
  handleAccessRequestSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void,
};

const RequestAccessButton = ({ disabled, handleAccessRequestSubmit }: Props) => (
  <>
    <Grid.Row>
      <Grid.Column>
        <Button
          type="button"
          color="blue"
          onClick={handleAccessRequestSubmit}
          disabled={disabled}
          style={{ width: '400px' }}
          basic
        ><Icon name="plus" /> REQUEST ACCESS
        </Button>
      </Grid.Column>
    </Grid.Row>
    {disabled && (
    <Grid.Row columns={1}>
      <br />
      <p style={{ width: '33%', color: '#FF6E31', marginLeft: '10px' }}>
        You must first set up your manager before you can request access from them. Click “Edit” to select your approver.
      </p>
    </Grid.Row>
    )}
  </>
);

export default RequestAccessButton;
