import _ from 'lodash';
import { OPERATOR } from 'constantsBase';
import { USERS_FETCH } from './constants';

export default function getUsers(limit = 10, skip = 0, searchStr = '', user, isAdmin) {
  const userId = user.id;
  const filter = !_.isEmpty(searchStr) ? { email: { [OPERATOR.CONTAINS]: searchStr } } : {};
  const mergedFilter = !_.isEmpty(searchStr) ? {
    or: [
      { id: userId, email: { [OPERATOR.CONTAINS]: searchStr } },
      { approver: userId, email: { [OPERATOR.CONTAINS]: searchStr } },
    ],
  } : { or: [{ id: userId }, { approver: userId }] };

  const where = isAdmin ? filter : mergedFilter;
  return {
    type: USERS_FETCH,
    payload: {
      limit,
      skip,
      where,
    },
  };
}
