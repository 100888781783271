/* UserSettings constants */
export const PAGE_NAME = 'Settings';

const PREFIX = 'USER_SETTINGS';
export const USER_SETTINGS_LOAD_SELECTED_THEME = `${PREFIX}:LOAD_SELECTED_THEME`;
export const USER_SETTINGS_CHANGE_THEME = `${PREFIX}:CHANGE_THEME`;

export const USER_SETTINGS_UPDATE = `${PREFIX}:USER_SETTINGS_UPDATE`;
export const USER_SETTINGS_UPDATE_COMPLETED = `${PREFIX}:USER_SETTINGS_UPDATE_COMPLETED`;
export const USER_SETTINGS_UPDATE_FAILED = `${PREFIX}:USER_SETTINGS_UPDATE_FAILED`;

export const SHARED_NOTIFICATION_LINK = `
https://copilotsupport.freshdesk.com/support/solutions/articles/47001132268
`;
