import React from 'react';
import { Form, Grid, TextArea } from 'buildingBlocks';
import { FONT_FAMILY } from 'constantsBase';
import RequestAccessButton from './RequestAccessButton';

const REQUEST_SECTION_TEXT_MAX_LENGTH = 280;

type RequestAccessSectionProps = {
  showRequestNotes: boolean,
  showRequestAccessButton: boolean,
  onChangeRequestNotes: (e: React.ChangeEvent<HTMLTextAreaElement>, data: { value: string }) => void,
  handleAccessRequestSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void,
  approver: string,
  requestNotes: string,
};

const RequestAccessSection = ({
  requestNotes, showRequestNotes, showRequestAccessButton,
  onChangeRequestNotes, handleAccessRequestSubmit, approver,
}: RequestAccessSectionProps) => (
  <>
    {showRequestNotes && (
      <Grid.Row>
        <Grid.Column>
          <Form.Field width={4}>
            <label htmlFor="requestNotes">Request Notes</label>
            <TextArea
              name="requestNotes"
              onChange={onChangeRequestNotes}
              value={requestNotes}
              maxLength={REQUEST_SECTION_TEXT_MAX_LENGTH}
              style={{ fontFamily: FONT_FAMILY }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    )}
    {showRequestAccessButton && (
    <RequestAccessButton disabled={!approver} handleAccessRequestSubmit={handleAccessRequestSubmit} />
    )}
  </>
);

export default RequestAccessSection;
