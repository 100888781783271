import React from 'react';
import { WppTypography, WppToggle, WppDivider } from 'buildingBlocks';
import { USER_SETTINGS } from '../style';

const {
  themeToggle,
  sectionHeader,
} = USER_SETTINGS;

type DisplayThemeProps = {
  screenTheme: string
  changeTheme: (theme: string | void) => void
  disabled: boolean
};

const DisplayTheme = (props: DisplayThemeProps) => {
  const { screenTheme, changeTheme, disabled } = props;
  const labelConfig = {
    text: 'Dark Mode',
  };

  const handleToggleChange = (event) => {
    changeTheme(event.detail.value);
  };

  return (
    <table width="100%">
      <tbody>
        <tr>
          <th style={sectionHeader}>
            <WppTypography tag="p" type="xl-heading">
              Theme
            </WppTypography>
            <WppDivider />
          </th>
        </tr>
        <tr>
          <td style={themeToggle}>
            <WppToggle
              disabled={disabled}
              value="dark"
              labelConfig={labelConfig}
              checked={screenTheme === 'dark'}
              onWppChange={handleToggleChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default DisplayTheme;
