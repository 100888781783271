import { useEffect } from 'react';

const useSideModalHideFreshdesk = (modalOpen: boolean) => {
  const freshWorksContainer = document.querySelector('#freshworks-container') as HTMLElement;
  useEffect(() => {
    // hide the freshworks help container when the edit modal is open
    if (freshWorksContainer) {
      freshWorksContainer.style.display = (modalOpen ? 'none' : 'block');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);
};

export default useSideModalHideFreshdesk;
