import React from 'react';
import { Grid, Message } from 'buildingBlocks';
import { GenericMouseEventHandler } from 'utils/types';
import FlightItemValidationColumn from './FlightItemValidationColumn';
import userGroupPageStyles from '../style';

type FlightItemColFlightBoxProps = {
  disableDismiss: boolean,
  onDismiss: GenericMouseEventHandler,
  formatApnObj: string,
  classNameStr: string,
};

export const FlightItemColFlightBox = ({
  disableDismiss,
  onDismiss,
  formatApnObj,
  classNameStr,
}: FlightItemColFlightBoxProps) => (
  <Grid.Column width={6}>
    {!disableDismiss ? (
      <Message
        className="ugs-flight-item"
        style={{ wordBreak: 'break-all' }}
        onDismiss={onDismiss}
      >
        <div style={{ marginRight: '20px' }}>{formatApnObj}</div>
      </Message>
    ) : (
      <Message
        className={`ugs-flight-item ${classNameStr}`}
        style={{ wordBreak: 'break-all', color: '#1a3849' }}
      >
        <div style={{ marginRight: '20px' }}>{formatApnObj}</div>
      </Message>
    )}
  </Grid.Column>
);

type FlightItemColExistingProps = {
  low: string,
  high: string,
  shouldDisplay: boolean,
};

export const FlightItemColExisting = ({ low, high, shouldDisplay } : FlightItemColExistingProps) => (
  <Grid.Column style={userGroupPageStyles.column} width={1}>
    {shouldDisplay && (
      <span style={{ color: 'grey' }}>
        {low}-
        {high}
      </span>
    )}
  </Grid.Column>
);

type FlightItemColSplitProps = {
  pct: number,
  shouldDisplay: boolean,
};

export const FlightItemColSplit = ({ shouldDisplay, pct } : FlightItemColSplitProps) => (
  <Grid.Column style={userGroupPageStyles.column} width={1}>
    {shouldDisplay && <span style={{ color: 'grey' }}>{pct}%</span>}
  </Grid.Column>
);

type FlightItemColNewProps = {
  classNameStr: string,
  newGroupRangeVal: string,
};

export const FlightItemColNew = ({ classNameStr, newGroupRangeVal } : FlightItemColNewProps) => (
  <Grid.Column style={userGroupPageStyles.column} width={2}>
    <Message className={classNameStr} style={userGroupPageStyles.newColBox}>
      {newGroupRangeVal}
    </Message>
  </Grid.Column>
);

type FlightItemColSuccessOrFailProps = {
  hasError: boolean,
  flightErrorMsg: string,
};

export const FlightItemColSuccessOrFail = ({ hasError, flightErrorMsg } : FlightItemColSuccessOrFailProps) => (
  <Grid.Column style={{ ...userGroupPageStyles.column, minWidth: '92px' }} width={1}>
    <FlightItemValidationColumn
      hasError={hasError}
      flightErrorMsg={flightErrorMsg}
    />
  </Grid.Column>
);
