import _ from 'lodash';
import React, { ReactNode, ComponentType } from 'react';
import { WrappedFieldMetaProps } from 'redux-form';
import { Label, Form } from 'buildingBlocks';

/**
 * omitProps a utility component to create a component that will remove props that we don't want passed down
 * this is useful when dealing with different libraries that handle props in different ways
 * (IE redux form and semantic UI)
 * const CleanedInput = omitProps(Input, 'meta');
 * const CleanedInput = omitPoprs(Input, ['meta', 'input']);
 *
 * Component should take a react component
 * propsToRemove can be either a list or a single string to be removed.
 */

type OmitProps = (node: ReactNode, arr: string | Array<string>) => (x: any) => JSX.Element;

export const omitProps: OmitProps = (InnerComponent: ComponentType, propsToRemove) => (props) => {
  const cleanProps = _.omit(props, propsToRemove);
  return <InnerComponent {...cleanProps} />;
};

/**
 * validatedFieldWrapper wrapper component to display if there are any errors from redux form
 * The previous function "validatedWrapper" is left here for backward compatibility, but
 * moving forward, validatedFieldWrapper is preferred since it will highlight the label with errors
 * as well as the field itself.
 * @param {Component} InnerComponent the component to wrap
 * @return {Component} that will display any meta.error messages after it has been touched.
 */

/* eslint-disable no-underscore-dangle */
type Meta = { touched: boolean, error: any, _error: any };
export type ValidatedInputFieldProps = {
  meta: WrappedFieldMetaProps,
  noInput?: any,
  required?: boolean,
  name: string,
  width?: number,
  label?: string,
  title?: string,
  labelPosition?: string,
};

// @ts-ignore
export type ValidatedFieldWrapper = (node: ReactNode) => (inputFields: ValidatedInputFieldProps) => Form.Field<unknown>;

export const validatedFieldWrapper: ValidatedFieldWrapper = (InnerComponent: ComponentType<any>) => (
  ({ meta, noInput, ...rest }: ValidatedInputFieldProps) => {
    // If label position wasn't specified, remove it before passing it down to the input field
    let cleanInput: { label?: string } = rest;
    if (!rest.labelPosition) {
      const omitFields = noInput ? ['label', 'input'] : ['label'];
      cleanInput = _.omit(rest, omitFields);
    }

    const touched = meta.touched && meta.error;
    const displayedLabel = _.get(rest, 'title', _.get(rest, 'label'));

    return (
      // @ts-ignore
      <Form.Field required={rest.required} error={!!touched} width={rest.width}>
        {displayedLabel && <label htmlFor={rest.name}>{displayedLabel}</label>}
        <InnerComponent {...cleanInput} />
        <Label basic style={{ display: 'block', background: 'transparent' }}>{touched && meta.error}</Label>
      </Form.Field>
    );
  }
);
/* eslint-enable */
type ValidatedWrapper = (node: ReactNode) => (m: { meta: Meta }) => ReactNode;

export const validatedWrapper: ValidatedWrapper = (
  InnerComponent: ComponentType<{ error: boolean }>,
) => ({ meta, ...rest }: { meta: Meta }) => (
  <div>
    <InnerComponent error={!!(meta.touched && meta.error)} {...rest} />
    {meta.touched && meta.error
      && (
      <div style={{ color: 'red' }}>
        {meta.error}
      </div>
      )}
  </div>
);
