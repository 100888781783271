import _ from 'lodash';
import React, { Component, CSSProperties, Ref } from 'react';
import { connect } from 'react-redux';

import KeyBinding from 'react-keybinding-component';
import { KeyboardEvent, KONAMI_CODE } from 'utils/keymap';

import { themeSettingLSKey } from 'containers/UserSettings/UserSettings';
import { getLocalStorageItem } from 'utils/localstorage/utils';

import { SNOW_RADIUS, MAX_PARTICLES } from './constants';
import { getDimensions, updateParticleCoords, Particle } from './utils';

/* eslint-disable react/no-string-refs */
type Props = {
  changeTheme: Function,
  userSelectedTheme: string | null,
};

type State = {
  size: { width: number, height: number },
  run: boolean,
  // @ts-ignore
  updateSnow: IntervalID | null,
  canvasCtx: CanvasRenderingContext2D,
  particles: Array<Particle>,
  prevTheme: string | null,
};

class Snow extends Component<Props, State> {
  constructor(props: Props, context: Object) {
    super(props, context);
    this.angle = 0;
    this.keystrokes = [];

    this.state = {
      size: getDimensions(),
      run: false,
      updateSnow: null,
      canvasCtx: null,
      particles: [],
      prevTheme: props.userSelectedTheme,
    };
  }

  componentDidMount() {
    // @ts-ignore ???
    this.setState({ canvasCtx: this.refs.copilotXmasCanvas.getContext('2d') });
  }

  componentWillUnmount() {
    this.stop();
  }

  angle: number;

  keystrokes: Array<number>;

  konamiTimeout: number;

  // eslint-disable-next-line react/no-unused-class-component-methods
  ref: Ref<string>;

  start = () => {
    const prevTheme = this.props.userSelectedTheme;
    this.props.changeTheme('dark');
    const { width, height } = getDimensions();
    const particles = _.map(_.range(MAX_PARTICLES), () => {
      const r = Math.random() * SNOW_RADIUS + 1;
      return ({
        x: Math.random() * width,
        y: Math.random() * height,
        initR: r,
        r,
        d: r * MAX_PARTICLES,
        comingCloser: Math.random() > 0.5,
      });
    });

    const updateSnow = setInterval(() => {
      this.draw();
      this.update();
    }, 50);
    this.setState({ size: { width, height }, particles, updateSnow, run: true, prevTheme });
  };

  stop = () => {
    if (this.state.run) {
      const { width, height } = getDimensions();
      this.props.changeTheme(this.state.prevTheme);
      if (this.state.canvasCtx) {
        this.state.canvasCtx.clearRect(0, 0, width, height);
      }
      if (this.state.updateSnow) {
        clearInterval(this.state.updateSnow);
      }
      this.setState({ size: { width, height }, run: false });
    }
  };

  draw = () => {
    const { canvasCtx, particles } = this.state;
    const { width, height } = getDimensions();

    if (canvasCtx) {
      canvasCtx.clearRect(0, 0, width, height);

      particles.forEach((p) => {
        canvasCtx.beginPath();
        canvasCtx.fillStyle = `rgba(255, 255, 255, ${(1 / (p.r * 3))})`;
        canvasCtx.moveTo(p.x, p.y);
        canvasCtx.shadowColor = 'rgba(255, 255, 255, 0.55)';
        canvasCtx.shadowBlur = p.r + SNOW_RADIUS * 2;
        canvasCtx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
        canvasCtx.fill();
        canvasCtx.beginPath();
        canvasCtx.fillStyle = 'rgba(255, 255, 255, 0.75)';
        canvasCtx.arc(p.x, p.y, p.r / 1.5, 0, Math.PI * 2, true);
        canvasCtx.fill();
      });
    }
  };

  update = () => {
    const { particles } = this.state;
    const { width, height } = getDimensions();

    if (!_.isEmpty(particles)) {
      const rng = Math.random();
      const newAngle = rng > 0.5 ? 0.01 : -0.01;
      this.angle += newAngle;
      // Don't use setState because it defeat the setInterval purpose (and we don't want react to manage this component)
      particles.forEach((p) => updateParticleCoords(p, rng, this.angle + (rng > 0.3 ? -newAngle : 0), width, height));
    }

    this.setState({ size: { width, height } });
  };

  registerKeys = (e: KeyboardEvent) => {
    if (KONAMI_CODE[this.keystrokes.length] === e.keyCode) {
      this.keystrokes.push(e.keyCode);
      if (this.konamiTimeout) {
        clearTimeout(this.konamiTimeout);
      }
      if (this.keystrokes.length === KONAMI_CODE.length) {
        if (this.state.run) {
          this.stop();
        } else {
          this.start();
        }
      } else {
        // @ts-ignore should be Timeout?
        this.konamiTimeout = setTimeout(() => {
          this.keystrokes = [];
        }, 1000);
      }
    } else if (this.konamiTimeout) {
      clearTimeout(this.konamiTimeout);
      this.keystrokes = [];
    }
  };

  render() {
    const snowStyles: CSSProperties = {
      margin: 0,
      padding: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      zIndex: 999,
    };

    return (
      <div>
        <canvas
          ref="copilotXmasCanvas"
          style={snowStyles}
          {...this.state.size}
        />
        <KeyBinding
          onKey={(e) => this.registerKeys(e)}
          type="keydown"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSettings: state.login.settings,
    userSelectedTheme: getLocalStorageItem(state, themeSettingLSKey),
  };
}

// @ts-ignore - type issue related to Snow component
export default connect(mapStateToProps)(Snow);
