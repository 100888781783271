import { useState, useEffect } from 'react';

export enum PossibleStates {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  hasData = 'hasData',
  permissionDenied = 'permissionDenied',
}

// TODO: improve types here and in useFetcher to use templates/annotations
export type State =
  | { kind: PossibleStates.initial }
  | { kind: PossibleStates.loading }
  | { kind: PossibleStates.error, errorObject: any }
  | { kind: PossibleStates.hasData, data: any };

export type StateT<T = any, U = any> =
  | { kind: PossibleStates.initial }
  | { kind: PossibleStates.loading }
  | { kind: PossibleStates.error, errorObject: U }
  | { kind: PossibleStates.hasData, data: T };

const useFetcher = (action: () => Promise<any>, deps: Array<any> = []) => {
  const [currentState, setCurrentState] = useState<State>({ kind: PossibleStates.initial });

  useEffect(() => {
    // tracks whether the component is still mounted
    let isMounted = true;

    async function loadData() {
      setCurrentState({ kind: PossibleStates.loading });
      try {
        const res = await action();
        // update state only if component is still mounted
        if (isMounted) {
          setCurrentState(res);
        }
      } catch (e) {
        if (isMounted) {
          setCurrentState({ kind: PossibleStates.error, errorObject: e });
        }
      }
    }

    loadData();
    // clean up function when component unmounts
    return () => {
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return currentState;
};

export default useFetcher;
