const keymap = {
  ENTER: 13,
  CTRL: 17,
  CMD: 91,
  KEY_0: 48,
  KEY_1: 49,
  KEY_2: 50,
  KEY_3: 51,
  KEY_4: 52,
  KEY_5: 53,
  KEY_6: 54,
  KEY_7: 55,
  KEY_8: 56,
  KEY_9: 57,
  KEY_BACKSLASH: 220,
  KEY_ARROW_LEFT: 37,
  KEY_ARROW_UP: 38,
  KEY_ARROW_RIGHT: 39,
  KEY_ARROW_DOWN: 40,
  KEY_A: 65,
  KEY_B: 66,
  KEY_H: 72,
  DELETE: 46,
  BACKSPACE: 8,
  ESCAPE: 27,
  TAB: 9,
};

export const KONAMI_CODE = [
  keymap.KEY_ARROW_UP,
  keymap.KEY_ARROW_UP,
  keymap.KEY_ARROW_DOWN,
  keymap.KEY_ARROW_DOWN,
  keymap.KEY_ARROW_LEFT,
  keymap.KEY_ARROW_RIGHT,
  keymap.KEY_ARROW_LEFT,
  keymap.KEY_ARROW_RIGHT,
  keymap.KEY_B,
  keymap.KEY_A,
];

export type KeyboardEvent = {
  shiftKey: boolean,
  ctrlKey: boolean,
  keyCode: number,
  preventDefault: Function,
};

export default keymap;
