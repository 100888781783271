/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import qs from 'qs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { GlobalState } from 'reducers';
import { WppGrid, AgGridHeader, AgGrid, WppPagination, WppTypography, WppButton, WppIconPlus } from 'buildingBlocks';
import { PaginationChangeEventDetail, User } from 'utils/types';
import { Permission } from 'utils/featureFlags';
import DataLoading from 'components/DataLoading';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { isAdminOrQAGlobal, isSupportL1 } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import PermissionWrapper from 'components/PermissionWrapper';
import { Link } from 'react-router-dom';
import { Users as TypeUsers, UsersState } from './types';
import getUsers from './actions';
import { mainContainer, usersPageHeaderContainer } from './style';
import {
  USERS_ELEMENTS_PER_PAGE_OPTIONS,
  USERS_STARTING_ELEMENTS_PER_PAGE,
} from './constants';
import UserTableHeader from './UserTableHeader';

const REQUIRED_PERMISSIONS = [
  Permission.manageUserRoles,
  Permission.adminUsers,
];
const REQUIRED_PERMISSIONS_ADD_USER = [Permission.adminUsers];
const PAGE_NAME = 'Users';

export const CustomHeader: React.FC = (): React.ReactElement => (
  <WppGrid container fullWidth>
    <WppGrid item all={24} style={usersPageHeaderContainer}>
      <WppTypography tag="h1" type="3xl-heading">
        {PAGE_NAME}
      </WppTypography>
      <PermissionWrapper
        unauthorizedComponent={<></>}
        permissions={REQUIRED_PERMISSIONS_ADD_USER}
      >
        <Link to="/users/create">
          <WppButton>
            <WppIconPlus slot="icon-start" /> New User
          </WppButton>
        </Link>
      </PermissionWrapper>
    </WppGrid>
  </WppGrid>
);

export const Users: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector<GlobalState>(
    (rootState) => rootState.login.session.data.user,
  ) as User;
  const { loading, usersCount, users } = useSelector<GlobalState>(
    (rootState) => rootState.users,
  ) as TypeUsers;

  const [usersState, setUsersState] = useState<UsersState>({
    search: '',
    limit: USERS_STARTING_ELEMENTS_PER_PAGE,
    startingPage: 1,
  });

  const updateStateAndGetUsers = async (
    limit: number,
    skip: number,
    search: string,
  ) => {
    setUsersState({
      search,
      limit,
      startingPage: skip / limit + 1,
    });
    const fetchAllUsers = isAdminOrQAGlobal(user) || isSupportL1(user);
    await dispatch(getUsers(limit, skip, search, user, fetchAllUsers));
  };

  const browserUrlUpdate = (limit: number, skip: number, search: string) => {
    const newFilter = { search, limit, skip };
    navigate(`${location.pathname}?${qs.stringify(newFilter)}`, {
      replace: true,
    });
  };

  const onDataRangeChange = ({
    limit,
    start,
  }: {
    limit: number;
    start: number;
  }) => {
    const skip = start;
    browserUrlUpdate(limit, skip, usersState.search);
    updateStateAndGetUsers(limit, skip, usersState.search);
  };

  const onSearchChange = (search: string) => {
    browserUrlUpdate(usersState.limit, 0, search);
    updateStateAndGetUsers(usersState.limit, 0, search);
  };

  useMount(() => {
    const { limit, skip, search } = qs.parse(
      location.search.replace(/^\?/, ''),
    );
    const parsedLimit = (_.toNumber(limit) > 0 && parseInt(limit as string, 10))
      || USERS_STARTING_ELEMENTS_PER_PAGE;
    const parsedSkip = (_.toNumber(skip) >= 0 && parseInt(skip as string, 10)) || 0;
    const parsedSearch = _.isUndefined(search) ? '' : search;

    updateStateAndGetUsers(parsedLimit, parsedSkip, parsedSearch as string);
  });

  return (
    // @ts-ignore - page template
    <WppPermissionPageTemplate
      name={PAGE_NAME}
      permissions={REQUIRED_PERMISSIONS}
      allPermissions={false}
      customHeader={<CustomHeader />}
    >
      <DataLoading loading={loading} pageName={PAGE_NAME}>
        <WppGrid container style={mainContainer}>
          <WppGrid item all={5}>
            <AgGridHeader
              onSearchChange={(event) => onSearchChange((event.target as HTMLWppInputElement).value)}
              searchPlaceholder="Search for users"
              searchValue={usersState.search}
              withSearch
            />
          </WppGrid>
          <WppGrid item all={24}>
            <AgGrid
              columnDefs={UserTableHeader(user, REQUIRED_PERMISSIONS)}
              rowData={users}
            />
          </WppGrid>
          <WppGrid item all={24}>
            <WppPagination
              count={usersCount}
              itemsPerPage={USERS_ELEMENTS_PER_PAGE_OPTIONS}
              activePageNumber={usersState.startingPage}
              selectedItemPerPage={usersState.limit}
              onWppChange={(event: Event) => {
                const { itemsPerPage, page } = (event as CustomEvent<
                PaginationChangeEventDetail
                >).detail;
                onDataRangeChange({
                  limit: itemsPerPage,
                  start: (page - 1) * itemsPerPage,
                });
              }}
            />
          </WppGrid>
        </WppGrid>
      </DataLoading>
    </WppPermissionPageTemplate>
  );
};

export default Users;
