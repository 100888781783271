import { NavigateFunction } from 'react-router';
import { User as TypeUser, Role, SingleMemberRole, Member, Region } from 'utils/types';

export type UserProps = {
  mode: Mode
  setMode: (x: Mode) => void
  userId: number
  isLoggedInUser: boolean
  onCreateNewUser: boolean
  userData: TypeUser
  onUserProfile: boolean
  isNewUser: boolean
  navigate: NavigateFunction
};

export type ButtonProps = {
  mode: Mode
  setMode: (x: Mode) => void
};

export enum Mode {
  view = 0,
  create = 1,
  edit = 2,
}

export enum UserSaveMode {
  save = 0,
  update = 1,
}

export enum ApproverValidationMode {
  valid = 1,
  invalid = 2,
}

export type MemberRoleState = {
  roleOptions: Array<Role>,
  membersPerRoles: Array<Member>,
  memberRoles: Array<string | SingleMemberRole>,
  regions: Array<Region>,
  error: string,
};

export type MembersByRegion = {
  name: string
  value: Array<Member>
};

export type MemberRoleToDelete = {
  id: number
  member: Member
  role: Role
};

export type MemberRoleMap = {
  [key: string]: Array<number>
};

export type UserForm = {
  firstName: string
  lastName: string
  email: string
  approver: string
  requestNotes: string
  selectedMembers: Array<Member>
  selectedRoles: Array<Role>
  selectedRegion: string
};

export type RenderUserAfterApproverLoadsProps = {
  currUserExists: boolean
  onCreateNewUser: boolean
  approver?: string
  userId: number
  mode: Mode
  setMode: (x: Mode) => void
  isLoggedInUser: boolean
  userData: TypeUser
  onUserProfile: boolean
  userDoesntExistAndNotCreateMode: boolean
  isNewUser: boolean
  email: string
  navigate: NavigateFunction
};
