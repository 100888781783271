import { CSSProperties } from 'react';

const WHITELIST_IP_STYLES: { [key: string]: CSSProperties } = {
  modalSectionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalMsgContainer: {
    padding: '32px 24px 24px',
    fontSize: '16px',
    textAlign: 'center',
  },
  whiteListBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default WHITELIST_IP_STYLES;
