import _ from 'lodash';
import { SetStateAction } from 'react';
import momentTimezone from 'moment-timezone';
import { Dispatch } from 'redux';
import { UserSettings } from 'utils/copilotAPI';
import { UserSettings as UserSettingsType } from 'utils/types';
import { USER_SETTINGS_UPDATE, USER_SETTINGS_UPDATE_COMPLETED, USER_SETTINGS_UPDATE_FAILED } from './constants';

export const timeZoneDisplayNameFormat = (v) => `UTC${momentTimezone.tz(v).format('Z')}`;

export const tzNames = _.map(momentTimezone.tz.names(), (name) => ({
  key: name,
  text: _.replace(`(UTC${momentTimezone.tz(name).format('Z')}) ${name}`, '_', ' '),
  value: name,
}));

export const updateUserSettings = async (
  id: string,
  config: UserSettingsType['config'],
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  dispatch({ type: USER_SETTINGS_UPDATE });
  try {
    const res = await UserSettings.put(id, { config });
    dispatch({ type: USER_SETTINGS_UPDATE_COMPLETED, payload: res.data });
  } catch (e) {
    dispatch({ type: USER_SETTINGS_UPDATE_FAILED, payload: 'UserSettings update failed' });
  }
};
