import _ from 'lodash';
import { AlgorithmType } from 'utils/copilotAPI';
import { RESULTS_LIMIT, SELECT_ALL_OPTION } from 'constantsBase';
import useFetcher, { PossibleStates } from 'utils/hooks/useFetcher';
import { camelize } from 'utils/formattingUtils';

export const formatAlgoTypeName = (algoType) => camelize(_.replace(algoType.name, /_/g, ' '));

export const useAlgoTypeFetcher = (hasSelectAll = false) => {
  const fetchAlgoTypes = async () => {
    const sort = 'id ASC';
    const where = {
      active: 1,
    };
    const algoTypes = await AlgorithmType.get({ where, limit: RESULTS_LIMIT, sort });
    const formattedData = _.map(algoTypes.data, (aT) => ({ ...aT, name: formatAlgoTypeName(aT) }));
    const data = hasSelectAll ? [SELECT_ALL_OPTION, ...formattedData] : formattedData;
    return { kind: PossibleStates.hasData, data };
  };

  const currentState = useFetcher(fetchAlgoTypes);
  const error = currentState.kind === PossibleStates.error ? 'Error fetching Algorithm Types' : null;
  return [_.get(currentState, 'data'), error];
};
