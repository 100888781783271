import _ from 'lodash';
import { useRef, useEffect } from 'react';

const useDeepCompareEffect = (callback, value) => {
  const ref = useRef();
  if (!_.isEqual(value, ref.current)) {
    ref.current = _.cloneDeep(value);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [ref.current]);
};

export default useDeepCompareEffect;
