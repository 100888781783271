// Fresh Desk documentation: https://developers.freshdesk.com/widget-api/
// @ts-ignore
// eslint-disable-next-line no-undef
export const WidgetWrapper = (...args: Array<any>) => FreshworksWidget(...args);

export const clearAllWidgetFieldsBesidesEmail = (email: string) => {
  WidgetWrapper('clear', 'ticketForm');
  WidgetWrapper('prefill', 'ticketForm', { email });
  WidgetWrapper('hide', 'ticketForm', ['email']);
};

export const initializeFreshworksWidget = (email: string) => {
  WidgetWrapper('prefill', 'ticketForm', { email });
  WidgetWrapper('hide', 'ticketForm', ['email']);
};

export const conditionallyShowFreshDeskWidget = () => {
  if (window.location.hash === '#support') {
    WidgetWrapper('open');
  }
};
