import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { ReactNode, useEffect } from 'react';
import qs from 'qs';
import { take, call, race } from 'redux-saga/effects';
import { AugmentedFlight, User } from 'utils/types';
import { FLIGHT_EXTERNAL_TYPE, DSP, BASE_URL_APN, XANDR_MONETIZE_BASE_URL, QA_GLOBAL_ROLE_ID } from 'constantsBase';
import { toSnakeCase } from './formattingUtils';

type StringOrArrayOfString = string | Array<string>;

type MessageParamsObj = {
  type: string,
  nameDetail: string,
  extId: string,
  link: string,
};

export const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);
export const isInRange = (n: number, lowerBound: number, upperBound: number) => (n >= lowerBound && n <= upperBound);

export const wrapCancelableSaga = (fn: Function, stopActions: StringOrArrayOfString) => (
  function* yieldRaceSaga(args: unknown): Generator {
    yield race({
      // @ts-ignore
      task: call(fn, args),
      cancel: take(stopActions),
    });
  }
);

export const wrapCancelableSagas = (arr: Array<[Function, StringOrArrayOfString, Function, StringOrArrayOfString]>) => (
  _.map(arr, ([triggerFn, triggerActions, fnToWrap, stopActions]) => (
    triggerFn(triggerActions, wrapCancelableSaga(fnToWrap, stopActions))
  ))
);

export const roundToNearest = (n: number, acc: number = 0) => {
  if (acc <= 0) {
    return n;
  }
  return Math.round(n / acc) * acc;
};

export const collect = (array: { [key: string]: any }, fn: Function) => (
  _.transform(array, (res: Array<unknown>, x: unknown) => res.push(fn(_.last(res) || 0, x)), [])
);

export const mapAndPick = (arrToMap: Array<unknown>, columnToPick: string | Array<string>) => (
  _.map(arrToMap, (elt) => _.pick(elt, columnToPick))
);

export const checkIfNumber = (rawNum: number | string) => {
  const num = Number(rawNum);
  return rawNum !== '' && rawNum !== null && isFinite(num) ? num : undefined;
};

export const numberOrUndefined = (obj: {} | number | string, fieldName?: string) => {
  if (_.isObject(obj)) {
    return checkIfNumber(_.get(obj, fieldName));
  }
  return checkIfNumber(obj as string | number);
};

export const arrayOrUndefined = (obj: {}, fieldName: string, sep: RegExp | string = /, ?/) => {
  const value = _.get(obj, fieldName);

  if (_.isEmpty(value)) {
    return undefined;
  }

  if (typeof value === 'string') {
    return value.split(sep);
  }
  if (Array.isArray(value)) {
    return value;
  }

  return undefined;
};

/**
 Adds missing days data for chart data, chart data should be in format array of array
 i.e. [[utc time stamp, value], [utc time stamp, value], [utc time stamp, value]]
 */
export const addMissingDaysChartData = (data: Array<any>, value: number = 0, dateRange: number = 7) => {
  let res;

  if (data.length === dateRange) {
    res = data;
  } else {
    const days = [];
    const today = moment.utc().startOf('date');
    for (let i = dateRange; i > 0; i -= 1) {
      days.push(moment.utc(today).subtract(i, 'days').valueOf());
    }

    const dataByDay = _.fromPairs(data);
    res = _.map(days, (day) => [day, dataByDay[day] || value]);
  }

  return _.sortBy(res, ([day]: Array<any>) => day);
};

/**
 * Convert a hex color to a rgb format, if the hex is short handed it will be expand before converted
 * @param rawHex the hex color
 * @returns an object {r, g, b} with red green blue value corresponding to the hex color
 */
export const hexToRgb = (rawHex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-fA-F\d])([a-fA-F\d])([a-fA-F\d])$/i;
  const hex = rawHex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);

  // Using regex to extract the red, green, and blue value and convert it in base 10 (hex is in base 16)
  const result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

/**
 * Split a 'r, g, b' string or a 'r,g,b' string into an object
 * @param str the rgb string
 * @returns an object {r, g, b} with red green blue value corresponding to the rgb string
 */
export const stringToRGB = (str: string) => {
  const rgb = str.split(/, ?/);
  const checkColor = (c: string | number) => {
    if (isNumeric(c)) {
      // @ts-ignore
      const color = parseInt(c, 10);
      return (color >= 0 && color <= 255);
    }
    return false;
  };
  return rgb.length === 3 && _.every(rgb, (color) => checkColor(color)) ? {
    r: parseInt(rgb[0], 10),
    g: parseInt(rgb[1], 10),
    b: parseInt(rgb[2], 10),
  } : null;
};

/**
* Checks valid s3 url
* @param s3Url string
* @returns true if valid url otherwise false
*/
export const isS3Url = (s3Url: string) => (
  s3Url ? (s3Url.startsWith('s3://') || s3Url.startsWith('s3a://') || s3Url.startsWith('s3n://')) : false
);

export const replaceAll = (str: string, search: string, replacement: string) => (
  str.replace(new RegExp(search, 'g'), replacement)
);

// TSX has a problem with some generic arrow functions so we *must* have extends so file will compile

// eslint-disable-next-line arrow-parens
export const uniqLast = <T extends any>(collection: Array<T>, comparator: (x: T, y: T) => boolean) => {
  const iter = (acc: Array<T>, value: T) => {
    const last = _.last(acc);
    if (last && comparator(last, value)) {
      acc.pop();
      acc.push(value);
      return acc;
    }
    acc.push(value);
    return acc;
  };
  return _.reduce(collection, iter, []);
};

// eslint-disable-next-line arrow-parens
export const uniqFirst = <T extends any>(collection: Array<T>, comparator: (l: T, r: T) => boolean) => {
  const iter = (acc: Array<T>, value) => {
    const last = _.last(acc);
    if (last && comparator(last, value)) {
      return acc;
    }
    acc.push(value);
    return acc;
  };
  return _.reduce(collection, iter, []);
};

export const objectPathsEqual = <T extends any>(paths: Array<[string, (l: T, r: T) => boolean]>) => (l: {}, r: {}) => {
  const pathEqualities = _.map(paths, ([path, comp]) => comp(_.get(l, path), _.get(r, path)));
  return _.every(pathEqualities);
};

export const objectPathArraysEqual = (paths: Array<string>) => (l: Array<unknown>, r: Array<unknown>) => {
  if (l.length !== r.length) {
    return false;
  }
  const toCompare = _.zip(l, r);
  const pathEqualities = _.map(toCompare, ([obj1, obj2]) => (
    _.map(paths, (path) => _.isEqual(_.get(obj1, path), _.get(obj2, path)))
  ));
  return _.every(_.flatten(pathEqualities));
};

export const generateDayRange = (dateRange: number, endDate: Moment | null) => {
  let end: Moment;
  if (endDate != null) {
    // to ensure both start and end dates are included we add a day to end date
    end = moment.utc(endDate).startOf('date').add(1, 'days');
  } else {
    end = moment.utc().startOf('date');
  }
  return _.map<number, number>(_.range(dateRange, 0), (i) => end.clone().subtract(i, 'days').valueOf());
};

export const mapKeysDeep = (obj: {}, fn: Function) => {
  const mapped = {};

  _.forOwn(obj, (v, k) => {
    let value: {} = v;
    if (_.isPlainObject(value)) {
      value = mapKeysDeep(value, fn);
    }
    mapped[fn(value, k)] = value;
  });
  return mapped;
};

export const transformKeyOfObjWithFn = (o: {}, fn: Function) => (
  _.isPlainObject(o)
    ? _(o)
      .map((value, key) => {
        const newKey = fn(key);
        if (Array.isArray(value)) {
          return [newKey, _.map(value, (v) => transformKeyOfObjWithFn(v, fn))];
        } if (_.isPlainObject(value)) {
          return [newKey, transformKeyOfObjWithFn(value, fn)];
        }
        return [newKey, value];
      })
      .fromPairs()
      .value()
    : o
);

export const selectiveEqual = (left: {}, right: {}, paths: Array<string> = []) => {
  if (_.isEmpty(paths)) {
    return _.isEqual(left, right);
  }
  const pathsArray = _.isArray(paths) ? paths : [paths];
  const comps = _.map(pathsArray, (path) => _.isEqual(_.get(left, path), _.get(right, path)));
  return _.every(comps);
};

export const sliceByDate = (arr: Array<{ day: number }>, dayMax: number) => {
  const index = _.findIndex(arr, (v) => v.day >= dayMax);
  if (index === -1) {
    return [];
  }

  return _.slice(arr, index);
};

const flattenBuildArrayKey = (key, i) => `${key}[${i}]`;
const flattenBuildKey = (prefix, key) => (prefix ? `${prefix}.${key}` : key);
/**
 * flatten an objects keys to fit the form:
 * - flatArray false: { x: { y: { z: 1 } }, y: [1, 2, 3] } -> { 'x.y.z': 1, y: [1, 2, 3] }
 * - flatArray true: { x: { y: { z: 1 } }, y: [1, 2, 3] } -> { 'x.y.z': 1, y[0]: 1, y[0]: 2, y[0]: 3 }
 * useful when dealing with lodash _.get and _.set methods
 * @param {object} object
 * @param {optional bool}  flatArray - flag to know if the algorithm has to flatten array or not
 * @param {optional string} prefix - used for recursion
 * @param {optional string} ret used for recursion
 *@return {object} flat object with string keys
 */
/* eslint-disable no-param-reassign */
export function flatten(object: {}, flatArray: boolean = false, prefix: string = '', ret: {} = {}) {
  const parentToClientRevenueValue = 'parentToClientRevenueValue';
  _.forEach(object, (e, key) => {
    // parentToClientRevenueValue is specific to cross-platform strats when there is a rev enabled goal
    if (e && typeof e === 'object' && !_.includes([parentToClientRevenueValue, toSnakeCase(parentToClientRevenueValue)], key)) {
      if (flatArray && Array.isArray(e)) {
        _.each(e, (elt, i) => {
          const k = flattenBuildKey(prefix, flattenBuildArrayKey(key, i));
          if (elt && typeof elt !== 'object') {
            ret[k] = elt;
          } else {
            flatten(elt, flatArray, k, ret);
          }
        });
      } else if (_.isPlainObject(e)) {
        flatten(e, flatArray, flattenBuildKey(prefix, key), ret);
      } else {
        ret[flattenBuildKey(prefix, key)] = e;
      }
    } else {
      ret[flattenBuildKey(prefix, key)] = e;
    }
  });
  return ret;
}
/* eslint-enable */

export const checkDuplicate = (arr: Array<unknown>) => {
  const duplicates = {};

  arr.forEach((v, i) => {
    // @ts-ignore
    if (duplicates[v]) {
      // @ts-ignore
      duplicates[v].push(i);
    } else if (arr.lastIndexOf(v) !== i) {
      // @ts-ignore
      duplicates[v] = [i];
    }
  });

  return duplicates;
};

// Eslint vs Flowtype bug: https://github.com/gajus/eslint-plugin-flowtype/issues/344
// eslint-disable-next-line arrow-parens
export const cancelablePromise = <T extends any>(promise: Promise<T>) => {
  let hasCanceled = false;
  let isRunning = true;
  const promiseEndCheckCancelApplyCallback = (params, cb) => {
    isRunning = false;
    return hasCanceled ? undefined : cb(params);
  };

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((res) => promiseEndCheckCancelApplyCallback(res, resolve));
    promise.catch((err) => promiseEndCheckCancelApplyCallback(err, reject));
  });

  return {
    isRunning,
    promise: wrappedPromise,
    cancel: () => {
      hasCanceled = true;
      isRunning = false;
    },
  };
};

/**
 * when given a combining function (e.g., addition) and a list of objects,
 * accumulate the results of applying the function to all objects in the list
 *
 * essentially cumulativeSum, but could be used for whatever
 *
 * @param addFn
 * @param data
 */
export const accumulate = (addFn: Function, data: Array<Object>) => {
  const acc = (result, value) => {
    if (_.isEmpty(result)) {
      result.push(value);
    } else {
      result.push(addFn(_.last(result), value));
    }
    return result;
  };
  return _.reduce(data, acc, []);
};

// Order the flights from the api response by the order of the incoming ids.
// This probably doesn't matter all that much, but good to keep it consistent.
export const orderFlights = (flightExtIds: Array<string>, flights: Array<AugmentedFlight>): Array<AugmentedFlight> => {
  const lookup = _.keyBy(flights, 'externalId');
  return _.map(flightExtIds, (extId) => _.get(lookup, extId));
};

// handles situations where you need to parse values like 'true' and 'false', ie, when reading from a query string
export const coerceBoolStringToBool = (value: any) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
};

const decoder = (str: string, defaultDecoder: qs.defaultDecoder) => {
  const coerced = coerceBoolStringToBool(str);
  if (_.isBoolean(coerced)) {
    return coerced;
  }
  return defaultDecoder(str);
};

export const withQueryString = (InnerComponent: React.ComponentType<any>): React.ComponentType<any> => {
  const inner = (props) => {
    const queryString = qs.parse(props.router.location.search, { ignoreQueryPrefix: true, decoder });
    return <InnerComponent qsParams={queryString} {...props} />;
  };
  return inner;
};

// 'bmw' is an acronym used by Xandr. NOT the actual company BMW.
export const getDeepLinkForAPNCampaign = (id: string) => `${XANDR_MONETIZE_BASE_URL}/buyside/campaign:${id}/show`;
export const getDeepLinkForAPNLI = (id: string) => `${BASE_URL_APN}/bmw/line-item/${id}`;
export const getDeepLinkForAPNIO = (id: string) => (
  `${BASE_URL_APN}/bmw/insertion-order/${id}`
);

export const APNLink = (
  msg: string,
  link: string,
): ReactNode => (
  <a
    href={link}
    rel="noopener noreferrer"
    target="_blank"
  >{msg}
  </a>
);

const generateMessageString = (type: string, name: string, extId: string) => `${type} | ${name} | ${extId}`;
export const generateMessages = (messageParams?: Array<MessageParamsObj>) => (
  _.map(messageParams, ({ type, nameDetail, extId, link }) => (
    APNLink(generateMessageString(type, nameDetail, extId), link)
  ))
);

export const populateFlightHoverDetail = (flight: AugmentedFlight, flightExternalType: number, _advId: string): Array<ReactNode | string> => {
  let messageParams;

  const {
    insertionOrder: { name: ioName, externalId: ioExtId },
    name, externalId,
  } = flight;
  if (flightExternalType === FLIGHT_EXTERNAL_TYPE.apnCampaign.id) {
    const { lineItem: { name: lineItemName, externalId: lineItemExtId } } = flight;
    messageParams = [
      { type: 'IO', nameDetail: ioName, extId: ioExtId, link: getDeepLinkForAPNIO(ioExtId) },
      { type: 'LI', nameDetail: lineItemName, extId: lineItemExtId, link: getDeepLinkForAPNLI(lineItemExtId) },
      { type: 'Campaign', nameDetail: name, extId: externalId, link: getDeepLinkForAPNCampaign(externalId) },
    ];
  } else if (flightExternalType === FLIGHT_EXTERNAL_TYPE.apnLineItem.id) {
    messageParams = [
      { type: 'IO', nameDetail: ioName, extId: ioExtId, link: getDeepLinkForAPNIO(ioExtId) },
      { type: 'LI', nameDetail: name, extId: externalId, link: getDeepLinkForAPNLI(externalId) },
    ];
  }
  return generateMessages(messageParams);
};

/**
 * sleep for (ms) seconds. useful for imitating async calls.
 * usage: await sleep(1000) // sleep for one second
 */
// eslint-disable-next-line no-promise-executor-return
export const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * wraps useEffect so that it can be used with async functions. the callback passed to useEffect cannot return a
 * promise, so this is a workaround.
 * @param effect an async function which returns a promise
 * @param dependencies an array of dependencies which useEffect will take as it's second argument, for specifying which
 * props you want to watch for changes.
 * @param destroy clean up function which will be returned from useEffect. is provided with the promise returned from
 * effect() as an argument
 */
export const useAsyncEffect = (
  effect: () => Promise<void>,
  dependencies?: Array<unknown>,
  destroy?: (arg0: Promise<void>) => void,
) => {
  useEffect(() => { // eslint-disable-line consistent-return
    const promise = effect();
    if (_.isFunction(destroy)) {
      return destroy(promise);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

type Fn<A, B> = (a: A) => B;
type First = <A, B>(fn: Fn<A, B>) => <T>(tuple: [A, T]) => [B, T];
type Second = <A, B>(fn: Fn<A, B>) => <T>(tuple: [T, A]) => [T, B];

export const Bifunctor: { first: First, second: Second } = {
  first: (fn) => ([a, t]) => ([fn(a), t]),
  second: (fn) => ([t, a]) => ([t, fn(a)]),
};
// creates a LocationDescriptorObject which retains the queryString
export const createLinkWithQS = (pathname: string) => ({ pathname, search: window.location.search });

/**
 * if v is not an array or null/undefined, returns an array containing v
 * otherwise return the original v
 * @param v
 * @returns {*}
 */
export const makeArray = (v) => {
  if (_.isNil(v) || _.isArray(v)) {
    return v;
  }

  return [v];
};

export const isSystemAdmin = (user: User) => {
  if (!user) {
    return false;
  }
  const userRoles = _.map(user.roles, 'name');
  return _.includes(userRoles, 'admin');
};

export const isValidDSP = (dspId: number) => _.includes([DSP.APN.id, DSP.TTD.id, DSP.DBM.id, DSP.AMZN.id, DSP.WALMART.id], dspId);

export const searchByIdOrName = (search: string, nameKey = 'name'): ((obj: { name: string, id: string | number }) => boolean) => {
  const regex = new RegExp(search, 'i');
  return (obj) => {
    const { id } = obj;
    const name = obj[nameKey];
    return Boolean(name.match(regex)) || Boolean(_.toString(id).match(regex));
  };
};

export const searchByIdExtIdOrName = (search: string, nameKey = 'name'): ((obj: { name: string, id: string | number, externalId: string | number }) => boolean) => {
  const regex = new RegExp(search, 'i');
  return (obj) => {
    const { id, externalId } = obj;
    const name = obj[nameKey];
    return Boolean(name.match(regex)) || Boolean(_.toString(id).match(regex)) || Boolean(_.toString(externalId).match(regex));
  };
};

export const truncateFilename = (filename: string, maxLimit: number) => _.truncate(filename, { length: maxLimit });

export const isQAGlobalReadOnly = (user) => {
  if (!user) {
    return false;
  }
  return _.includes(_.map(user.roles, 'id'), QA_GLOBAL_ROLE_ID);
};

export const isAdminOrQAGlobal = (user: User) => {
  if (!user) {
    return false;
  }
  return isSystemAdmin(user) || isQAGlobalReadOnly(user);
};

export const isSupportL1 = (user: User): boolean => _.some(user.roles, (role: { name: string }) => role.name === 'Support L1');
