const userGroupPageStyles = {
  flightSplitHeader: {
    fontWeight: 500,
    color: '#1a3849',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  newColBox: {
    color: '#1a3849',
    fontWeight: '500',
    width: '76px',
    padding: '14px 0',
    textAlign: 'center',
  },
};

export default userGroupPageStyles;
