import _ from 'lodash';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { Effect } from 'redux-saga';
import { fetchMembers, fetchAdvertisers, fetchFlights } from 'utils/sharedSagas';
import { Microservices } from 'utils/copilotAPI';
import {
  FETCH_MEMBERS, FETCH_ADVERTISERS, FETCH_FLIGHTS, FLIGHT_DROPDOWN_LIMIT,
  CHECK_USER_GROUPS, CHECK_USER_GROUPS_SUCCEEDED, CHECK_USER_GROUPS_FAILED, SET_USER_GROUPS, SET_USER_GROUPS_SUCCEEDED,
  SET_USER_GROUPS_FAILED, REMOVE_USER_GROUPS, REMOVE_USER_GROUPS_SUCCEEDED, REMOVE_USER_GROUPS_FAILED,
} from './constants';
import {
  fetchMembersSucceeded, fetchMembersFailed, fetchAdvertisersSucceeded, fetchAdvertisersFailed,
  fetchFlightsSucceeded, fetchFlightsFailed,
} from './actions';

const onMemberPermissionError = () => fetchMembersFailed('You do not have permission to view any seats');
const onMemberFetchFailed = () => fetchMembersFailed('Failed to fetch members');
const onAdvFetchFailed = () => fetchAdvertisersFailed('Failed to fetch advertisers');

function getMsRequest(action, service) {
  const body = _.mapKeys(action.payload, (_v, k) => _.snakeCase(k));
  return _.partial(Microservices.runService, body, service);
}

function* userGroupCheckSaga(action) {
  try {
    const req = getMsRequest(action, 'check_user_groups');
    const res = yield call(req);
    yield put({ type: CHECK_USER_GROUPS_SUCCEEDED, payload: res.data });
  } catch (e) {
    yield put({ type: CHECK_USER_GROUPS_FAILED, payload: e });
  }
}

function* userGroupSetSaga(action) {
  try {
    const req = getMsRequest(action, 'set_user_groups');
    const res = yield call(req);
    yield put({ type: SET_USER_GROUPS_SUCCEEDED, payload: res.data });
  } catch (e) {
    yield put({ type: SET_USER_GROUPS_FAILED, payload: e });
  }
}

function* userGroupRemoveSaga(action) {
  try {
    const req = getMsRequest(action, 'remove_user_groups');
    const res = yield call(req);
    yield put({ type: REMOVE_USER_GROUPS_SUCCEEDED, payload: res.data });
  } catch (e) {
    yield put({ type: REMOVE_USER_GROUPS_FAILED, payload: e });
  }
}

function* userGroupSagas(): Iterable<Effect> {
  yield all([
    takeLatest(FETCH_MEMBERS, fetchMembers, onMemberPermissionError, fetchMembersSucceeded, onMemberFetchFailed),
    takeLatest(FETCH_ADVERTISERS, fetchAdvertisers, fetchAdvertisersSucceeded, onAdvFetchFailed),
    // @ts-ignore redux-saga fetchFlights
    takeLatest(
      FETCH_FLIGHTS,
      fetchFlights,
      fetchFlightsSucceeded,
      fetchFlightsFailed,
      FLIGHT_DROPDOWN_LIMIT,
    ),
    takeLatest(CHECK_USER_GROUPS, userGroupCheckSaga),
    takeLatest(SET_USER_GROUPS, userGroupSetSaga),
    takeLatest(REMOVE_USER_GROUPS, userGroupRemoveSaga),
  ]);
}

export default userGroupSagas;
