import { call, put, takeLatest, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { ViewUser } from 'utils/copilotAPI';
import { RESULTS_LIMIT } from 'constantsBase';
import { USERS_FETCH, USERS_FETCH_COMPLETED, USERS_FETCH_FAILED } from './constants';

export function* getUsersSaga({ payload: { where = {}, limit = RESULTS_LIMIT, skip = 0 } } : AnyAction) {
  try {
    const [usersCount, users] = yield all([
      call(ViewUser.count, { where }),
      call(
        ViewUser.get,
        {
          where,
          limit,
          skip,
          sort: 'id desc',
        },
      ),
    ]);
    yield put({
      type: USERS_FETCH_COMPLETED,
      payload: {
        users: users.data,
        usersCount: usersCount.data.count,
      },
    });
  } catch (error) {
    yield put({ type: USERS_FETCH_FAILED, error });
  }
}

export const sagas = [takeLatest(USERS_FETCH, getUsersSaga)];

export function* usersSagas() {
  yield all([
    ...sagas,
  ]);
}

export default usersSagas;
