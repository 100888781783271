// @flow
import React from 'react';
import { Grid, Button, Form } from 'buildingBlocks';
import { ButtonClickHandler } from 'utils/types';

type Props = {
  isConfirmationPage: boolean,
  edit: ButtonClickHandler,
  reset: ButtonClickHandler,
  loading: boolean,
  isDisabled: boolean,
  pristine: boolean,
  handleUserGroupSubmit: ButtonClickHandler,
};

const FormButtons = ({ isConfirmationPage, edit, reset, loading, isDisabled, handleUserGroupSubmit, pristine }: Props) => (
  <Grid.Row style={{ marginBottom: '1.1rem' }}>
    <Grid.Column>
      <Form.Group widths="equal">
        <Form.Field style={{ textAlign: 'right' }}>
          {!isConfirmationPage ? (
            <Button
              primary
              loading={loading}
              content="Submit"
              disabled={isDisabled}
              onClick={handleUserGroupSubmit}
            />
          ) : (
            <Button
              content="Edit"
              disabled={isDisabled}
              onClick={edit}
            />
          )}
        </Form.Field>
        <Form.Field style={{ textAlign: 'left' }}>
          <Button
            content={!isConfirmationPage ? 'Clear' : 'Done'}
            disabled={pristine}
            onClick={reset}
          />
        </Form.Field>
      </Form.Group>
    </Grid.Column>
  </Grid.Row>
);

export default FormButtons;
