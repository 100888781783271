import { CSSProperties } from 'react';
import { COPILOT_COLORS } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;

export const mainContainer: CSSProperties = {
  margin: 0,
  backgroundColor: NEUTRALS.N0_WHITE,
};

export const usersPageHeaderContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
};
