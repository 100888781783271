import React from 'react';
import _ from 'lodash';
import { ObjectMultiDropdown, Form, WppGrid, WppTypography } from 'buildingBlocks';
import userGroupPageStyles from 'containers/UserGroups/style';
import RowHeader from './RowHeader';
import { ACTIONS } from '../constants';

const { setUserGroupsStyle } = userGroupPageStyles;

type FlightSelectWithSplitsProps = {
  selected: Array<unknown>,
  renderSelectionList: Function,
  selectedActionType: string,
  hasErrors: boolean,
  isDisabled: boolean,
  meta: {
    error: string | null,
  },
};
const FlightSelectWithSplits = ({
  selected,
  renderSelectionList,
  selectedActionType,
  hasErrors,
  isDisabled,
  meta,
  ...rest
}: FlightSelectWithSplitsProps) => (
  <>
    <WppGrid item all={24}>
      <Form.Field disabled={isDisabled}>
        {/* @ts-ignore - semantic-ui-react */}
        <ObjectMultiDropdown
          fluid
          selection
          scrolling
          disableRemoveItem
          renderLabel={() => undefined}
          {..._.omit(rest, 'renderSelectionList', 'selectedActionType', 'hasErrors', 'isDisabled')}
        />
        <WppTypography type="xs-midi" style={setUserGroupsStyle.selectedActionTypeText}>
          {`Select ${_.isEqual(selectedActionType, ACTIONS.apply.value) ? '2' : '1'} to 5 DSP Objects`}
        </WppTypography>
        {selected.length > 0 && (
          <WppTypography type="s-strong" style={setUserGroupsStyle.errorMessageStyle}>
            {meta.error}
          </WppTypography>
        )}
      </Form.Field>
      {_.size(selected) > 0 && <RowHeader hasErrors={hasErrors} selectedActionType={selectedActionType} />}
    </WppGrid>
    {renderSelectionList(selected)}
  </>
);

export default FlightSelectWithSplits;
