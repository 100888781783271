import { FramedAppMethods, FramedAppParentMethods, OsContext, OsContextApi } from '@wppopen/core';
import { connectToParent, Methods } from 'penpal';
import { useState } from 'react';
import { isLoadedThroughWppOs } from 'utils/environmentUtils';
import { useMount } from './generic/hookWrappers';

const connectToOsApi = async (
  setFetchContextComplete: ((x: boolean) => void),
  setOsContext: (x: OsContext) => void,
  setOsApi: (x: OsContextApi) => void,
) => {
  const methods: Methods = {
    // function to receive context from wpp os
    receiveOsContext: (osContext) => setOsContext(osContext),
  } satisfies FramedAppMethods;
  try {
    await connectToParent<FramedAppParentMethods>({
      parentOrigin: '*',
      methods,
      debug: true,
    }).promise.then(({ osApi }) => {
      // @ts-expect-error due to types mismatch
      setOsApi(osApi);
      setFetchContextComplete(true);
    });
  } catch (error) {
    console.error('Penpal child context error:', error);
    setFetchContextComplete(true);
  }
};

const useWppOsContext = () => {
  const [fetchContextComplete, setFetchContextComplete] = useState<boolean>(false);
  const [osContext, setOsContext] = useState<OsContext>(null);
  const [osApi, setOsApi] = useState<OsContextApi>(null);
  const loadedThroughWppOS = isLoadedThroughWppOs();

  useMount(() => {
    // only connect to the context if UI is loaded through wpp os
    if (loadedThroughWppOS) {
      connectToOsApi(setFetchContextComplete, setOsContext, setOsApi);
    } else {
      setFetchContextComplete(true);
    }
  });

  return { fetchContextComplete, osContext, osApi };
};

export default useWppOsContext;
