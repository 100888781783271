import _ from 'lodash';
import { SetStateAction } from 'react';
import { Dispatch } from 'redux';
import moment from 'moment';
import { RESULTS_LIMIT } from 'constantsBase';
import { Member, Role, UserRole, UserRoleApprover, Users, ViewUser } from 'utils/copilotAPI';
import { SingleMemberRole, User } from 'utils/types';
import { LOGIN_USER_UPDATE_SUCCEEDED } from 'containers/Login/constants';
import {
  USER_CREATE,
  RESET_ALL,
  UPDATE_MEMBER_ROLES,
  UPDATE_USER,
  VALIDATING_USER,
  USER_VALIDATION,
  SAVE_APPROVER,
  MEMBERS_FETCH_FAILED,
  MEMBERS_FETCH_SUCCEEDED,
  ROLES_FETCH_SUCCEEDED,
  ROLES_FETCH_FAILED,
  MEMBER_ROLES_MAP_FETCH_SUCCEEDED,
  MEMBER_ROLES_MAP_FETCH_FAILED,
  USER_ROLE_FETCH_SUCCEEDED,
  USER_FETCH_FAILED,
  USER_STATUS_MSG,
  USER_SUCCESS_MSG,
  USER_UPDATE_FAILED,
  USER_MEMBER_ROLE_CREATE_SUCCEEDED,
  MEMBER_ROLE_DELETE_SUCCEEDED,
  USER_CREATE_FAILED,
  USER_CREATE_SUCCEEDED,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_FAILURE,
} from './constants';

export function resetAll() {
  return {
    type: RESET_ALL,
  };
}

export function updateMemberRoles({ memberRolesToSave, memberRoles, memberRolesToDelete }: { memberRolesToSave: Array<SingleMemberRole>, memberRoles: Array<SingleMemberRole>, memberRolesToDelete: Array<SingleMemberRole> }) {
  return {
    type: UPDATE_MEMBER_ROLES,
    payload: { memberRoles, memberRolesToSave, memberRolesToDelete },
  };
}

export const requestAccess = async (user: User, approver: string, requestNotes: string, dispatch: Dispatch<SetStateAction<any>>) => {
  try {
    const approverObj = await ViewUser.get({ email: approver, limit: 1 });
    await Users.sendRequestAccessEmail({ user, approver: _.head(approverObj.data), requestNotes });
    dispatch({ type: EMAIL_SENT_SUCCESS });
  } catch (e) {
    console.error(e);
    dispatch({ type: EMAIL_SENT_FAILURE, payload: { error: e.response.data } });
  }
};

export const validatingUser = () => ({
  type: VALIDATING_USER,
});

export const userValidation = (isValid: boolean) => ({
  type: USER_VALIDATION,
  payload: { isValid },
});

export const saveApprover = (userId: number, approver: string) => ({
  type: SAVE_APPROVER,
  payload: { userId, approver },
});

export const fetchMembers = async () => {
  try {
    const { data } = await Member.getMembersBasedOnUserRoles({ populate: ['region'], limit: RESULTS_LIMIT });
    return { type: MEMBERS_FETCH_SUCCEEDED, payload: data };
  } catch (e) {
    return { type: MEMBERS_FETCH_FAILED, payload: 'Failed to fetch seats' };
  }
};

export const fetchRoles = async () => {
  try {
    const { data } = await Role.getRolesBasedOnUserRoles();
    return { type: ROLES_FETCH_SUCCEEDED, payload: data };
  } catch (e) {
    return { type: ROLES_FETCH_FAILED, payload: 'Failed to fetch roles' };
  }
};

const fetchMemberRoleMapping = async () => {
  try {
    const { data } = await UserRole.getMembersAndRolesMapping();
    return { type: MEMBER_ROLES_MAP_FETCH_SUCCEEDED, payload: data };
  } catch (e) {
    return { type: MEMBER_ROLES_MAP_FETCH_FAILED, payload: 'Failed to fetch members and roles mapping' };
  }
};

// eslint-disable-next-line consistent-return
const fetchUserRoles = async (userId: number, hasPermissionToFetchRoles: boolean) => {
  try {
    if (!_.isNil(userId)) {
      const { data } = await ViewUser.get({ id: userId, limit: 1, populate: ['approver'] });
      const user = _.head(data);
      let userResponse = { user, approver: _.get(user, 'approver.email'), memberRoles: [] };
      if (hasPermissionToFetchRoles) {
        const userRoleMapping = await UserRole.get({
          where: { user: userId },
          limit: RESULTS_LIMIT,
          populate: ['member', 'role'],
        });
        userResponse = { ...userResponse, memberRoles: userRoleMapping.data };
      }
      return { type: USER_ROLE_FETCH_SUCCEEDED, payload: userResponse };
    }
  } catch (e) {
    return { type: USER_FETCH_FAILED, payload: 'User fetch failed' };
  }
};

export const getUser = async (userId: number, hasPermissionToFetchRoles: boolean, dispatch: Dispatch<SetStateAction<any>>) => {
  const results = await Promise.all([fetchMembers(), fetchRoles(), fetchMemberRoleMapping(), fetchUserRoles(userId, hasPermissionToFetchRoles)]);
  _.forEach(results, dispatch);
};

const createUserRole = (memberRoles: Array<SingleMemberRole>, userId: any, dispatch: Dispatch<SetStateAction<any>>) => {
  const userRoles = _.map(
    memberRoles,
    (memberRole) => UserRole.post({
      user: parseInt(userId, 10),
      member: !_.isUndefined(memberRole.member) ? memberRole.member.id : undefined,
      role: memberRole.role.id,
    }),
  );
  dispatch({ type: USER_MEMBER_ROLE_CREATE_SUCCEEDED, payload: userRoles });
};

const deleteUserMemberRole = (memberRoles: Array<SingleMemberRole>, userId: string, dispatch: Dispatch<SetStateAction<any>>) => {
  const userRoles = _.map(
    memberRoles,
    (memberRole) => UserRole.delete(_.toString(memberRole.id), {
      user: parseInt(userId, 10),
      member: !_.isUndefined(memberRole.member) ? memberRole.member.id : undefined,
      role: memberRole.role.id,
    }),
  );
  dispatch({ type: MEMBER_ROLE_DELETE_SUCCEEDED, payload: userRoles });
};

export const updateUser = async (
  userId: string,
  firstName: string,
  lastName: string,
  memberRolesToSave: Array<SingleMemberRole>,
  memberRolesToDelete: Array<SingleMemberRole>,
  approver: string,
  requestNotes: string,
  isNewUser: boolean,
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  dispatch({ type: UPDATE_USER });
  try {
    const user = await Users.put(userId, { firstName, lastName });
    const approverObj = await ViewUser.get({ email: approver, limit: 1 });
    const approverId = _.get(_.head(approverObj.data), 'id');
    const userApproverParams = { user: userId, approver: approverId };

    const userRoleApproverResp = await UserRoleApprover.get({ user: userId, limit: 1 });
    const userRoleApprover = _.head(userRoleApproverResp.data);

    const userRoleApproverRecordId = _.get(userRoleApprover, 'id');
    if (userRoleApproverRecordId) {
      await UserRoleApprover.put(userRoleApproverRecordId, userApproverParams);
    } else {
      await UserRoleApprover.post(userApproverParams);
    }
    dispatch({ type: LOGIN_USER_UPDATE_SUCCEEDED, payload: approver });
    if (!_.isEmpty(memberRolesToSave)) {
      createUserRole(memberRolesToSave, userId, dispatch);
    }
    if (!_.isEmpty(memberRolesToDelete)) {
      deleteUserMemberRole(memberRolesToDelete, userId, dispatch);
    }
    if (isNewUser) {
      await Users.sendRequestAccessEmail({ user: user.data, approver: _.head(approverObj.data), requestNotes });
    }
    const approvedMembers = _.map(memberRolesToSave, (mR) => _.omit(mR.member, 'content'));
    if (!_.isEmpty(approvedMembers)) {
      await Users.sendAccessApprovedEmail({ user: user.data, approver: _.head(approverObj.data), approvedMembers });
    }
    dispatch({ type: USER_STATUS_MSG, message: USER_SUCCESS_MSG });
  } catch (e) {
    dispatch({ type: USER_UPDATE_FAILED, payload: 'User update failed' });
  }
};

const createUser = async (firstName: string, lastName: string, email: string, memberRolesToSave: Array<SingleMemberRole>, approver: string, requestNotes: string, dispatch: Dispatch<SetStateAction<any>>) => {
  const lastLogin = moment.utc().toString();
  const isEnabled = true;
  const user = await Users.post({
    firstName, lastName, email, lastLogin, isEnabled,
  });
  const userId = _.get(user.data, 'id');
  const approverObj = await ViewUser.get({ email: approver, limit: 1 });
  const approverId = _.get(_.head(approverObj.data), 'id');
  const userApprover = await UserRoleApprover.post({ user: userId, approver: approverId });

  dispatch({ type: USER_CREATE_SUCCEEDED, payload: { ...user, approver, userApprover: userApprover.data } });
  createUserRole(memberRolesToSave, user.data.id, dispatch);
  dispatch({ type: USER_STATUS_MSG, message: USER_SUCCESS_MSG });
  await Users.sendRequestAccessEmail({ user: user.data, approver: _.head(approverObj.data), requestNotes });
};

export const checkAndCreateUser = async (firstName: string, lastName: string, email: string, memberRolesToSave: Array<SingleMemberRole>, approver: string, requestNotes: string, dispatch: Dispatch<SetStateAction<any>>) => {
  dispatch({ type: USER_CREATE });
  try {
    const isUserExist = await Users.count({ where: { email } });
    if (isUserExist.data.count === 1) {
      dispatch({ type: USER_STATUS_MSG, message: 'User already exists.' });
    } else {
      createUser(firstName, lastName, email, memberRolesToSave, approver, requestNotes, dispatch);
    }
  } catch (e) {
    dispatch({ type: USER_CREATE_FAILED, payload: 'User create failed' });
  }
};

export const saveUser = (
  userId: number,
  firstName: string,
  lastName: string,
  email: string,
  memberRolesToSave: Array<SingleMemberRole>,
  memberRolesToDelete: Array<SingleMemberRole>,
  approver: string,
  requestNotes: string,
  isNewUser: boolean,
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  !userId
    ? checkAndCreateUser(firstName, lastName, email, memberRolesToSave, approver, requestNotes, dispatch)
    : updateUser(_.toString(userId), firstName, lastName, memberRolesToSave, memberRolesToDelete, approver, requestNotes, isNewUser, dispatch);
};
