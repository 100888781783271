import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { ButtonProps, WppButton, WppIconEdit, WppIconPlus, WppGrid, WppTypography } from 'buildingBlocks';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { Permission } from 'utils/featureFlags';
import { Mode } from 'containers/User/types';
import { GlobalState } from 'reducers';
import { isSystemAdmin } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { useMemberFetcher } from 'utils/hooks/useMemberFetcher';
import { buildKey } from 'utils/localstorage/utils';
import { MemberEmail, MemberEmailType, User, UserSettings as UserSettingType } from 'utils/types';
import DisplayTheme from './components/DisplayTheme';
import EmailNotifications from './components/EmailNotifications';
import MemberEmails from './components/MemberEmails';
import { PAGE_NAME } from './constants';
import { USER_SETTINGS } from './style';
import { updateUserSettings } from './utils';

const {
  pageHeader,
  saveUndoStyle,
  undoButtonStyle,
  memberShareWithTitleStyle,
  hideThemeToggle,
} = USER_SETTINGS;

export const themeSettingLSKey = 'theme-setting';

const SettingsPageHeader = ({ mode, setMode }: ButtonProps) => (
  <WppGrid container fullWidth>
    <WppGrid item all={24} style={pageHeader}>
      <WppTypography tag="h1" type="3xl-heading">
        {PAGE_NAME}
      </WppTypography>
      <WppButton
        variant="secondary"
        size="m"
        onClick={() => setMode(Mode.edit)}
        style={mode === Mode.edit || mode === Mode.create ? { display: 'none' } : {}}
      >
        <WppIconEdit slot="icon-start" /> Edit
      </WppButton>
    </WppGrid>
  </WppGrid>
);

const UserSettings = () => {
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const userSettings = useSelector<GlobalState>((state) => state.login.settings) as UserSettingType;
  const isAdmin = isSystemAdmin(user);
  const userId = user.id;

  const [userConfig, setUserConfig] = useState<UserSettingType['config']>(userSettings.config);
  const [screenTheme, setScreenTheme] = useState<string>(_.get(window.localStorage, buildKey(userId, themeSettingLSKey)));

  // MemberEmailType consists of objects and due its reference nature, setMemberEmailSelection will not always result in a rerender of the parent
  const [memberEmails, setMemberEmails] = useState<MemberEmailType>([]);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>(Mode.view);
  const dispatch = useDispatch();
  const timeZone = userConfig.timeZone;

  const methods = useForm({ mode: 'onChange' });
  const { control, getValues } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'memberEmails',
    shouldUnregister: false,
  });

  const memberEmailsForm = useWatch({ name: 'memberEmails', control });
  const membersWithSharedNotifications = _.map(memberEmailsForm, 'member.id');
  const [members, memberFetchError] = useMemberFetcher(user);
  const options = getOptionsWithDspIcon(_.filter(members, (m) => !_.includes(membersWithSharedNotifications, m.id)));

  const disabled = mode === Mode.view;

  useMount(() => {
    const myKey = buildKey(userId, themeSettingLSKey);
    const currentClass = window.localStorage.getItem(myKey);
    setScreenTheme(currentClass);
  });

  useEffect(() => {
    if (!members) return;
    if (_.size(userConfig.sharedNotifications) > 0) {
      const memberIds = _.keys(userConfig.sharedNotifications).map((key) => +key);
      const selectedMembers = _.filter(members, (o) => memberIds.includes(o.id));
      const memberEmailsFromDB = [];
      _.forEach(userConfig.sharedNotifications, (value, key) => {
        const mem = _.find(selectedMembers, (o) => o.id === +key);
        if (mem) {
          memberEmailsFromDB.push({ member: mem, emails: value, invalidEmails: new Set<string>() });
        }
      });
      const orderedMemberEmailsFromDB = _.orderBy(memberEmailsFromDB, (o) => o.member.displayName.toLowerCase(), ['asc']);
      append(orderedMemberEmailsFromDB);
      setMemberEmails(orderedMemberEmailsFromDB);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, userConfig.sharedNotifications]);

  const lastMemberEmail: MemberEmail = _.last(memberEmailsForm);
  const sharedNotificationsError = (!disabled && lastMemberEmail && (!lastMemberEmail?.member || !lastMemberEmail?.emails.length));
  const addButtonDisabled = disabled || sharedNotificationsError || emailError;

  const saveUserSettings = (key: string, value: string | boolean) => {
    const mergedConfig = _.merge(userConfig, { [key]: value });
    setUserConfig({ ...mergedConfig });
  };

  const updateSettings = () => {
    const memberNotificationEmails = {};
    // eslint-disable-next-line array-callback-return
    getValues().memberEmails.map((i) => {
      if (i.member && i.emails.length) {
        memberNotificationEmails[_.toString(i.member.id)] = i.emails;
      }
    });
    updateUserSettings(_.toString(userSettings.id), { ...userConfig, ...{ sharedNotifications: memberNotificationEmails } }, dispatch);

    // Theme
    const myKey = buildKey(userId, themeSettingLSKey);
    window.localStorage.setItem(myKey, screenTheme);
    document.documentElement.className = screenTheme;

    setMode(Mode.view);
  };

  const changeTheme = (theme: string | void) => {
    if (theme) {
      document.documentElement.className = theme;
    } else {
      const newTheme = screenTheme ? '' : 'dark';
      setScreenTheme(newTheme);
    }
  };

  const onCancel = () => {
    // TODO: too many complicated states built in - will address in re-write
    // easiest way to reset the form is to reload the page
    window.location.reload();
  };

  return (
    <FormProvider {...methods}>
      <WppPermissionPageTemplate
        user={user}
        permissions={Permission.adminUsers}
        title={PAGE_NAME}
        name={PAGE_NAME}
        customHeader={<SettingsPageHeader mode={mode} setMode={setMode} />}
      >
        <WppGrid container fullWidth>
          <WppGrid item all={24}>
            <EmailNotifications
              timeZoneSetting={timeZone}
              saveUserSetting={saveUserSettings}
              disabled={disabled}
            />
          </WppGrid>
          <WppGrid item all={24}>
            <WppTypography tag="p" type="m-strong">
              Share Notifications
            </WppTypography>
            {!!fields.length && (
              <>
                <WppGrid container fullWidth style={memberShareWithTitleStyle}>
                  <WppGrid item all={6}>
                    <WppTypography tag="p" type="s-strong">
                      Member
                    </WppTypography>
                  </WppGrid>
                  <WppGrid item all={18}>
                    <WppTypography tag="p" type="s-strong">
                      Share with
                    </WppTypography>
                  </WppGrid>
                </WppGrid>
                {fields.map((_item, index) => (
                  <MemberEmails
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    index={index}
                    options={options}
                    memberEmails={memberEmails}
                    setMemberEmails={setMemberEmails}
                    setEmailError={setEmailError}
                    remove={remove}
                    disabled={!!memberFetchError || disabled}
                  />
                ))}
              </>
            )}
          </WppGrid>
          <WppGrid item all={24}>
            <WppButton
              variant="secondary"
              size="s"
              disabled={addButtonDisabled}
              onClick={() => {
                append({ member: null, emails: null, invalidEmails: new Set<string>() });
                setMemberEmails(getValues('memberEmails'));
              }}
            >
              <WppIconPlus slot="icon-start" /> Add
            </WppButton>
          </WppGrid>
          {isAdmin && (
            <WppGrid item all={24} style={hideThemeToggle}>
              <DisplayTheme
                screenTheme={screenTheme}
                changeTheme={changeTheme}
                disabled={disabled}
              />
            </WppGrid>
          )}
        </WppGrid>
      </WppPermissionPageTemplate>
      <WppGrid container>
        <WppGrid item all={24} style={saveUndoStyle}>
          <WppButton
            variant="secondary"
            size="m"
            disabled={disabled}
            onClick={onCancel}
            style={undoButtonStyle}
          >
            Cancel
          </WppButton>
          <WppButton
            variant="primary"
            size="m"
            disabled={addButtonDisabled}
            onClick={updateSettings}
          >
            Save
          </WppButton>
        </WppGrid>
      </WppGrid>
    </FormProvider>
  );
};

export default UserSettings;
