import _ from 'lodash';

export const getLocalStorage = (state) => state.localStorage;
export const getUserId = (state) => _.get(state, 'login.user.id');
export const buildKey = (userId, key) => `${userId}-${key}`;

export const getLocalStorageItem = (state, rawKey) => {
  const localStorage = getLocalStorage(state);
  const prefix = getUserId(state);

  return _.get(localStorage, buildKey(prefix, rawKey));
};
