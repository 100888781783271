import React from 'react';
import _ from 'lodash';
import { ObjectMultiDropdown, Grid, Form } from 'buildingBlocks';
import RowHeader from './RowHeader';
import { ACTIONS } from '../constants';

type FlightSelectWithSplitsProps = {
  selected: Array<unknown>,
  renderSelectionList: Function,
  selectedActionType: string,
  hasErrors: boolean,
  isDisabled: boolean,
  meta: {
    error: string | null,
  },
};
const FlightSelectWithSplits = (props: FlightSelectWithSplitsProps) => (
  <div>
    <Grid style={{ width: '85%' }}>
      <Grid.Row>
        <Grid.Column width={6}>
          <Form.Field disabled={props.isDisabled}>
            <label style={{ marginBottom: '10px' }}>
              {
                _.isEqual(props.selectedActionType, ACTIONS.apply.value)
                  ? 'Select 2 to 5 DSP Objects'
                  : 'Select 1 to 5 DSP Objects'
              }
            </label>
            {/*
              // @ts-ignore - semantic-ui-react */}
            <ObjectMultiDropdown
              fluid
              selection
              scrolling
              disableRemoveItem
              renderLabel={() => undefined}
              {..._.omit(props, 'renderSelectionList', 'selectedActionType', 'hasErrors', 'isDisabled')}
            />
            {props.selected.length > 0 && (
              <span style={{ display: 'block', marginTop: '8px', fontSize: '12px', color: '#9F3A38' }}>
                {props.meta.error}
              </span>
            )}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      {props.selected.length > 0
        && <RowHeader hasErrors={props.hasErrors} selectedActionType={props.selectedActionType} />}
    </Grid>
    <div style={{ marginTop: '10px' }}>
      {props.renderSelectionList(props.selected)}
    </div>
  </div>
);

export default FlightSelectWithSplits;
