/*
 *
 * UserGroups - main component of set user groups page
 *
 */
import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import _ from 'lodash';
import { Grid, Container, Form, Message } from 'buildingBlocks';
import { FetchFlightsAction } from 'components/DoubleFlightSelect/DoubleFlightSelect';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { Member, Advertiser, Flight, LoadingStates, User } from 'utils/types';
import { getStrategyMembers, Permission } from 'utils/featureFlags';
import { FLIGHT_EXTERNAL_TYPE, DSP } from 'constantsBase';
import MemberAndAdvertiser from 'components/RFMemberAndAdvertiser';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import strategyWizard from 'containers/StrategyWizard/styles';
import MiddleForm from './components/MiddleForm';
import FormButtons from './components/FormButtons';
import { setFlightExternalTypeString } from './utils';
import {
  fetchMembers, fetchAdvertisers, fetchFlights, resetForm, setUserGroupForm,
  checkUserGroupForm, editForm, removeUserGroupForm,
} from './actions';
import { FORM_NAME, PAGE_TITLE, LOADING_STATES, ACTIONS } from './constants';
import validate from './validate';

const { column, base } = strategyWizard;

type Props = {
  change: Function,
  fetchMembers: Function,
  fetchAdvertisers: Function,
  fetchFlights: FetchFlightsAction,
  user: User,
  members: Array<Member>,
  advertisers: Array<Advertiser>,
  selectedMember: Member,
  selectedAdvertiser: Advertiser,
  advertiserLoadingState: LoadingStates,
  selectedFlightType: number,
  selectedActionType: string,
  flights: Array<Flight>,
  invalid: boolean,
  reset: Function,
  loading: boolean,
  pristine: boolean,
  advertiserFetchError: string,
  memberFetchError: string,
  resetForm: Function,
  editForm: Function,
  selectedFlights: Array<Flight>,
  checkUserGroupForm: Function,
  setUserGroupForm: Function,
  removeUserGroupForm: Function,
  checkedUserGroupData: Array<unknown>,
  userGroupData: Array<unknown>,
  errorMsg: string | null,
};

export class UserGroupsTool extends Component<Props> {
  componentDidMount() {
    const members = getStrategyMembers(this.props.user);
    this.props.fetchMembers(members, [DSP.APN.id]);
  }

  /**
    For cases where a user has only one member assigned,
    automatically select that member for them (member field will be greyed out)
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.members.length === 1 && !prevProps.selectedMember) {
      this.props.change('member', this.props.members[0]);
      this.onSeatSelect(_.first(this.props.members));
    }
    /**
      Will make a call to check_user_group microservice. this will only get triggered if the
      selectedFlights prop changes and the length is between 2 and 5
     */
    if (!_.isEqual(prevProps.selectedFlights, this.props.selectedFlights)) {
      if (_.size(this.props.selectedFlights) >= 2 && _.size(this.props.selectedFlights) <= 5) {
        this.handleUserGroupCheck(this.props.selectedMember.externalId, this.props.selectedFlightType, this.props.selectedFlights);
      }
    }
  }

  handleUserGroupCheck = (selectedMember: string, selectedFlightType: number, selectedFlights: Array<unknown>) => {
    const fet = FLIGHT_EXTERNAL_TYPE.getById(selectedFlightType);
    this.props.checkUserGroupForm(
      selectedMember,
      _.map(selectedFlights, 'externalId'),
      setFlightExternalTypeString(fet.displayName),
    );
  };

  handleUserGroupSubmit = (selectedMember: string, selectedFlightType: number, selectedFlights: Array<unknown>) => {
    const fet = FLIGHT_EXTERNAL_TYPE.getById(selectedFlightType);
    const args = [
      selectedMember,
      _.map(selectedFlights, 'externalId'),
      setFlightExternalTypeString(fet.displayName),
    ];
    if (this.props.selectedActionType === ACTIONS.apply.value) {
      this.props.setUserGroupForm(...args);
    } else {
      this.props.removeUserGroupForm(...args);
    }
  };

  handleDismiss = (toDismiss: { externalId: string }) => {
    const filteredFlights = _.filter(this.props.selectedFlights, (item) => item.externalId !== toDismiss.externalId);
    this.props.change('selectedFlights', filteredFlights);
  };

  reset = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.reset();
    this.props.resetForm();
  };

  edit = () => {
    const { selectedMember, selectedFlightType, selectedFlights } = this.props;
    this.props.editForm();
    this.handleUserGroupCheck(selectedMember.externalId, selectedFlightType, selectedFlights);
  };

  onSeatSelect = (member: Member) => {
    this.props.change('advertiser', '');
    this.props.fetchAdvertisers(member.id);
  };

  onSearchChange = (searchTerm: string) => {
    this.props.fetchAdvertisers(this.props.selectedMember.id, searchTerm);
  };

  onAdvSelect = (advertiser: Advertiser) => {
    const { selectedMember, selectedFlightType } = this.props;
    this.clearFlightFields();
    this.props.fetchFlights(selectedMember.id, advertiser.id, selectedFlightType, undefined, false);
  };

  clearFlightFields = () => {
    this.props.change('selectedFlights', []);
  };

  isMemberAdvertiserEmpty = () => !this.props.selectedMember || !this.props.selectedAdvertiser;

  render() {
    const {
      members, advertisers, selectedMember, selectedAdvertiser, advertiserLoadingState, invalid, flights,
      advertiserFetchError, memberFetchError, selectedFlightType, selectedFlights, pristine,
      checkedUserGroupData, loading, userGroupData, errorMsg, selectedActionType,
    } = this.props;

    const isConfirmationPage = _.isEqual(loading, LOADING_STATES.success);
    return (
      <PermissionPageTemplate title={PAGE_TITLE} name={PAGE_TITLE} permissions={Permission.userGroups}>
        <Container>
          <div style={base}>
            <Container fluid>
              <Form>
                <Grid stackable style={{ margin: '0px 50px' }}>
                  <Grid.Row>
                    <Grid.Column style={column} width={12}>
                      <Form.Field disabled={isConfirmationPage}>
                        <MemberAndAdvertiser
                          members={members}
                          advertisers={advertisers}
                          prettyMembers={getOptionsWithDspIcon(members)}
                          selectedMember={selectedMember}
                          selectedAdvertiser={selectedAdvertiser}
                          memberFetchError={memberFetchError}
                          advertiserFetchError={advertiserFetchError}
                          loadingAdvertisers={_.isEqual(advertiserLoadingState, LOADING_STATES.loading)}
                          onSeatSelect={(_event, selected: Member) => this.onSeatSelect(selected)}
                          onSearchChange={(_event, value) => this.onSearchChange(value.searchQuery)}
                          onAdvSelect={(adv: Advertiser) => this.onAdvSelect(adv)}
                          onAdvClick={() => this.props.fetchAdvertisers(this.props.selectedMember.id)}
                          sectionHeaderProps={{
                            group: 'memberAndAdvertiser',
                            title: 'Member and Advertiser',
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  {
                    !this.isMemberAdvertiserEmpty() && (
                      <MiddleForm
                        selectedFlightType={selectedFlightType}
                        selectedActionType={selectedActionType}
                        selectedMember={selectedMember}
                        selectedAdvertiser={selectedAdvertiser}
                        flights={flights}
                        fetchFlights={this.props.fetchFlights}
                        clearFlightFields={() => this.clearFlightFields()}
                        handleDismiss={this.handleDismiss}
                        selectedFlights={selectedFlights}
                        checkedUserGroupData={checkedUserGroupData}
                        userGroupData={userGroupData}
                        isDisabled={isConfirmationPage}
                        changeAction={(action) => this.props.change('actionType', action)}
                      />
                    )
                  }
                  {!_.isNil(errorMsg) && (
                    <Grid.Row>
                      <Grid.Column style={column}>
                        <Message negative><p>{errorMsg}</p></Message>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <FormButtons
                    isConfirmationPage={isConfirmationPage}
                    edit={this.edit}
                    reset={this.reset}
                    loading={_.isEqual(loading, LOADING_STATES.loading)}
                    isDisabled={invalid}
                    pristine={pristine}
                    handleUserGroupSubmit={() => {
                      this.handleUserGroupSubmit(selectedMember.externalId, selectedFlightType, selectedFlights);
                    }}
                  />
                </Grid>
              </Form>
            </Container>
          </div>
        </Container>
      </PermissionPageTemplate>
    );
  }
}

const formSelector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  return {
    user: state.login.session.data.user,
    members: state.userGroups.members,
    advertisers: state.userGroups.advertisers,
    selectedMember: formSelector(state, 'member'),
    selectedAdvertiser: formSelector(state, 'advertiser'),
    memberFetchError: state.userGroups.memberFetchError,
    advertiserFetchError: state.userGroups.advertiserFetchError,
    flights: state.userGroups.flights,
    advertiserLoadingState: state.userGroups.advertiserLoadingState,
    selectedFlightType: formSelector(state, 'flightType'),
    selectedFlights: formSelector(state, 'selectedFlights'),
    selectedActionType: formSelector(state, 'actionType'),
    loading: state.userGroups.loading,
    selectedFields: state.userGroups.selectedFields,
    checkedUserGroupData: state.userGroups.checkedUserGroupData,
    userGroupData: state.userGroups.userGroupData,
    errorMsg: state.userGroups.errorMsg,
  };
}

export default connect(mapStateToProps, {
  fetchMembers,
  fetchAdvertisers,
  fetchFlights,
  resetForm,
  setUserGroupForm,
  checkUserGroupForm,
  removeUserGroupForm,
  editForm,
})(
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      flightType: FLIGHT_EXTERNAL_TYPE.apnLineItem.id,
      actionType: ACTIONS.apply.value,
    },
    validate,
    // @ts-ignore - redux
  })(UserGroupsTool),
);
