import _ from 'lodash';
import { STRATEGY_TYPE } from 'constantsBase';
import { Member, StrategyType, User } from 'utils/types';

type CustomMember = Member | string | Array<number>;

export const Permission = {
  stratSegRec: 'strat_seg_rec',
  stratHeliosSegRec: 'strat_helios_seg_rec',
  stratCustomTree: 'strat_custom_tree',
  stratHelios: 'strat_helios',
  stratAdvancedHelios: 'strat_advanced_helios',
  stratCustomBidList: 'strat_custom_bid_list',
  stratHeliosBidList: 'strat_helios_bid_list',
  stratAPNBudgetOptimization: 'strat_apn_budget_optimization',
  stratTTDBudgetOptimization: 'strat_ttd_budget_optimization',
  stratWMTBudgetOptimization: 'strat_wmt_budget_optimization',
  stratDBMBudgetOptimization: 'strat_dbm_budget_optimization',
  stratAMZNBudgetOptimization: 'strat_amzn_budget_optimization',
  stratCrossPlatformOptimization: 'strat_cross_platform_budget_optimization',
  stratDBMCustomSDF: 'strat_dbm_custom_sdf',
  manageUserRoles: 'manage_user_roles',
  adminUsers: 'admin_users',
  manageBrands: 'manage_brands',
  manageRoles: 'manage_roles',
  manageMembers: 'manage_members',
  manageMessages: 'manage_messages',
  manageJobs: 'manage_jobs',
  deleteStrategy: 'delete_strategy',
  strategyFlightRuns: 'strategy_flight_runs',
  wizardAdmin: 'wizard_admin',
  accessStrategyWizard: 'access_strategy_wizard',
  manageStrategyFlights: 'manage_strategy_flights',
  abReporting: 'ab_reporting',
  userGroups: 'user_groups',
  generateAuthHeaders: 'generate_auth_headers',
  algoCreativeOptimization: 'algo_creative_optimization',
  heliosBFO: 'helios_bfo',
  developerAPIAccess: 'developer_api_access',
  simplifiedFlightSelect: 'simplified_flight_select',
  xndrIntelligentChildObjects: 'xndr_intelligent_child_objects',
  crossPlatformIntelligentChildObjects: 'cross_platform_intelligent_child_objects',
  abInsightsRedesign: 'ab_insights_redesign',
  dbmBudgetOptCPMGoal: 'dbm_budget_opt_cpm_goal',
  ttdBudgetOptBidModel: 'ttd_budget_opt_bid_model',
  insightsViz: 'insights_viz',
  saveUpdateStrategy: 'save_update_strategy',
  viewUsers: 'view_users',
  markClientTest: 'mark_client_test',
  nmsConsolidatedIo: 'nms_consolidated_io',
  // GOAL-RELATED PERMISSIONS
  viewStrategyGoals: 'view_strategy_goals',
  editStrategyGoals: 'edit_strategy_goals',
  ttdBudgetOptCustomGoals: 'ttd_budget_opt_custom_goals',
  vCpmTtdBudgetOpt: 'vcpm_ttd_budget_opt',
  vCpmXndrBudgetOpt: 'vcpm_xndr_budget_opt',
  vCpmDv360BudgetOpt: 'vcpm_dv360_budget_opt',
  xndrBudgetOptCustomGoals: 'xndr_budget_opt_custom_goals',
  dbmYoutubeGoals: 'dbm_youtube_goals',
  dbmImpValuePerImp: 'dbm_imp_value_per_imp',
  dbmImpValuePerCost: 'dbm_imp_value_per_cost',
  cpmRevenueType: 'cpm_revenue_type',
  crossPlatformRevenueTypeGoals: 'cross_platform_revenue_type_goals',
  budgetAllocationGroups: 'budget_allocation_groups',
  cyodGoalType: 'cyod_goal_type',
  awgGoalType: 'awg_goal_type',
  amznBudgetOptConvGoals: 'amzn_budget_opt_conv_goals',
  crossPlatformOptConvGoals: 'cross_platform_opt_conv_goals',
  impactOutcomeGoalType: 'impact_outcome_goal_type',
  ncsNewBuyerGoalType: 'ncs_new_buyer_goal_type',
  accessRevenueTypes: 'access_revenue_types',
};

const StrategyPermissions = [
  Permission.stratSegRec,
  Permission.stratHeliosSegRec,
  Permission.stratCustomTree,
  Permission.stratHelios,
  Permission.stratCustomBidList,
  Permission.stratHeliosBidList,
  Permission.stratAPNBudgetOptimization,
  Permission.stratTTDBudgetOptimization,
  Permission.stratWMTBudgetOptimization,
  Permission.stratDBMBudgetOptimization,
  Permission.stratAMZNBudgetOptimization,
  Permission.stratDBMCustomSDF,
];

export const StrategyTypeToPermission = {
  [STRATEGY_TYPE.heliosSegmentRecency.id]: Permission.stratHeliosSegRec,
  [STRATEGY_TYPE.customTree.id]: Permission.stratCustomTree,
  [STRATEGY_TYPE.helios.id]: Permission.stratHelios,
  [STRATEGY_TYPE.customBidList.id]: Permission.stratCustomBidList,
  [STRATEGY_TYPE.heliosBidList.id]: Permission.stratHeliosBidList,
  [STRATEGY_TYPE.apnBudgetOptimization.id]: Permission.stratAPNBudgetOptimization,
  [STRATEGY_TYPE.ttdBudgetOptimization.id]: Permission.stratTTDBudgetOptimization,
  [STRATEGY_TYPE.wmtBudgetOptimization.id]: Permission.stratWMTBudgetOptimization,
  [STRATEGY_TYPE.dbmBudgetOptimization.id]: [Permission.stratDBMBudgetOptimization],
  [STRATEGY_TYPE.amznBudgetOptimization.id]: Permission.stratAMZNBudgetOptimization,
  [STRATEGY_TYPE.crossPlatformOptimization.id]: Permission.stratCrossPlatformOptimization,
  [STRATEGY_TYPE.dbmCustomSDF.id]: Permission.stratDBMCustomSDF,
  [STRATEGY_TYPE.wmtBudgetOptimization.id]: Permission.stratWMTBudgetOptimization,
};

export const getPermissionFromStrategyType = (st: StrategyType) => _.get(StrategyTypeToPermission, `${st.id}`);

export const MEMBER = {
  ALL: '*',
  ANY: '?',
};

/**
 * Whether a particular feature is enabled for a user, in the context of a
 * member.
 * member may be either a member's externalID or the special value MEMBER.ANY,
 * which asks if the feature is enabled without respect to a member's context.
 * @param {Object} user - the logged in user.
 * @param {string} featureName - the feature.
 * @param {Object} member - either MEMBER.ANY or a member object an externalId or an array of memberIds.
 * @returns {Boolean} Whether feature is enabled.
 */
export function featureEnabled(user: User, featureName: string, member: CustomMember) {
  if (!user?.features) {
    return false;
  }
  const feature = user.features[featureName];

  if (!feature || !feature.enabled) {
    return false;
  }

  if (_.includes(feature.memberExtIds, MEMBER.ALL)) {
    return true;
  }

  if (!member) {
    return false;
  }
  if (_.isArray(member)) {
    // if member is an array, check feature.memberIds to see if any permission was blacklisted
    // blacklisted permisisons removed from memberIds in FeatureFlagService.getFeaturesForUser
    return _.isEmpty(_.difference(member, feature.memberIds));
  }

  return member === MEMBER.ANY || ((member as Member).externalId && _.includes(feature.memberExtIds, (member as Member).externalId));
}

/**
 * Check if user has enough permissions based on the list of features required with member if provided
 * @param {Object} user - the logged in user.
 * @param {Array.<string>|string} features - the list of features to check.
 * @param {Object} member (default: MEMBER.ANY) - or member object containing the externalId or an array of memberIds.
 * @param {Boolean} all (default: true) - check if all the features requested are required or only one of them
 * @returns {Boolean} Whether feature is enabled.
 */
export function checkPermissions(
  user: User,
  features: string | string[],
  member: CustomMember = MEMBER.ANY,
  all: boolean = true,
) {
  const mem = _.isNil(member) ? MEMBER.ANY : member;
  const setOfFeaturesToCheck = Array.isArray(features) ? features : [features];
  if (_.isEmpty(setOfFeaturesToCheck)) { return true; }
  const featuresChecked = _.map(setOfFeaturesToCheck, (feature) => featureEnabled(user, feature, mem));
  return all ? _.every(featuresChecked) : _.some(featuresChecked);
}

/**
 * Given a user and feature, return the members for which the feature is
 * enabled.
 * @param {Object} user - the logged in user.
 * @param {String} featureName - the requested permission.
 * @returns {*} - MEMBER.ALL if enabled for all members,
 *               false if feature is missing or not enabled,
 *               Array of member ids if feature enabled.
 */
export function membersForFeature(user: User, featureName: string) {
  if (!featureEnabled(user, featureName, MEMBER.ANY)) {
    return false;
  }

  const features = _.get(user, 'features', {});
  const feature = _(features)
    .get(featureName, {});
  const memberIds = _.get(feature, 'memberIds', []);
  return _.includes(memberIds, MEMBER.ALL) ? MEMBER.ALL : memberIds;
}

/**
 * The union of all members for all strategy permissions.
 * @param {Object} user - the logged in user.
 * @returns {string|Array<Number>} MEMBER.ALL if user has access to all members,
 *   else list of members.
 */
export function getStrategyMembers(user: User) {
  const features = _.get(user, 'features', {});
  const stratPermissions = _.at(features, StrategyPermissions);
  const members = _(stratPermissions)
    .map('memberIds')
    .flatten()
    .uniq()
    .filter((mem) => !_.isNil(mem))
    .value();

  if (_.includes(members, MEMBER.ALL)) {
    return MEMBER.ALL;
  }

  return members;
}
