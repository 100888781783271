// @flow
import React from 'react';
import _ from 'lodash';
import { Grid } from 'buildingBlocks';
import { formatApnObj } from 'utils/formattingUtils';
import { FlightItemColFlightBox, FlightItemColExisting, FlightItemColSplit,
  FlightItemColNew, FlightItemColSuccessOrFail } from './FlightItemCols';
import { ACTIONS } from '../constants';

type FlightItemProps = {
  v: {
    externalId: string,
    name: string,
  },
  onDismiss: Function,
  pct: number,
  newGroupRange: Array<string>,
  index: number,
  selectedActionType: string,
  checkedUserGroupData: Array<unknown>,
  userGroupData: Array<unknown>,
  disableDismiss: boolean,
};

const FlightItem = ({
  v, onDismiss, pct, newGroupRange, index, selectedActionType, checkedUserGroupData, userGroupData, disableDismiss,
}: FlightItemProps) => {
  const hasError = _.has(userGroupData[index], 'response.error');
  const coloredString = hasError ? 'red' : 'green';
  const getExistingVal = (highOrLow: string) => _.get(checkedUserGroupData[index], `user_group_targets.groups[0].${highOrLow}`);
  const newGroupRangeVal = _.isEqual(selectedActionType, ACTIONS.apply.value) && !_.isEmpty(newGroupRange[index]) ? newGroupRange[index] : '-';
  return (
    <Grid style={{ width: '85%' }} key={v.externalId}>
      <Grid.Row>
        <FlightItemColFlightBox
          onDismiss={() => onDismiss(v)}
          formatApnObj={formatApnObj(v)}
          classNameStr={coloredString}
          disableDismiss={disableDismiss}
        />
        <FlightItemColExisting
          low={getExistingVal('low')}
          high={getExistingVal('high')}
          shouldDisplay={(hasError || _.isEmpty(userGroupData))}
        />
        {_.isEqual(selectedActionType, ACTIONS.apply.value) && (
          <FlightItemColSplit shouldDisplay={(hasError || _.isEmpty(userGroupData))} pct={pct} />
        )}
        <FlightItemColNew
          classNameStr={disableDismiss ? coloredString : ''}
          newGroupRangeVal={newGroupRangeVal}
        />
        {!_.isEmpty(userGroupData) && (
          <FlightItemColSuccessOrFail hasError={hasError} flightErrorMsg={_.get(userGroupData[index], 'response.error')} />
        )}
      </Grid.Row>
    </Grid>
  );
};

export default FlightItem;
