import React from 'react';
import { NavigateFunction, Params, useLocation, useNavigate, useParams, Location } from 'react-router-dom';

export type RouterProps = {
  router: {
    location: Location,
    navigate: NavigateFunction,
    params: Readonly<Params<string>>,
  }
};

export type Router = RouterProps['router'];

const WithRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  };

  return ComponentWithRouterProp;
};

export default WithRouter;
