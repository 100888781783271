import _ from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useAmazonTags = () => {
  const location = useLocation();

  // (amazon tags) append track event script if page is homepage or monitoring page otherwise remove already added script
  useEffect(() => {
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.id = 'amazon-tags-script';
    const scriptCode = ' amzn("setRegion", "EU");\n amzn("addTag", "808c8874-1291-4baa-8d4c-cf3a7822ca29");\n';
    if (_.isEqual(location.pathname, '/') || _.isEqual(location.pathname, '/strategies')) {
      script.text = `${scriptCode} amzn('trackEvent', 'homepage');`;
      body.appendChild(script);
    }
    if (_.includes(location.pathname, 'monitoring')) {
      script.text = `${scriptCode} amzn('trackEvent', 'monitoring');`;
      body.appendChild(script);
    }

    return () => {
      const amazonScript = document.getElementById('amazon-tags-script');
      if (amazonScript) {
        amazonScript.remove();
      }
    };
  }, [location.pathname]);
};

export default useAmazonTags;
