import _ from 'lodash';
import fp from 'lodash/fp';
import { flatten } from 'utils/functionHelpers';

export const USE_NUMERIC_KEYS = Symbol('numericKeys');

export type StringMapping = [string, string];
type ArrayMapping = [string, string, Array<string>];
type NumericKeyMapping = [string, string, Symbol];

export type MappingConfigObj = {
  passthrough: PlainKeyMapping,
};

export type PlainKeyMapping =
  | StringMapping
  | NumericKeyMapping
  | Array<StringMapping | ArrayMapping>;

type MappingWithConfig = {
  config?: MappingConfigObj,
  mapping: PlainKeyMapping,
};

export type MappingConfig = PlainKeyMapping | MappingWithConfig;

/**
 * mapping
 *
 * either 'path' notation is supported like lodash periods or different elements of a list
 * const mappingArray = [
 *  ['a.b.c', 'x.y.z'],
 *  ['a.f', 'x.m'],
 * ]
 *
 * mapping with config (currently only passthrough is supported)
 * const mappingConfig = {
 *   config: { passthrough: ['(.*)', 'some_prefix\.(.*)'] }, // will passthrough anything that doesnt match
 *                  to some arbitrary location
 *   mapping: [
 *     ['a', 'new_loc'],
 *   ],
 * }
 * So in this example an object of { a: 1, other: 2 } we would get { new_loc: 1, some_prefix: { other: 2 } }
 *
 *
 * Arrays of object remapping is also supported
 * const mapping = [
 *   ['a', 'b'],
 *   ['array', 'new_loc', [  // array based remap can support any valid mappingArray or mappingConfig
 *     ['innerKey', 'inner_key']
 *     ['anotherInnerKey', 'another_inner_key']
 *   ]]
 * ];
 *
 * { a: 1, array: [{ innerKey: 2, anotherInnerKey: 3 }, { innerKey: 4 anotherInnerKey: 5 }] } would give
 * { b: 1, new_loc: [{ inner_key: 2, another_inner_key: 3 }, { inner_key: 4, anotherInnerKey: 5 }] }
 */

/**
 * checks to see if passthrough is simple or not
 * ['(.*)', 'prefix\.(.*)'] would be Simple
 * [['b\.(.*)', 'BBB\.(.*)'], ['(.*)', 'prefix\.(.*)']] would not be simple
 * @param  passthrough
 */
// kind of broken as this function doesnt prove [string, string] but we'll leave this for legacy reasons
// actually proves that passthrough is an array of symbols and strings of anything length....
export function isSimplePassthrough(passthrough: PlainKeyMapping): passthrough is [string, string] {
  return _.every(_.map(passthrough, (elem) => {
    const t = typeof elem;
    return (t === 'symbol' || t === 'string');
  }));
}

/**
 * reverse a single passthrough element
 * @param  {Array<string>} passthrough at least 2 elements long
 * @return {Array<string>}
 */
function reversePassthroughElement(passthrough) {
  const [fst, snd, ...rest] = passthrough;
  return [snd, fst, ...rest];
}

/**
 * reverse the passthrough object
 * @param  {Array<string> or Array<Array<string>>} passthrough
 * @return {Array<string> or Array<Array<string>>}
 */
function reversePassthrough(passthrough) {
  if (isSimplePassthrough(passthrough)) {
    return reversePassthroughElement(passthrough);
  }
  return _.map(passthrough, (elem) => reversePassthroughElement(elem));
}

/**
 * reverse a mapping list. useful so you can create a mapping once and use it for both sending and receiving data.
 * @param  {array} - mapping.
 * @return {array} reversed mapping
 */
function reverseMappings(mapping) {
  const flipMapping = (mappingArray) => _.map(mappingArray.slice(), (e) => {
    if (e.length === 3) {
      const [key, newKey, arrayRemapping] = e;
      return [newKey, key, reverseMappings(arrayRemapping)];
    }
    return e.slice().reverse();
  });

  if (!_.isArray(mapping)) {
    const ret = {
      ...mapping,
      mapping: flipMapping(mapping.mapping),
    };
    if (mapping.config && mapping.config.passthrough) {
      const passthrough = reversePassthrough(mapping.config.passthrough);
      ret.config = {
        ...mapping.config,
        passthrough,
      };
    }
    return ret;
  }
  return flipMapping(mapping);
}

/**
 * flatten an objects keys to fit the form { x: { y: { z: 1 } } } -> { 'x.y.z': 1}
 * useful when dealing with lodash _.get and _.set methods
 * @param {object} object
 * @param {optional string} prefix - used for recursion
 * @param {optional string} ret used for recursion
 *@return {object} flat object with string keys
 */

/* eslint-disable no-param-reassign */
export function flattenObjectKeys(object, prefix = '', ret = {}) {
  _.forEach(object, (value, key) => {
    if (!_.isPlainObject(value)) {
      ret[`${prefix}${key}`] = value;
    } else {
      flattenObjectKeys(value, `${prefix}${key}.`, ret);
    }
  });
  return ret;
}
/* eslint-enable */

/**
 * handles regex for maping keys from src to new
 * @param  {string} key       the key that we want renamed
 * @param  {string} toMatch   will be converted into regex
 * @param  {string} toReplace string to replace into
 * @param  {Object/undefined} setWith value to be used with setWith
 * @return {[string/undefined]}  the new key or undefined if unable to match
 */
function passthroughKey(key: string, toMatch: string, toReplace: string, setWith?: unknown) {
  const re = new RegExp(toMatch);
  if (re.test(key)) {
    const found = re.exec(key);
    const setWithValue = setWith === USE_NUMERIC_KEYS ? Object : undefined;
    return [toReplace.replace(/\(\.\*\)/, found[1]), setWithValue];
  }
  return undefined;
}
/**
 * [firstMatchingPassthroughKey description]
 * @param  {string} key          [description]
 * @param  {array} passthroughs either [lhs, rhs] or [[lhs1, rhs1], [lhs2, rhs2]]
 * @return {string/undefined}              [description]
 */
function firstMatchingPassthroughKey(key: string, passthroughs: PlainKeyMapping) {
  // if (passthroughs.length === 2 && _.isArray(passthroughs[0])) {
  if (!isSimplePassthrough(passthroughs)) {
    // @ts-ignore type refinement spread args jrose
    const keys = _.map(passthroughs, (args) => passthroughKey(key, ...args));
    return _.first(_.compact(keys));
  }
  // @ts-ignore type refinement spread args jrose
  return passthroughKey(key, ...passthroughs);
}

/**
 * remap the fields that are in the object based on the mapping array
 * @param  {Array} to describe which fields should be selected and inserted into the new object
 * @param  {Object} the source object
 * @return {Object} new object using the new keys selected from the source object
 */
function remap(mappingConfig: MappingConfig, obj: {}) {
  let mapping = mappingConfig;
  let config = null;
  if (!_.isArray(mappingConfig)) {
    mapping = mappingConfig.mapping;
    config = mappingConfig.config;
  }
  const advancedMapping = fp.flow([
    fp.map(([key,, advanced]) => [key, advanced]),
    fp.filter(([, advanced]) => advanced !== undefined),
    fp.fromPairs,
  ])(mapping);
  // @ts-ignore hard jrose
  mapping = _.transform(mapping, (result, [key, value]) => {
    // @ts-ignore hard jrose
    let mappedKeys: Array<unknown> = result[key];
    if (_.isUndefined(result[key])) {
      mappedKeys = [];
      /* eslint-disable no-param-reassign */
      // @ts-ignore hard jrose
      result[key] = mappedKeys;
    }
    mappedKeys.push(value);
  }, {});
  const newObj = {};
  const flatObj = flatten(obj);
  _.forEach(flatObj, (value, key) => {
    let newKeys = _.get(mapping, key);
    if (newKeys) {
      _.forEach(newKeys, (newKey) => {
        const arrayMapping = _.get(advancedMapping, key);
        if (newKey) {
          if (arrayMapping) {
            _.set(newObj, newKey, _.map(value, (v) => remap(arrayMapping, v)));
          } else {
            _.set(newObj, newKey, value);
          }
        }
      });
    } else if (config && config.passthrough) {
      const tryPassthrough = firstMatchingPassthroughKey(key, config.passthrough);
      if (tryPassthrough) {
        let setWith;
        // eslint-disable-next-line prefer-const
        [newKeys, setWith] = tryPassthrough;
        _.setWith(newObj, newKeys, value, setWith);
      }
    }
  });
  return newObj;
}

export { remap, reverseMappings, passthroughKey };
