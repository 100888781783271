import _ from 'lodash';
import { MAX_SNOWFLAKES_RADIUS, MIN_SNOWFLAKES_RADIUS } from './constants';

export type Particle = { x: number, y: number, r: number, d: number, comingCloser: boolean, initR: number };

export const getDimensions = () => {
  const dom = document.querySelector('#cp-main-container');
  return ({
    // Give in some offset to make sure snow falls out
    height: Math.max(_.get(dom, 'scrollHeight', 0) + 60, window.innerHeight),
    width: Math.max(_.get(dom, 'scrollWidth', 0), window.innerWidth),
  });
};

/* eslint-disable no-param-reassign */
export const updateParticleCoords = (
  p: Particle,
  rng: number,
  angle: number,
  width: number,
  height: number,
) => {
  p.y += (Math.cos(angle + p.d) + p.r / 2);
  p.x += Math.sin(angle) * 2;

  if (p.x > width + p.r
    || p.x < -p.r
    || p.y > (height + p.r)
    || p.r > MAX_SNOWFLAKES_RADIUS
    || p.r < MIN_SNOWFLAKES_RADIUS) {
    p.r = p.initR;
    p.x = rng * width;
    p.y = -p.r;
    p.comingCloser = Math.random() > 0.5;
  }

  if (p.comingCloser) {
    p.r += (0.01 * p.r) / 2;
  } else if (!p.comingCloser) {
    p.r -= (0.05 * 1) / p.r;
  }
};
/* eslint-enable no-param-reassign */
