import _ from 'lodash';
import useFetcher, { PossibleStates } from 'utils/hooks/useFetcher';
import { Users } from 'utils/copilotAPI';
import { RESULTS_LIMIT } from 'constantsBase';

export const useUsersDataFetcher = () => {
  const fetchUsers = async () => {
    const users = await Users.get({ limit: RESULTS_LIMIT, sort: 'email ASC', isEnabled: 1 });
    return { kind: PossibleStates.hasData, data: users.data };
  };

  const currentState = useFetcher(fetchUsers);
  const error = currentState.kind === PossibleStates.error && 'Failed to fetch users';
  // @ts-ignore
  return [currentState.data, error];
};

export const useUserDataFetcher = (id: number) => {
  const fetchUser = async () => {
    const user = await Users.get({ id, limit: 1, isEnabled: 1 });
    return { kind: PossibleStates.hasData, data: user.data };
  };

  const currentState = useFetcher(fetchUser);
  const error = currentState.kind === PossibleStates.error && 'Failed to fetch user';
  // @ts-ignore
  return [_.head(currentState.data), error];
};
