import _ from 'lodash';
import React, { ReactNode, ComponentType, ComponentElement, CSSProperties, useState } from 'react';
import { UseControllerReturn } from 'react-hook-form';
import { Label, Form, WppLabel, WppInlineMessage, SemanticWIDTHS, WppInlineEdit, WppInput, WppTypography } from 'buildingBlocks';
import { BUDGET_INTERVALS } from '../containers/StrategyWizard/steps/GoalSelection/styles';
import { CustomDatePickerProps, WppInputCustomEvent, InputChangeEventDetail, WppInlineEditCustomEvent, InlineEditChangeModeEventDetail, InlineEditMode } from './types';

/**
 * @param {Component} InnerComponent the component to wrap
 * @return {Component} that will display any meta.error messages after it has been touched.
 */

export type ValidatedInputFieldProps = Omit<UseControllerReturn<any, any>, 'formState'> & {
  onChange: (e, value: { value: string }) => void
  onKeyDown?: (e, value: { value: string }) => void
  value?: string | number
  noInput?: any
  required?: boolean
  width?: number
  label?: string
  title?: string
  labelPosition?: string
  disabled?: boolean
  icon?: string | ComponentElement<any, any>
  className?: string
  type?: string
  fluid?: boolean
  style?: CSSProperties
  autoFocus?: boolean
  placeholder?: number | string
  defaultValue?: string | number
  skipIsDirtyCheck?: boolean
  selection?: boolean
  options?: Array<unknown>
  keyFn?: Function
  size?: string
  messageType?: string
  autoComplete?: string
} & CustomDatePickerProps;

// @ts-ignore
export type ValidatedFieldWrapper = (node: ReactNode) => (inputFields: ValidatedInputFieldProps) => Form.Field<unknown>;

export const validatedFieldWrapper: ValidatedFieldWrapper = (InnerComponent: ComponentType<any>) => (
  ({ field, fieldState, skipIsDirtyCheck, ...rest }: ValidatedInputFieldProps) => {
    // If label position wasn't specified, remove it before passing it down to the input field
    let cleanInput: { label?: string } = rest;
    if (!rest.labelPosition) {
      const omitFields = rest.noInput ? ['label', 'input'] : ['label'];
      cleanInput = _.omit(rest, omitFields);
    }

    const dirty = skipIsDirtyCheck ? fieldState.error : (fieldState.isDirty && fieldState.error);
    const displayedLabel = _.get(rest, 'title', _.get(rest, 'label'));
    const value = field.value ?? '';

    return (
      // @ts-ignore
      <Form.Field required={rest.required} error={!!dirty} width={rest.width}>
        {displayedLabel && <label htmlFor={field.name}>{displayedLabel}</label>}
        <InnerComponent {...cleanInput} value={value} />
        {(dirty && fieldState.error.message)
          && <Label basic style={{ display: 'block', background: 'transparent', padding: '4px 0px 0px' }}>{fieldState.error.message}</Label>}
      </Form.Field>
    );
  }
);

export const validatedWppFieldWrapper: ValidatedFieldWrapper = (InnerComponent: ComponentType<any>) => (
  ({ field, fieldState, skipIsDirtyCheck, ...rest }: ValidatedInputFieldProps) => {
    // If label position wasn't specified, remove it before passing it down to the input field
    let cleanInput: { label?: string } = rest;
    if (!rest.labelPosition) {
      const omitFields = rest.noInput ? ['label', 'input'] : ['label'];
      cleanInput = _.omit(rest, omitFields);
    }

    const dirty = skipIsDirtyCheck ? fieldState.error : (fieldState.isDirty && fieldState.error);
    const displayedLabel = _.get(rest, 'title', _.get(rest, 'label'));
    const value = field.value ?? '';

    return (
      <Form.Field required={rest.required} error={!!dirty} width={rest.width as SemanticWIDTHS}>
        {displayedLabel && <WppLabel htmlFor={field.name} style={BUDGET_INTERVALS.wrapperWppInputLabel} config={{ text: displayedLabel }} />}
        <InnerComponent
          {...cleanInput}
          value={value}
          classname="input-currency"
          onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => {
            rest.onChange(event, {
              value: event.detail.value,
            });
          }}
        >
          {cleanInput.label && (
          <WppTypography
            type="s-strong"
            slot="icon-end"
            tag="p"
            style={BUDGET_INTERVALS.inputcurrency}
          >
            {cleanInput.label}
          </WppTypography>
          )}
        </InnerComponent>
        {(dirty && fieldState.error.message)
        && (
        <WppInlineMessage
          size="s"
          message={fieldState.error.message}
          type="error"
          className="wrapper-error-message"
        />
        )}
      </Form.Field>
    );
  }
);

export const validatedWppFieldInlineWrapper: ValidatedFieldWrapper = () => (
  ({ field, fieldState, skipIsDirtyCheck, ...rest }: ValidatedInputFieldProps) => {
    // If label position wasn't specified, remove it before passing it down to the input field
    let cleanInput: { label?: string } = rest;
    if (!rest.labelPosition) {
      const omitFields = rest.noInput ? ['label', 'input', 'onChange'] : ['label', 'onChange'];
      cleanInput = _.omit(rest, omitFields);
    }

    const dirty = skipIsDirtyCheck ? fieldState.error : (fieldState.isDirty && fieldState.error);
    const displayedLabel = _.get(rest, 'title', _.get(rest, 'label'));
    const value = field.value ?? '';
    const [inputMode, setInputMode] = useState<InlineEditMode>('read');

    return (
      <Form.Field required={rest.required} error={!!dirty} width={rest.width as SemanticWIDTHS}>
        {displayedLabel && <WppLabel htmlFor={field.name} config={{ text: displayedLabel }} />}
        <WppInlineEdit
          value={value}
          mode={inputMode}
          {...cleanInput}
          onWppModeChange={(event: WppInlineEditCustomEvent<InlineEditChangeModeEventDetail>) => {
            setInputMode(event.detail.mode);
            if (event.detail.mode === 'read') {
              event.detail.closePopover();
            }
          }}
        >
          <WppInput
            size="s"
            slot="form-element"
            value={value}
            onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => {
              rest.onChange(event, {
                value: event.detail.value,
              });
            }}
          />
        </WppInlineEdit>
        {(dirty && fieldState.error.message)
        && (
        <WppInlineMessage
          size="s"
          message={fieldState.error.message}
          type="error"
        />
        )}
      </Form.Field>
    );
  }
);
