// @flow
import React from 'react';
import _ from 'lodash';
import { Grid } from 'buildingBlocks';
import userGroupPageStyles from '../style';
import { ACTIONS } from '../constants';

type Props = {
  selectedActionType: string,
  hasErrors: boolean,
};

const RowHeader = ({ selectedActionType, hasErrors }: Props) => (
  <Grid.Row>
    <Grid.Column width={6} style={userGroupPageStyles.flightSplitHeader}>DSP Object</Grid.Column>
    <Grid.Column textAlign="center" width={1} style={userGroupPageStyles.flightSplitHeader}>
      {hasErrors && 'Existing'}
    </Grid.Column>
    { _.isEqual(selectedActionType, ACTIONS.apply.value) && (
      <Grid.Column textAlign="center" width={1} style={userGroupPageStyles.flightSplitHeader}>
        {hasErrors && 'Split'}
      </Grid.Column>
    )}
    <Grid.Column textAlign="center" width={2} style={userGroupPageStyles.flightSplitHeader}>New</Grid.Column>
  </Grid.Row>
);

export default RowHeader;
