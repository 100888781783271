import _ from 'lodash';
import { call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { Member as MemberT, Advertiser as AdvertiserT } from 'utils/types';
import { Member, Advertiser, ViewAPNCampaign, APNLineItem, APNInsertionOrder, ViewTTDAdGroup, TTDCampaign, ViewDBMLineItem, DBMInsertionOrder } from 'utils/copilotAPI';
import { MEMBER } from 'utils/featureFlags';
import { orderFlights } from 'utils/functionHelpers';
import { RESULTS_LIMIT, FLIGHT_EXTERNAL_TYPE } from 'constantsBase';

// Currently fetches only APN Members. Should update this once we start allowing members from other DSPs.
export function* fetchMembers(
  onPermissionError,
  onSuccess,
  onFail,
  {
    payload: {
      memberIds,
      dspIds,
    },
  },
) {
  try {
    if (_.isEmpty(memberIds)) {
      yield put(onPermissionError());
    } else {
      const where = (memberIds === MEMBER.ALL)
        ? { dsp: dspIds }
        : { id: memberIds, dsp: dspIds };
      const members = yield call(
        Member.get,
        {
          where,
          limit: RESULTS_LIMIT,
          sort: 'displayName ASC',
          enabled: 1,
        },
      );
      yield put(onSuccess(members.data));
    }
  } catch (error) {
    yield put(onFail());
  }
}

export function* fetchAdvertisers(onSuccess, onFail, { payload: { member, searchTerm } }) {
  const limit = 10;
  const populate = 'defaultCurrency';
  const sort = 'updatedAt DESC';
  const where = {
    member,
    or: [
      { name: { contains: searchTerm } },
      { externalId: { contains: searchTerm } },
    ],
  };
  try {
    const advertisers = yield call(Advertiser.get, {
      where, sort, limit, populate,
    });
    yield put(onSuccess(advertisers.data));
  } catch (e) {
    yield put(onFail());
  }
}

type FetchFlightsWhere = {
  member: MemberT,
  advertiser: AdvertiserT,
  or: unknown,
  campaignType?: string,
  lineItemType?: string,
  isDeleted?: boolean,
  isEnabled?: boolean,
  id?: unknown,
};

type Query = {
  where?: any
  populate?: Array<string>
  limit?: number
};

const getApnCampaignsQuery = (includeDisabledFlights): Query => {
  const isDeleted = includeDisabledFlights && { isDeleted: false };
  const where = { campaignType: 'default', ...isDeleted };
  const populate = ['lineItem', 'insertionOrder'];
  return { where, populate };
};

const getApnALIsQuery = (includeDisabledFlights): Query => {
  const isEnabled = includeDisabledFlights && { isEnabled: true };
  const where = { lineItemType: 'standard_v2', ...isEnabled };
  const populate = ['insertionOrder'];
  return { where, populate };
};

const flightTypeToQuery = {
  [FLIGHT_EXTERNAL_TYPE.apnCampaign.id]: getApnCampaignsQuery,
  [FLIGHT_EXTERNAL_TYPE.apnLineItem.id]: getApnALIsQuery,
};

const FLIGHT_TYPE_TO_CP_ENDPOINT = {
  [FLIGHT_EXTERNAL_TYPE.apnCampaign.id]: ViewAPNCampaign,
  [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: APNInsertionOrder,
  [FLIGHT_EXTERNAL_TYPE.apnLineItem.id]: APNLineItem,
  [FLIGHT_EXTERNAL_TYPE.ttdMegagon.id]: ViewTTDAdGroup,
  [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: TTDCampaign,
  [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: DBMInsertionOrder,
  [FLIGHT_EXTERNAL_TYPE.dbmLineItem.id]: ViewDBMLineItem,
};

export function* fetchFlights(
  onSuccess,
  onFail,
  limit,
  { payload: { memberId, advertiserId, flightType, searchString, includeDisabledFlights = true, excludedIds = null } },
) {
  const ids: Array<number> = excludedIds || null;
  try {
    const baseWhere: FetchFlightsWhere = {
      member: memberId,
      advertiser: advertiserId,
      or: [
        { name: { contains: searchString } },
        { externalId: { contains: searchString } },
      ],
      id: { not: ids },
    };

    const { where, ...rest }: Query = flightTypeToQuery[flightType] ? flightTypeToQuery[flightType](includeDisabledFlights) : {};
    const queryConfig: Query = { where: { ...baseWhere, ...where }, ...rest, limit };

    const objects = yield call(FLIGHT_TYPE_TO_CP_ENDPOINT[flightType].get, queryConfig);
    yield put(onSuccess(objects.data));
  } catch (error) {
    yield put(onFail());
  }
}

type FetchArgs = {
  member: string,
  flightType: string,
  flightLeft: string,
  flightRight: string,
  advertiser: string,
};

export function* fetchMemberAdvertiserAndFlights(
  fetchArgs: FetchArgs,
  onFailAction: (err: unknown) => AnyAction,
) {
  const { member, flightType, flightLeft, flightRight, advertiser } = fetchArgs;
  try {
    const memberRes = yield call(Member.get, { externalId: member });
    const memberData = memberRes.data[0];

    const advertiserRes = yield call(Advertiser.get, {
      member: memberData.id,
      externalId: advertiser,
      populate: ['defaultCurrency'],
    });
    const advertiserData = advertiserRes.data[0];

    const flightExtIds = [flightLeft, flightRight];
    const flightTypeNum = Number(flightType);

    const populate = flightTypeNum === FLIGHT_EXTERNAL_TYPE.apnCampaign.id
      ? ['lineItem', 'insertionOrder']
      : ['insertionOrder'];
    const flights = yield call(FLIGHT_TYPE_TO_CP_ENDPOINT[flightType].get, {
      member: memberData.id, externalId: flightExtIds, limit: 2, advertiser: advertiserData.id, populate,
    });

    const [left, right] = orderFlights(flightExtIds, flights.data);

    const ret = {
      advertiser: advertiserData,
      member: memberData,
      flightType: flightTypeNum,
      flightLeft: left,
      flightRight: right,
    };

    return ret;
  } catch (err) {
    yield put(onFailAction(err));
    return null;
  }
}
